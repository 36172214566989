<template>
	<section>
		<div class="local-markets-fields">
			<p class="has-text-centered local-market-title"><b>Negócio Local</b></p>
			<div class="qr-code-div">
				<qrcode-vue class="center-inside" ref="qrcode" :value="getLocalMarketData.id" foreground="#1d4696" :size="290" level="H" />
			</div>

			<p class="has-text-centered local-market-discount-text">
				<b>Partilha este QR code com o parceiro e.migra para utilizares o desconto.</b>
			</p>

			<div class="next-div has-text-centered">
				<button class="button is-rounded is-white is-fullwidth" v-if="purchaseBenefits.benefits.length === 1">
					Código {{ purchaseBenefits.benefits[0].discount }}
			<!-- 		<span v-if="selectedBenefits[0].type == 'percentage_purchase' || selectedBenefits[0].type == 'percentage_product'">%</span> --><span>€</span>
				</button>
				<button class="button is-rounded is-white is-fullwidth" v-else>
					Aplicar Descontos
				</button>

				<button @click="cancel" class="button cancel is-rounded is-danger is-fullwidth">
					Cancelar
				</button>
			</div>
			<!--      <div class="next-div has-text-centered">
              <button @click="setMarketInfo(0,null)" class="button is-rounded is-white">
                X
              </button>
            </div>-->
		</div>
	</section>
</template>

<script>
	//QRCode Creator
	import QrcodeVue from 'qrcode.vue';
	//Mixins
	import { localMarketSteps } from '@/mixins/localMarkets/localMarketSteps';

	export default {
		name: 'LocalMarketQrCode',
		mixins: [localMarketSteps],
		props: ['marketInfo'],
		components: {
			QrcodeVue,
		},
		data() {
			return {
				selectedBenefits: null,
				purchaseBenefits: null,
			};
		},
		created() {
			console.log(this.$store.getters['localMarketSteps/getBenefits']);
			console.log(this.$store.getters['localMarketSteps/getLocalMarketData']);
			this.selectedBenefits = this.$store.getters['localMarketSteps/getBenefits'];
			this.purchaseBenefits = this.$store.getters['localMarketSteps/getLocalMarketData'];
		},
		methods: {
			cancel() {
				this.$store.commit('localMarketSteps/setActiveLocalMarketStep', 0);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';
	@import '../../../assets/scss/localMarket';

	.button {
		margin-top: 30px;
		color: #1d4696;
		border: none;
	}

	.local-markets-fields {
		height: 100vh;
		background-color: #f6a328;
		transform: translateY(-62px);
	}

	.has-text-white {
		word-wrap: break-word;
		text-align: center;
	}

	.local-market-title {
		padding-top: 15px;
		color: white;
		font-size: 2rem;
		margin-right: 10px;
	}

	.local-market-discount-text {
		color: white;
	}

	.qr-code-div {
		margin-top: 50px;
		margin-bottom: 50px;
	}

	.cancel {
		color: white;
		margin-bottom: 20px;
	}
</style>
