<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Perfil</b></p>
			<div class="tabs is-toggle center-inside is-toggle-rounded">
				<div class="tabs-shadow">
					<ul>
						<li @click="activeTab = 0" :class="{ 'is-active': activeTab === 0 }">
							<a>
								<span><b>O meu perfil</b></span>
							</a>
						</li>
						<li @click="activeTab = 1" :class="{ 'is-active': activeTab === 1 }">
							<a>
								<span><b>Publicações</b></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<EditProfile v-if="userData.info && activeTab === 0" :userData="userData"></EditProfile>
    <Publications v-if="activeTab === 1"/>
	</section>
</template>

<script>
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';
	//Components
	import EditProfile from '@/components/Profile/EditProfile';
  import Publications from '@/components/Profile/MyPublications.vue'

	export default {
		mixins: [userInfo],
		name: 'Profile',
		components: {
			EditProfile,
      Publications
		},
		data() {
			return {
				isLoading: false,
				activeTab: 0,
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/auth';

	.auth-div {
		top: 20px;
	}

	.tabs.is-toggle.is-toggle-rounded li:first-child a {
			border-radius: 20px;
	}

	.tabs.is-toggle.is-toggle-rounded li:last-child a {
			border-radius: 20px;
	}

	.tabs-shadow {
		/* border-radius */
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		/* box-shadow */
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.tabs.is-toggle a {
		border: none;
		color: #1d4696;
	}

	.tabs {
		padding: 10px;
    overflow: hidden;
	}

	.tabs.is-toggle li.is-active a {
		color: #1d4696;
	}

	.title {
		font-size: 1.5rem;
		margin-top: 4px;
	}
</style>
