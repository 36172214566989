<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Completar Registo </b></p>
			<label class="label">Cód. Postal no Estrangeiro</label>
			<div class="email-field ">
				<v-select
					class="custom"
					label="country_data"
					v-validate="{
						required: true,
					}"
					name="hostName"
					v-model="countryName"
					:options="postalCodeHostData"
					><span slot="no-options">Não foi encontrado nenhum resultado.</span></v-select
				>
				<p v-show="errors.has('hostName')" class="help is-danger">
					Selecione um país de acolhimento!
				</p>
				<div class="field has-addons">
					<!-- <div class="control">
            <div class="select">
              <select style="width:78px" v-model="host_country">
                <option :value="zip" v-for="zip in postalCodeData" v-if="zip.Regex !== '' || zip.Regex"
                  >{{ zip.ISO }}
                  <pre>&nbsp;&nbsp;</pre>
                  -
                  {{ zip.Country }}
                </option>
              </select>
            </div>
          </div> -->
					<div style="width: 100%" class="control has-icons-right">
						<input
							ref="host"
							class="input is-success"
							type="text"
							v-validate="{ required: true, regex: countryName.Regex }"
							name="host"
							:class="{ 'error-text': errors.has('host') }"
							v-model="zip_host"
							placeholder="Ex: 70004"
						/>
						<span class="icon is-small is-right">
							<i v-show="!errors.has('host') && zip_host != null" class="required-icon mdi mdi-check mdi-24px"></i>
						</span>
					</div>
				</div>
				<p v-show="errors.has('host')" class="help is-danger">
					Formato de código postal inválido
				</p>
			</div>

			<label class="label">Local de Origem</label>
			<div class="field has-addons">
				<div class="control">
					<div class="select ">
						<select class="select-origin" disabled>
							<option>{{ origin_country.ISO }} </option>
						</select>
					</div>
				</div>
				<div style="width: 100%" class="control has-icons-right">
					<input
						class="input is-success"
						:class="{ 'error-text': errors.has('origin') }"
						type="text"
						v-validate="{ required: true, regex: origin_country.Regex }"
						name="origin"
						v-model="zip_origin"
						placeholder="Ex: 4490-243"
					/>
					<span class="icon is-small is-right">
						<i v-show="!errors.has('origin') && zip_origin != null" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
			</div>
			<p v-show="errors.has('origin')" class="help is-danger">
				Formato de código postal inválido
			</p>
			<br />
			<div class="next-div ">
				<!--        <p class="small-text">Não sabe o código postal? <a @click="openModal()" class="link-text">Introduzir Morada</a>-->
				<button class="button is-rounded is-primary is-fullwidth" @click="nextStep">
					Seguinte
				</button>
				<div class="register-steps center-inside is-fullwidth">
					<div class="step-1"></div>
					<div @click="nextStep"></div>
					<div></div>
				</div>
			</div>
		</div>
		<!--    <AddressModal v-if="isModalOpen" :isModalOpen="isModalOpen" @clicked="closeModal"></AddressModal>-->
	</section>
</template>

<script>
	//Mixins
	import { steps } from '@/mixins/shared/steps';

	import { verifyHostZip, verifyOriginZip } from '@/api/apiAuth';

	//PostalCode Data JS
	import countryCodes from '@/components/Auth/RegisterSteps/postalCodes/postal-codes.js';
	//PostalCode Data JSON
	const postalCodeData = require('@/components/Auth/RegisterSteps/postalCodes/postal-codes.json');
	const postalCodeHostData = require('@/components/Auth/RegisterSteps/postalCodes/postal-codes-withoutPortugal.json');
	//Component
	/*import AddressModal from "@/components/Auth/CompleteRegister/AddressModal";*/

	export default {
		mixins: [steps],
		components: {},
		props: {
			userData: {
				type: Object,
				required: true,
			},
		},
		name: 'Step1',
		data() {
			return {
				isModalOpen: false,
				//countryCodes,JS
				postalCodeData,
				host_country: postalCodeHostData[77],
				zip_host: null,
				origin_country: postalCodeData[179],
				zip_origin: null,
				countryName: null,
				postalCodeHostData,
			};
		},
		mounted() {
			console.log(this.userData.info);
			if (this.userData.info.destination_zip_code && this.userData.info.origin_zip_code) {
				for (let i = 0; i < this.postalCodeHostData.length; i++) {
					if (this.userData.info.destination_country === this.postalCodeHostData[i].ISO) {
						this.countryName = this.postalCodeHostData[i];
					}
				}
				this.zip_host = this.userData.info.destination_zip_code;
				this.zip_origin = this.userData.info.origin_zip_code;
				this.postalCodeHostData.map(function(x) {
					return (x.country_data = x.ISO + ' - ' + x.Country);
				});
			} else {
				this.countryName = this.host_country;
				this.postalCodeHostData.map(function(x) {
					return (x.country_data = x.ISO + ' - ' + x.Country);
				});
			}
		},

		methods: {
			openModal() {
				this.isModalOpen = true;
			},
			closeModal(value) {
				this.isModalOpen = value;
			},
			nextStep() {
				this.$validator.validateAll().then(result => {
					if (result) {
						let originZip = this.zip_origin.replace(/-/g, '');
						let hostZip = this.zip_host.replace(/-/g, '');
						verifyHostZip(hostZip, this.countryName.Country)
							.then(response => {
								console.log(response.data.status);
								if (response.data.status === 'ZERO_RESULTS') {
									this.$buefy.toast.open({
										message: 'Código postal inválido. Verifique os códigos postais.',
										position: 'is-top',
										type: 'is-danger',
									});
								} else {
									verifyOriginZip(originZip)
										.then(response => {
											console.log(response);
											if (response.data.status === 'ZERO_RESULTS') {
												this.$buefy.toast.open({
													message: 'Código postal inválido. Verifique os códigos postais.',
													position: 'is-top',
													type: 'is-danger',
												});
											} else {
												let data = {
													origin_county: this.origin_country.ISO,
													origin_parish: null,
													origin_street: null,
													origin_zip_code: this.zip_origin,
													destination_zip_code: this.zip_host,
													destination_country: this.countryName.ISO,
													destination_county: null,
													destination_city: null,
													destination_street: null,
													name: null,
													gender: null,
													email: null,
													birth_date: null,
													phone: null,
													photo: null,
												};
												console.log(data);
												this.setActiveStep(1, data);
											}
										})
										.catch(error => {
											this.isLoading = false;
											this.$buefy.toast.open({
												message: 'Verifique os códigos postais',
												position: 'is-top',
												type: 'is-danger',
											});
										});
								}
							})
							.catch(error => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: 'Verifique os códigos postais',
									position: 'is-top',
									type: 'is-danger',
								});
							});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		top: 30%;
	}

	.field {
		margin-bottom: 0;
	}

	.email-field {
		margin-bottom: 40px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 25px;
	}

	.register-steps > .step-1 {
		background: #1d4696;
	}

	.select-origin {
		color: #bac3dc !important;
	}

	.modal-card {
		border-radius: 20px;
	}

	.delete {
		float: right;
	}

	.title {
		font-size: 1.5rem;
	}

	.label {
		font-size: 0.9rem;
	}

	.small-text,
	.link-text {
		font-size: 0.75rem;
	}

	/deep/ .custom .vs__search::placeholder,
	/deep/ .custom .vs__dropdown-toggle,
	/deep/ .custom .vs__dropdown-menu {
		border: none;
	}

	/deep/ .custom .vs__selected {
		color: #1d4696;
	}

	/deep/ .custom .vs__clear,
	/deep/ .custom .vs__open-indicator {
		fill: $secondary;
	}

	.custom {
		margin-left: -10px;
		margin-bottom: 10px;
	}

	/deep/ .custom .vs__clear {
		visibility: hidden;
	}
</style>
