<template>
	<section>
		<loading :isPageLoading="isPageLoading"></loading>
		<div class="auth-div">
			<div class="header">
				<span class="title">Voos</span>
			</div>
			<div class="tabs is-toggle center-inside is-toggle-rounded">
				<div class="tabs-shadow">
					<ul>
						<li @click="Tab(0)" :class="{ 'is-active': activeTab === 0 }">
							<a>
								<span><b>Ida</b></span>
							</a>
						</li>
						<li @click="Tab(1)" :class="{ 'is-active': activeTab === 1 }">
							<a>
								<span><b>Ida + Volta</b></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div>
				<div class="field email-field">
					<label class="label">De</label>
					<div class="select control">
						<select v-validate="'required'" v-model="departureAirport" name="departureAirport">
							<option
								v-if="arrivalAirport === null || (arrivalAirport !== portugalFlights[0].PlaceId && arrivalAirport !== portugalFlights[1].PlaceId)"
								:value="portugalFlights[1].PlaceId"
								>Porto - {{ portugalFlights[1].PlaceId.split('-')[0] }}</option
							>
							<option
								v-if="arrivalAirport === null || (arrivalAirport !== portugalFlights[0].PlaceId && arrivalAirport !== portugalFlights[1].PlaceId)"
								:value="portugalFlights[0].PlaceId"
								>Lisboa - {{ portugalFlights[0].PlaceId.split('-')[0] }}</option
							>
							<option
								v-show="arrivalAirport === null || arrivalAirport.CountryName !== airport.CountryName"
								v-for="(airport, index) in airports"
								:value="airport"
								:key="index"
								>{{ airport.PlaceName }} - {{ airport.PlaceId.split('-')[0] }}
							</option>
						</select>
						<p v-show="errors.has('departureAirport')" class="help is-danger">
							Selecione um aeroporto de partida
						</p>
					</div>
				</div>
			</div>
			<div>
				<i class="mdi mdi-swap-vertical mdi-24px" @click="swapPlaces" style="margin-right:10px"></i>
			</div>
			<div>
				<div class="field email-field">
					<label class="label">Para</label>
					<div class="select control">
						<select v-validate="'required'" v-model="arrivalAirport" name="arrivalAddress" @change="activeTrackingTab = 0">
							<option :value="portugalFlights[1].PlaceId" v-if="departureAirport !== portugalFlights[0].PlaceId && departureAirport !== portugalFlights[1].PlaceId"
								>Porto - {{ portugalFlights[1].PlaceId.split('-')[0] }}</option
							>
							<option :value="portugalFlights[0].PlaceId" v-if="departureAirport !== portugalFlights[0].PlaceId && departureAirport !== portugalFlights[1].PlaceId"
								>Lisboa - {{ portugalFlights[0].PlaceId.split('-')[0] }}</option
							>
							<option
								v-show="departureAirport === null || departureAirport.CountryName !== airport.CountryName"
								v-for="(airport, index) in airports"
								:value="airport"
								:key="index"
								>{{ airport.PlaceName }} - {{ airport.PlaceId.split('-')[0] }}
							</option>
						</select>

						<p v-show="errors.has('arrivalAddress')" class="help is-danger">
							Selecione um aeroporto de chegada
						</p>
					</div>
					<br />
				</div>
			</div>
			<br />
			<div>
				<div>
					<div class="field email-field" v-if="activeTab === 0">
						<vc-date-picker :min-date="new Date()" v-model="date" @input="activeTrackingTab = 0" />
					</div>
					<div class="field email-field" v-else>
						<vc-date-picker :min-date="new Date()" v-model="dateRange" is-range @input="activeTrackingTab = 0" />
					</div>
				</div>
			</div>
			<div>
				<div>
					<span class="label">Passageiros</span>
				</div>
			</div>
			<div>
				<div class="title">
					<span class="passangers-title">Adultos <span class="passangers-label">16+</span></span>
				</div>
				<div class="counter">
					<button class="counter-button" @click="minusAdultsCounter">-</button><input class="counter-input" v-model="adultsCounter" disabled />
					<button class="counter-button" @click="addAdultsCounter">+</button>
				</div>
			</div>

			<div>
				<div class="title">
					<span class="passangers-title">Crianças <span class="passangers-label">3-16</span></span>
				</div>
				<div class="counter">
					<button class="counter-button" @click="minusChildsCounter">-</button><input class="counter-input" v-model="childsCounter" disabled />
					<button class="counter-button" @click="addChildsCounter">+</button>
				</div>
			</div>
			<div>
				<div class="title">
					<span class="passangers-title">Bebés <span class="passangers-label">0-2</span></span>
				</div>
				<div class="counter">
					<button class="counter-button" @click="minusBabysCounter">-</button><input class="counter-input" v-model="babysCounter" disabled />
					<button class="counter-button" @click="addBabysCounter">+</button>
				</div>
			</div>
			<p v-show="noPassenger" class="help is-danger">
				Introduza pelo menos 1 passageiro
			</p>
			<br />
			<div>
				<div>
					<span class="label">Escalas</span>
				</div>
			</div>
			<div class="field email-field">
				<div class="select control">
					<select v-model="stops" @change="activeTrackingTab = 0">
						<option value="true">Sim</option>
						<option value="false">Não</option>
					</select>
				</div>
			</div>

			<div>
				<div>
					<span class="label">Ordenar por</span>
				</div>
			</div>
			<div class="field email-field">
				<div class="select control">
					<select v-model="sort" @change="activeTrackingTab = 0">
						<option value="duration">Mais rápido</option>
						<option value="price">Mais barato</option>
					</select>
				</div>
			</div>
			<br />
			<div class="next-div has-text-centered ">
				<button class="button is-rounded is-primary is-fullwidth" @click="searchFlight">
					Pesquisar
				</button>
			</div>
			<div>
				<div class="going-card">
					<span class="label" style="text-align: left ;padding-top: 14px">Tracking</span>
					<div class="field email-field">
						<div class="select control">
							<select v-model="tracking" class="hour" @change="activeTrackingTab = 0">
								<option value="daily">Diário</option>
								<option value="weekly">Semanal</option>
								<option value="monthly">Mensal</option>
							</select>
						</div>
					</div>

					<div class="media">
						<div class="media-left"></div>
						<div class="media-content"></div>
						<div class="media-right">
							<div class="tabs is-toggle center-inside is-toggle-rounded">
								<div class="tabs-shadow">
									<ul>
										<li @click="activeTrackingTab = 0" :class="{ 'is-active': activeTrackingTab === 0 }">
											<a>
												<span><b>Off</b></span>
											</a>
										</li>
										<li @click="track" :class="{ 'is-active': activeTrackingTab === 1 }">
											<a>
												<span><b>On</b></span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import moment from 'moment';
	import { getAirports, getFlight, trackFlight } from '@/api/apiFlights';
	import { flightSteps } from '@/mixins/flight/flightSteps';
	import Loading from '@/components/Loading/Loading';
	export default {
		mixins: [flightSteps],
		components: {
			Loading,
		},
		data() {
			return {
				date: new Date(),
				dateRange: new Date(),
				adultsCounter: 0,
				childsCounter: 0,
				babysCounter: 0,
				scaleOption: '',
				orderBy: '',
				activeTab: 0,
				isRange: false,
				departureAirport: null,
				arrivalAirport: null,
				userInfo: null,
				userHostAddress: null,
				splittedCountry: null,
				airports: null,
				stops: true,
				sort: 'duration',
				tracking: 'daily',
				activeTrackingTab: 0,
				noPassenger: false,
				isPageLoading: false,
				portugalFlights: [
					{
						PlaceId: 'LIS-sky',
						PlaceName: 'Lisbon',
						CountryId: 'PT-sky',
						RegionId: '',
						CityId: 'LISB-sky',
						CountryName: 'Portugal',
					},
					{
						PlaceId: 'OPO-sky',
						PlaceName: 'Porto',
						CountryId: 'PT-sky',
						RegionId: '',
						CityId: 'PORT-sky',
						CountryName: 'Portugal',
					},
				],
			};
		},
		computed: {
			formattedDate() {
				return moment(this.date).format('YYYY-MM-DD');
			},

			formattedInboundRangeDate() {
				return moment(this.dateRange.end).format('YYYY-MM-DD');
			},
			formattedOutboundRangeDate() {
				return moment(this.dateRange.start).format('YYYY-MM-DD');
			},
		},
		methods: {
			searchFlight() {
				if (this.adultsCounter > 0 || this.childsCounter > 0 || this.babysCounter > 0) {
					this.noPassenger = false;
					this.$validator.validateAll().then(result => {
						if (result) {
							this.isPageLoading = true;
							let origin;
							let destination;

							if (this.departureAirport === 'OPO-sky' || this.departureAirport === 'LIS-sky') {
								origin = this.departureAirport;
							} else {
								origin = this.departureAirport.PlaceId;
							}

							if (this.arrivalAirport === 'OPO-sky' || this.arrivalAirport === 'LIS-sky') {
								destination = this.arrivalAirport;
							} else {
								destination = this.arrivalAirport.PlaceId;
							}

							let data;

							if (this.activeTab === 0) {
								data = {
									currency: 'EUR',
									originPlace: origin,
									destinationPlace: destination,
									outboundDate: this.formattedDate,
									adults: this.adultsCounter,
									children: this.childsCounter,
									infants: this.babysCounter,
									sort: this.sort,
									stops: this.stops,
								};
							} else {
								data = {
									currency: 'EUR',
									originPlace: origin,
									destinationPlace: destination,
									outboundDate: this.formattedOutboundRangeDate,
									inboundDate: this.formattedInboundRangeDate,
									adults: this.adultsCounter,
									children: this.childsCounter,
									infants: this.babysCounter,
									sort: this.sort,
									stops: this.stops,
								};
							}

							getFlight(data)
								.then(response => {
									console.log(response.data);
									if (response.data.length > 0 && this.activeTab === 0) {
										this.$store.commit('flightSteps/setActiveFlightStep', 2);
										this.$store.commit('flightSteps/setFlightData', response.data);
										this.$store.commit('flightSteps/setPassengers', data);
										/* 	this.$store.commit('flightSteps/setSearchOrder', this.sort); */
										this.isPageLoading = false;
									} else if (response.data.length > 0 && this.activeTab === 1) {
										this.$store.commit('flightSteps/setActiveFlightStep', 1);
										this.$store.commit('flightSteps/setFlightData', response.data);
										this.$store.commit('flightSteps/setPassengers', data);
										/* 	this.$store.commit('flightSteps/setSearchOrder', this.sort); */
										this.isPageLoading = false;
									} else {
										this.$buefy.toast.open({
											message: 'Os teus dados estão a ser processados. Por favor tenta novamente.',
											position: 'is-top',
											type: 'is-danger',
										});
										this.isPageLoading = false;
									}
								})
								.catch(error => {
									this.isLoading = false;
									this.$buefy.toast.open({
										message: error,
										position: 'is-top',
										type: 'is-danger',
									});
								});
						}
					});
				} else {
					this.noPassenger = true;
				}
			},
			track() {
				this.activeTrackingTab = 1;
				this.$validator.validateAll().then(result => {
					if (result) {
						let origin;
						let destination;

						if (this.departureAirport === 'OPO-sky' || this.departureAirport === 'LIS-sky') {
							origin = this.departureAirport;
						} else {
							origin = this.departureAirport.PlaceId;
						}

						if (this.arrivalAirport === 'OPO-sky' || this.arrivalAirport === 'LIS-sky') {
							destination = this.arrivalAirport;
						} else {
							destination = this.arrivalAirport.PlaceId;
						}

						let data;

						if (this.activeTab === 0) {
							data = {
								originPlace: origin,
								destinationPlace: destination,
								outboundDate: this.formattedDate,
								frequency: this.tracking,
							};
						} else {
							data = {
								originPlace: origin,
								destinationPlace: destination,
								outboundDate: this.formattedOutboundRangeDate,
								inboundDate: this.formattedInboundRangeDate,
								frequency: this.tracking,
							};
						}

						trackFlight(data)
							.then(response => {
								console.log(response);
								this.$buefy.toast.open({
									message: `Tracking criado com sucesso e guardado em "Os meus voos".`,
									position: 'is-top',
									type: 'is-primary',
								});
								/*  this.frequency = "daily";
	             this.date = new Date();
	             this.dateRange = new Date(); */
							})
							.catch(error => {
								console.log(error);
								this.isLoading = false;
								this.$buefy.toast.open({
									message: error,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					}
				});
			},
			swapPlaces() {
				let arrivalTochange;
				arrivalTochange = this.arrivalAirport;
				this.arrivalAirport = this.departureAirport;
				this.departureAirport = arrivalTochange;
				this.activeTrackingTab = 0;
			},

			addAdultsCounter() {
				this.adultsCounter++;
				this.activeTrackingTab = 0;
			},
			minusAdultsCounter() {
				this.activeTrackingTab = 0;
				this.adultsCounter--;
				if (this.adultsCounter < 0) {
					this.adultsCounter = 0;
				}
			},
			addChildsCounter() {
				this.activeTrackingTab = 0;
				this.childsCounter++;
			},
			minusChildsCounter() {
				this.activeTrackingTab = 0;
				this.childsCounter--;
				if (this.childsCounter < 0) {
					this.childsCounter = 0;
				}
			},
			addBabysCounter() {
				this.activeTrackingTab = 0;
				this.babysCounter++;
			},
			minusBabysCounter() {
				this.activeTrackingTab = 0;
				this.babysCounter--;
				if (this.babysCounter < 0) {
					this.babysCounter = 0;
				}
			},

			Tab(tab) {
				this.activeTab = tab;
			},
		},
		created() {
			this.userInfo = this.$store.getters['auth/getUserData'];
		},
		mounted() {
			getAirports(this.userInfo.info.destination_country_long_name)
				.then(response => {
					console.log(response);
					this.airports = response.data;
					console.log(this.airports.Places);
					this.airports = this.airports.Places.slice(1);
					console.log(this.airports);
				})
				.catch(error => {
					this.isLoading = false;
					this.$buefy.toast.open({
						message: error,
						position: 'is-top',
						type: 'is-danger',
					});
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';
	@import '../../assets/scss/flightForm';

	.header .title {
		font-size: 1.5rem;
	}

	.label,
	input,
	select,
	.passangers-title,
	.passangers-title {
		font-size: 0.9rem;
	}
</style>
