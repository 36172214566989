<template>
  <section>
    <div class="chat-div">
      <p class="title has-text-primary"><b>Chat</b></p>

      <div class="tabs is-toggle center-inside is-toggle-rounded">
        <div class="tabs-shadow">
          <ul>
            <li @click="activeTab=0" :class="{'is-active':activeTab===0}">
              <a>
                <span><b>Conversas</b></span>
              </a>
            </li>
            <li @click="activeTab=1" :class="{'is-active':activeTab===1}">
              <a>
                <span><b>Pedidos</b></span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <AllChats v-if="userData.info&&activeTab===0" :rooms="rooms"></AllChats>
      <ChatRequests v-else-if="userData.info&&activeTab===1" :rooms="rooms"></ChatRequests>


    </div>
  </section>
</template>
<script>
//Mixins
import {userInfo} from "@/mixins/shared/userInfo";
import {loading} from "@/mixins/shared/loading";
//Components
import AllChats from "@/components/Chat/AllChats";
import ChatRequests from "@/components/Chat/ChatRequests";

export default {
  name: "Chat",
  components: {AllChats, ChatRequests},
  mixins: [userInfo, loading],
  data() {
    return {
      activeTab: 0,
      rooms: [],
    };
  },
  created() {
    this.askForUnread();
  },
  methods: {
    askForUnread() {
      //pede as salas e o numero de mensagens nao lidas de um utilizador, convem ser o que está logado
      this.$socket.emit(
          "chat_ask_unread",
          this.$store.getters["auth/getToken"]
      );
    }
  },
  sockets: {
    chat_get_unread(rooms) {
      //devolve todas as salas em que o utilizador está, numero de mensagens nao lidas e informações para fazer uma lista

      this.rooms = rooms;
      console.log(this.rooms);
    },
    chat_receive_message(data) {
      //quando recebe uma mensagem nova, se a sala aberta for a que recebeu a mensagem adiciona ao array, senao aumenta o numero de mensagens nao lidas da sala correspondente, se a sala ainda nao existir, vai buscar todas as informações das salas (falta ver se isto funciona direito)
      console.log(data);

      this.rooms.forEach((room) => {
        if (room._id == data.room_id) {
          room.unread++;
          room.last_message.message = data.message
        }
      });
      /*if (!found) this.askForUnread();*/
    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/chat";

.tabs.is-toggle.is-toggle-rounded li:first-child a {
	border-radius: 20px;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
	border-radius: 20px;
}

.tabs-shadow {
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;

}

.tabs.is-toggle a {
  border: none;
  color: #1d4696;
}

.tabs {
  padding: 10px;
}

.tabs.is-toggle li.is-active a {
  color: #1d4696;
}

.title {
  font-size: 1.5rem;
  margin-top:4px
}

</style>