<template>
	<section>
		<div class="goods-services-fields">
			<div class="field email-field">
				<label class="label">Título</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('title') }" type="text" v-validate="'required'" name="title" v-model="title" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('title') && title != ''" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('title')" class="help is-danger">
					Introduza o título
				</p>
			</div>
			<div class="field email-field">
				<label class="label">Descrição</label>
				<div class="control has-icons-right">
					<textarea class="input is-success" type="text" maxlength="400" name="description" v-model="description" />
				</div>
				<small class="counter"> {{ description.length }} / 400 </small>
			</div>
			<div class="field email-field">
				<label class="label">Categoria</label>
				<div class="select control">
					<select v-validate="'required'" v-model="selectedService" name="services">
						<option v-for="service in servicesList" :value="service.id">{{ service.name_pt }}</option>
					</select>
					<p v-show="errors.has('services')" class="help is-danger">
						Selecione uma categoria
					</p>
				</div>
			</div>
			<div class="field email-field">
				<label class="label">Preço/hora(€)</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('price') }" type="number" v-validate="'required'" name="price" v-model="price" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('price') && price != null" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('price')" class="help is-danger">
					Introduza o preço por hora
				</p>
				<br />
			</div>
			<div class="field email-field">
				<label class="label">Raio de ação(km)</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('range') }" type="number" v-validate="'required'" name="range" v-model="range" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('v') && range != null" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('price')" class="help is-danger">
					Introduza o preço por hora
				</p>
				<br />
			</div>

			<label class="radio">
				<input type="checkbox" value="true" v-model="radioContact" name="answer" />
				Partilha de Contacto
			</label>
			<br />
			<br />
			<label class="radio">
				<input type="checkbox" value="true" v-model="radioAddress" name="answer" />
				Partilha de Morada
			</label>
			<br />
			<p v-show="contactError" style="position:unset" class="help is-danger">Selecione pelo menos uma opção de contacto</p>
			<br />
			<div class="next-div has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" @click="annouceService" :class="{ 'is-loading': isLoading }">
					Anunciar
				</button>
			</div>
			<br />
			<br />
		</div>
	</section>
</template>

<script>
	//API
	import { createService, searchService } from '@/api/apiGoodsAndServices';
	//Mixins
	import { servicesSteps } from '@/mixins/services/servicesSteps';

	export default {
		name: 'CreateGoods',
		mixins: [servicesSteps],
		props: {
			servicesList: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				title: '',
				description: '',
				editFile: {},
				selectedService: '',
				isLoading: false,
				userImg: '',
				price: null,
				radioContact: false,
				radioAddress: false,
				range: null,
				contactError: false,
			};
		},
		created() {},

		methods: {
			annouceService() {
				this.isLoading = true;
				if (this.radioContact === false && this.radioAddress === false) {
					this.contactError = true;
				} else this.contactError = false;

				this.$validator.validateAll().then(result => {
					if (result && this.contactError === false) {
						let data = {
							service_type_id: this.selectedService,
							title: this.title,
							description: this.description,
							hourly_price: this.price,
							share_contact: this.radioContact,
							share_location: this.radioAddress,
							range_radius: this.range,
						};
						createService(data)
							.then(response => {
								console.log(response);
								this.$buefy.toast.open({
									message: 'Anúncio criado com sucesso',
									position: 'is-top',
									type: 'is-primary',
								});
								this.setServices(0, null);

								this.$emit('changeTab');
							})
							.catch(error => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';
	@import '../../assets/scss/goodsAndServices';

	.select,
	select {
		width: 100% !important;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.file-cta {
		width: 400px;
		height: 200px;
		padding: 0;
		background-color: #bac3dc !important;
	}

	.profile-photo {
		width: 120px;
		height: 120px;
	}

	.button {
		margin-top: 30px;
	}
</style>
