<template>
	<section class="section-card" v-if="!showError">
		<div class="card">
			<div class="content">
				<div class="header">
					<img v-if="this.details.user.emigrant.photo" class="profile-photo inline" :src="this.details.user.emigrant.photo" />
					<div v-else class="diff-file-cta center-inside inline">
						<i class="diff-file-icon mdi mdi-camera-outline mdi-26px"></i>
					</div>
					<div class="inline">
						<span
							class="new-post-text"
							:style="details.user.emigrant.photo ? 'transform: translateY(-20px)' : 'transform: translateY(0px)'"
							v-if="this.details.user.emigrant.name.length < 17"
							>Publicação de {{ this.details.user.emigrant.name }}</span
						>
						<span v-else class="new-post-text" :style="details.user.emigrant.photo ? 'transform: translateY(-20px)' : 'transform: translateY(0px)'">
							Publicação de
							{{ this.details.user.emigrant.name.substring(0, 14) }}...
						</span>
					</div>
				</div>
				<div
					v-if="this.details.media_url && this.details.media_type === 'image'"
					class="feed-image"
					:style="{ 'background-image': `url(${details.media_url})`}"
				
				/>
				<Media
					v-else-if="this.details.media_url && this.details.media_type === 'video'"
					class="feed-video"
					:kind="'video'"
					:controls="true"
					:loop="true"
					:src="details.media_url"
				>
				</Media>
				<div v-else class="file-post center-inside  ">
					<i class="file-icon-post  mdi mdi-camera-outline mdi-48px"></i>
				</div>

				<textarea
					class="input is-success"
					:class="{ 'error-text': errors.has('requestDescription') }"
					type="text"
					v-model="details.description"
					name="description"
					disabled
				/>

				<p v-if="details.likes.length !== 1" class="likes has-text-primary ">{{ details.likes.length }} gostos</p>
				<p v-else class="likes has-text-primary ">{{ details.likes.length }} gosto</p>
			</div>
		</div>
		<div class="next-div has-text-centered" style="display: flex;justify-content: center;">
			<button class="button is-rounded is-primary is-fullwidth publish" @click="goBack">
				Voltar
			</button>
		</div>
	</section>
	<div v-else style="height:100vh" class="goods-services-div has-text-centered margin">
		<div class="goods-header"></div>
		<Error type="publicação" />
	</div>
</template>

<script>
	import { getPostDetails } from '@/api/apiFeed';
	import { feedSteps } from '@/mixins/feed/feedSteps';
	import Error from '@/views/Error.vue';
	export default {
		mixins: [feedSteps],
		name: 'ViewPublication',
		components: {
			Error,
		},
		data() {
			return {
				postId: null,
				details: [],
				showError: false,
			};
		},
		methods: {
			postDetails() {
				getPostDetails(this.postId)
					.then(response => {
						this.details = response.data;
						console.log(this.details);
					})
					.catch(error => {
						if (error) {
							this.showError = true;
						}
					});
			},

			goBack() {
				this.setFeedInfo(0, null);
			},
		},

		created() {
			this.postId = this.$store.getters['feedSteps/getFeedData'];
			this.postDetails();
		},
		beforeDestroy() {
			this.setFeedInfo(0, null);
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/createPublication';
</style>
