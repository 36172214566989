<template>
	<div>
		<navbar></navbar>
		<sidebar @clicked="onClickChild"></sidebar>

		<div class="content">
			<Business v-if="showBusiness"></Business>
			<Users v-if="showUsers"></Users>
			<Tickets v-if="showTickets"></Tickets>
			<Control v-if="showControl"></Control>
		</div>
	</div>
</template>

<script>
	import Sidebar from '../components/Backoffice/Sidebar';
	import Navbar from '../components/Backoffice/Navbar';
	import Business from '../components/Backoffice/Business';
	import Users from '../components/Backoffice/Users';
	import Tickets from '../components/Backoffice/Tickets';
	import Control from '../components/Backoffice/Control';

	export default {
		components: {
			Sidebar,
			Navbar,
			Business,
			Users,
			Tickets,
      Control
		},
		data() {
			return {
				showBusiness: true,
				showUsers: false,
				showTickets: false,
				showControl: false,
			};
		},
		methods: {
			onClickChild(value) {
				if (value === 'business') {
					this.showBusiness = true;
					this.showUsers = false;
					this.showTickets = false;
					this.showControl = false;
				}
				if (value === 'users') {
					this.showBusiness = false;
					this.showUsers = true;
					this.showTickets = false;
					this.showControl = false;
				}
				if (value === 'tickets') {
					this.showBusiness = false;
					this.showUsers = false;
					this.showTickets = true;
					this.showControl = false;
				}
				if (value === 'control') {
					this.showBusiness = false;
					this.showUsers = false;
					this.showTickets = false;
					this.showControl = true;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		padding-top: 68px;
		padding-left: 150px;
	}

	@media screen and (max-width: 768px) {
		.content {
			padding-left: 0;
		}
	}
</style>
