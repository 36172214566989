<template>
	<section>
		<div class="auth-div ">
			<div class="img center-inside">
				<img width="140" src="../assets/logos/logo.png" />
			</div>
			<p class="title has-text-primary"><b>Login</b></p>

			<div class="field email-field">
				<label class="label">Email</label>
				<div class="control has-icons-right">
					<input class="input  is-success" :class="{ 'error-text': errors.has('email') }" type="email" v-validate="'required|email'" name="email" v-model="login_email" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('email') && login_email != ''" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('email')" class="help is-danger">
					Formato de email inválido
				</p>
			</div>

			<div class="field">
				<label class="label">Password</label>
				<div class="control">
					<b-input
						type="password"
						name="password"
						ref="password"
						:class="{ 'error-text': errors.has('password') }"
						v-validate="'required|min:8'"
						v-model="login_password"
						password-reveal
					>
					</b-input>
				</div>
				<p v-show="errors.has('password')" class="help is-danger">
					A password é demasiado pequena
				</p>
			</div>

			<div class="auth-buttons has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isLoading }" @click="submitLogin">
					Login
				</button>
				<p class="link-text is-click" @click="goToPage('/recover_password')">
					Esqueci-me da password?
				</p>

				<p class="link-text is-click mt-2" @click="goToPage('/resend')">
					Reenviar email de ativação
				</p>

				<div class="is-divider" data-content="OU"></div>

				<div class="field has-text-left">
					<div style="display: inline-flex;align-items: center;">
						<label class="label has-text-left">Continuar com</label>
						<button @click="googleLogin" class="social-button">
							<i class="mdi mdi-google mdi-24px"> </i>
						</button>
						<button @click="facebookLogin" class="social-button">
							<i class="mdi mdi-facebook mdi-24px"> </i>
						</button>

						<button class="social-button" @click="twitterLogin">
							<i class="mdi mdi-twitter mdi-24px"></i>
						</button>
					</div>
					<hr />
				</div>
				<p class="small-text">
					Ainda não tens conta?
					<span class="link-text is-click" @click="goToPage('/register')">Regista-te agora!</span>
				</p>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { goToPage } from '@/mixins/shared/goToPage';
	import { socialAuth } from '@/mixins/auth/socialAuth';
	import { loading } from '@/mixins/shared/loading';
	//API
	import { login } from '@/api/apiAuth';
	import { startPushedNotifications } from '@/mixins/notifications/notifications';

	export default {
		mixins: [goToPage, socialAuth, loading],
		name: 'Login',
		components: {},
		data() {
			return {
				login_email: '',
				login_password: '',
				isLoading: false,
			};
		},
		methods: {
			submitLogin() {
				this.isLoading = true;
				this.$validator.validateAll().then(result => {
					if (result) {
						login(this.login_email, this.login_password)
							.then(response => {
								console.log(response);
								this.isLoading = false;
								if (response.status === 200) {
									startPushedNotifications();
									this.$store.dispatch('auth/authenticateUser', response, {
										root: true,
									});
									this.goToPage('/');
									this.isLoading = false;
								}
							})
							.catch(error => {
								this.isLoading = false;

								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/auth';

	.auth-div {
		top: 30%;
	}

	.email-field {
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.button {
		margin-top: 40px;
		margin-bottom: 10px;
	}

	hr {
		margin-top: 15px;
	}

	.social-button {
		background-color: white;
		border: none;

		margin-left: 15px;
	}

	.social-button > .mdi {
		color: #1d4696;
	}

	.auth-div {
		position: relative !important;
		padding-top: 40px;
		padding-bottom: 20px;
	}
</style>
