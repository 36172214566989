import { get, post, put, del } from '@/utils/http';
import axios from 'axios';
const queryString = require('query-string');

export function createCarTrip(data) {
	console.log(data);
	return post('/cartrips', data);
}

export function getAvailableCarTrips(query = {}) {
	let _query = '?' + queryString.stringify(query);
	return get('/cartrips/search' + _query);
}

export function listOwnCarTrips() {
	return get('/cartrips?open=true');
}

export function updateCarTrip(id, places) {
	return put('/cartrips/' + id, {
		available_places: places,
	});
}

export function deleteCarTrip(id) {
	return del('/cartrips/' + id);
}

export function requestHitchhike(data) {
	return post('/hitchhikes', data);
}

export function listOwnHitchhikes() {
	return get('/hitchhikes?open=true');
}

export function listOwnWishCartrips() {
	return get('/wishcartrips?open=true');
}

export function createWishCartrip(data) {
	return post('/wishcartrips', data);
}

export function deleteWishCartrip(id) {
	return del('/wishcartrips/' + id);
}

export function answerHitchhikeRequest(id, answer) {
	return put('/hitchhikes/answer/' + id, {
		answer: answer,
	});
}

export function deleteHitchhike(id) {
	return del('/hitchhikes/' + id);
}

/*export function getRoutes(origin, destination) {
    return route('origin=' + origin + '&destination=' + destination + '&key=' + process.env.VUE_APP_GMAPS_KEY)
}*/
