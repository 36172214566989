<template>
	<section>
		<div class="auth-div">
			<div class="header">
				<i class="mdi mdi-chevron-left mdi-36px" style="margin-right:10px" @click="$router.back()"></i>
				<span class="title">{{ details.OutboundLegId.OriginStation.Code }}-{{ details.OutboundLegId.DestinationStation.Code }}</span>
			</div>

			<div class="going-card">
				<div class="media" style="padding-top: 6%">
					<div class="media-left">
						<p class="date">
							{{ details.OutboundLegId.Departure | moment('D MMMM') }}
						</p>
					</div>
					<div class="media-content"></div>
					<div class="media-right"></div>
				</div>
				<div class="media">
					<div class="media-left size">
						<p class="hour">
							{{ details.OutboundLegId.Departure | moment('HH:mm') }}
						</p>
						<p class="dark-label">
							{{ details.OutboundLegId.OriginStation.Code }}
						</p>
					</div>
					<div class="media-content">
						<p class="dark-label">
							{{ details.OutboundLegId.Duration | moment('HH:mm') }}
						</p>
						<hr />
						<div class="company-container">
							<p class="initials">
								{{ details.OutboundLegId.Carriers[0].Name }}
							</p>
							<img class="logo-company" :src="details.OutboundLegId.Carriers[0].ImageUrl" alt="" />
						</div>
					</div>
					<div class="media-right size">
						<p class="hour">
							{{ details.OutboundLegId.Arrival | moment('HH:mm') }}
						</p>
						<p class="dark-label">
							{{ details.OutboundLegId.DestinationStation.Code }}
						</p>
					</div>
				</div>

				<div class="media" v-if="details.InboundLegId" style="padding-top: 6%">
					<div class="media-left ">
						<p class="date">
							{{ details.InboundLegId.Departure | moment('D MMMM') }}
						</p>
					</div>
					<div class="media-content"></div>
					<div class="media-right"></div>
				</div>

				<div class="media" v-if="details.InboundLegId">
					<div class="media-left size">
						<p class="hour">
							{{ details.InboundLegId.Departure | moment('HH:mm') }}
						</p>
						<p class="dark-label">
							{{ details.InboundLegId.OriginStation.Code }}
						</p>
					</div>
					<div class="media-content">
						<p class="dark-label">
							{{ details.InboundLegId.Duration | moment('HH:mm') }}
						</p>
						<hr />
						<div class="company-container">
							<p class="initials">
								{{ details.InboundLegId.Carriers[0].Name }}
							</p>
							<img class="logo-company" :src="details.InboundLegId.Carriers[0].ImageUrl" alt="" />
						</div>
					</div>
					<div class="media-right size">
						<p class="hour">
							{{ details.InboundLegId.Arrival | moment('HH:mm') }}
						</p>
						<p class="dark-label">
							{{ details.InboundLegId.DestinationStation.Code }}
						</p>
					</div>
				</div>

				<div class="media">
					<div
						class="media-left"
						style="    text-align: left;
                 transform: translateY(-25px);"
					>
						<p class="dark-label" v-if="adults > 0">{{ adults }} x Adultos</p>
						<p class="dark-label" v-if="children > 0">{{ children }} x Crianças</p>
						<p class="dark-label" v-if="infants > 0">{{ infants }} x Bébes</p>
					</div>
					<div class="media-content"></div>
					<div class="media-right">
						<div class="price">
							<p class="price-value">{{ details.PricingOptions[0].Price }} €</p>
						</div>
					</div>
				</div>
			</div>

			<button
				class="button request is-rounded is-primary"
				style="position: absolute;bottom: 0px;width: 88%;
"
			>
				<a style="color:white" :href="details.PricingOptions[0].DeeplinkUrl">Reservar</a>
			</button>
		</div>
	</section>
</template>

<script>
	import { flightSteps } from '@/mixins/flight/flightSteps';
	import fligthCharts from '@/components/Flights/FlightCharts.vue';
	export default {
		mixins: [flightSteps],
		components: {
			fligthCharts,
		},
		data() {
			return {
				details: null,
				adults: 0,
				infants: 0,
				children: 0,
				activeTab: 0,
			};
		},
		created() {
			console.log(this.$route.params.details);
			this.details = this.$route.params.details;
			console.log(this.getPassengers);
			this.adults = this.getPassengers.adults;
			this.infants = this.getPassengers.infants;
			this.children = this.getPassengers.children;
			console.log(this.adults);
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';
	@import '../../assets/scss/flightDetails';

	a {
		width: 100%;
	}

	.price {
		width: 86px;
	}
	.logo-company {
		object-fit: scale-down;
		width: 50px;
	}

	.company-container {
		justify-content: center;
		margin-top: 10px;
	}

	.header {
		margin-top: 6px;
	}

	.hour,
	.date,
	.initials,
	.dark-label,
	.price-value {
		font-size: 0.9rem;
	}

	.request {
		margin-bottom: 1rem;
	}

	
</style>
