<template>
	<section>
		<loading :isPageLoading="isPageLoading"></loading>
		<div class="hitchhike-div">
			<p class="title has-text-primary"><b>Boleias</b></p>
			<div class="tabs is-toggle center-inside is-toggle-rounded">
				<div class="tabs-shadow">
					<ul>
						<li @click="activeTab = 0" :class="{ 'is-active': activeTab === 0 }">
							<a>
								<span><b>Pedidos</b></span>
							</a>
						</li>
						<li @click="activeTab = 1" :class="{ 'is-active': activeTab === 1 }">
							<a class="middle-tab">
								<span><b>Anúncios</b></span>
							</a>
						</li>
						<li @click="activeTab = 2" :class="{ 'is-active': activeTab === 2 }">
							<a>
								<span><b>Favoritos</b></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<RequestedHitchhikes :requestedHitchhikes="requestedHitchhikes" v-if="activeTab === 0 && requestedHitchhikes !== null"></RequestedHitchhikes>
			<CretedHitchhikes :ownHitchhikes="ownHitchhikes" v-else-if="activeTab === 1 && ownHitchhikes !== null"></CretedHitchhikes>
			<Favourites :favouriteCarTrips="favouriteCarTrips" v-else-if="activeTab === 2 && favouriteCarTrips !== null"></Favourites>
		</div>
	</section>
</template>
<script>
	//API
	import { listOwnCarTrips, listOwnHitchhikes, listOwnWishCartrips } from '@/api/apiRides';

	//Components
	import Favourites from '@/components/Hitchhike/MyHitchhikes/Favourites';
	import RequestedHitchhikes from '@/components/Hitchhike/MyHitchhikes/ListRequestedHitchhikes';
	import CretedHitchhikes from '@/components/Hitchhike/MyHitchhikes/ListCreatedHitchhikes';
	import Loading from '@/components/Loading/Loading';

	import moment from 'moment';

	export default {
		name: 'MyHitchhikes',
		components: { Favourites, CretedHitchhikes, RequestedHitchhikes, Loading },
		data() {
			return {
				activeTab: 0,
				favouriteCarTrips: null,
				ownHitchhikes: null,
				requestedHitchhikes: null,
				isPageLoading: true,
			};
		},
		created() {
			listOwnWishCartrips()
				.then(response => {
					for (let i = 0; i < response.data.length; i++) {
						let day = response.data[i].car_trip.departure_date.substring(8, 10);
						let month = response.data[i].car_trip.departure_date.substring(5, 7);
						let hour = response.data[i].car_trip.departure_date.substring(11, 13);
						let minutes = response.data[i].car_trip.departure_date.substring(14, 16);
						response.data[i].complete_hour = response.data[i].car_trip.departure_date;
						response.data[i].car_trip.departure_date = day + '/' + month;
						response.data[i].car_trip.departure_hour = hour + 'h:' + minutes;
					}
					this.favouriteCarTrips = response.data;
					
				})
				.catch(error => {});

			listOwnCarTrips()
				.then(response => {
					
					response.data.sort(function(a, b) {
						return new Date(b.departure_date) - new Date(a.departure_date);
					});
					for (let i = 0; i < response.data.length; i++) {
						response.data[i].hitchhikes.sort(function(a, b) {
							return new Date(a.createdAt) - new Date(b.createdAt);
						});
					}

					for (let i = 0; i < response.data.length; i++) {
						console.log(response.data[i].departure_date);
						let day = response.data[i].departure_date.substring(8, 10);
						let month = response.data[i].departure_date.substring(5, 7);
						let hour = response.data[i].departure_date.substring(11, 13);
						let minutes = response.data[i].departure_date.substring(14, 16);
						response.data[i].complete_hour = response.data[i].departure_date;
						response.data[i].departure_date = day + '/' + month;
						response.data[i].departure_hour = hour + 'h:' + minutes;
					}
					this.ownHitchhikes = response.data;
				
				})
				.catch(error => {});

			listOwnHitchhikes()
				.then(response => {
					console.log(response.data);
					for (let i = 0; i < response.data.length; i++) {
						let day = response.data[i].car_trip.departure_date.substring(8, 10);
						let month = response.data[i].car_trip.departure_date.substring(5, 7);
						let hour = response.data[i].car_trip.departure_date.substring(11, 13);
						let minutes = response.data[i].car_trip.departure_date.substring(14, 16);
						response.data[i].complete_hour = response.data[i].car_trip.departure_date;
						response.data[i].departure_date = day + '/' + month;
						response.data[i].departure_hour = hour + 'h:' + minutes;
					}
					this.requestedHitchhikes = response.data;
					this.isPageLoading = false
				})
				.catch(error => {});
		},
	};
</script>

<style scoped lang="scss">
	@import '../../assets/scss/hitchhike';

	.tabs.is-toggle.is-toggle-rounded li:first-child a {
			border-radius: 20px;
	}

	.tabs.is-toggle.is-toggle-rounded li:last-child a {
			border-radius: 20px;
	}

	.tabs-shadow {
		/* border-radius */
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		/* box-shadow */
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.tabs.is-toggle a {
		border: none;
		color: #1d4696;
	}

	.tabs {
		padding: 10px;
	}

	.tabs.is-toggle li.is-active a {
		color: #1d4696;
	}

	.middle-tab {
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
	}
</style>
