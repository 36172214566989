<template>
	<section>
		<div class="hitchhike-fields">
			<div class="field email-field">
				<label class="label">De</label>
				<div class="select control">
					<select v-validate="'required'" v-model="departureAddress" name="departureAddress">
						<option :selected="originInfo !== arrivalAddress" :value="originInfo">{{ originInfo.formatted_address }}</option>
						<option :selected="originInfo !== arrivalAddress" :value="destinationInfo">{{ destinationInfo.formatted_address }}</option>
					</select>
					<p v-show="errors.has('departureAddress')" class="help is-danger">
						Selecione um ponto de partida
					</p>
				</div>
			</div>
			<div>
				<i class="mdi mdi-swap-vertical mdi-24px" @click="swapPlaces" style="margin-right:10px"></i>
			</div>
			<div class="field email-field">
				<label class="label">Para</label>
				<div class="select control">
					<select v-validate="'required'" v-model="arrivalAddress" name="arrivalAddress">
						<option :value="originInfo">{{ originInfo.formatted_address }}</option>
						<option :value="destinationInfo">{{ destinationInfo.formatted_address }}</option>
					</select>
					<p v-show="errors.has('arrivalAddress')" class="help is-danger">
						Selecione um ponto de chegada
					</p>
				</div>
				<br />
			</div>
			<br />
			<div class="field email-field">
				<vc-date-picker :min-date="todayDate" v-model="departureDates" is-range :disabled-dates="dates" />
				<input style="transform: translateY(2px);" id="all" type="checkbox" @change="checkAllDates" v-model="allDates" /> <label for="all">Qualquer data</label>
			</div>
			<div class="field email-field">
				<label class="label">Nº de lugares pretendidos</label>
				<div class="select control">
					<select v-validate="'required'" v-model="hitchhikePlaces" name="hitchhikePlaces">
						<option :value="1">1</option>
						<option :value="2">2</option>
						<option :value="3">3</option>
						<option :value="4">4</option>
						<option :value="5">5</option>
						<option :value="6">6</option>
						<option :value="7">7</option>
						<option :value="8">8</option>
					</select>
				</div>
				<p v-show="errors.has('hitchhikePlaces')" class="help is-danger">
					Selecione o número de lugares disponíveis
				</p>
			</div>
			<br />
			<div class="next-div has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" @click="searchCarTrip" :class="{ 'is-loading': isLoading }">
					Pesquisar
				</button>
			</div>
			<br />
			<br />
		</div>
	</section>
</template>

<script>
	//API
	import { getAvailableCarTrips } from '@/api/apiRides';
	//Mixins
	import { hitchhikeSteps } from '@/mixins/hitchhikes/hitchhikeSteps';
	import moment from 'moment';

	export default {
		name: 'AskForRide',
		mixins: [hitchhikeSteps],
		props: {
			userData: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				departureDates: {
					start: new Date(),
					end: new Date(),
				},
				carUserSeats: [],
				hitchhikePlaces: 1,
				departureAddress: null,
				arrivalAddress: null,
				isLoading: false,
				originInfo: null,
				destinationInfo: null,
				todayDate: new Date(),
				allDates: false,
				dates: {
					weekdays: [],
				},
			};
		},
		created() {
			/* this.todayDate.setDate(this.todayDate.getDate() - 1);*/
			this.originInfo = {
				country: this.userData.info.origin_country,
				zip_code: this.userData.info.origin_zip_code,
				formatted_address: this.userData.info.origin_formatted_address,
			};
			this.destinationInfo = {
				country: this.userData.info.destination_country,
				zip_code: this.userData.info.destination_zip_code,
				formatted_address: this.userData.info.destination_formatted_address,
			};
		},
		computed: {
			checkAllDates() {
				if (this.allDates) {
					this.departureDates = null;
					this.dates = {
						weekdays: [1, 2, 3, 4, 5, 6, 7],
					};
				} else {
					this.departureDates = {
						start: new Date(),
						end: new Date(),
					};
					this.dates = {
						weekdays: [],
					};
				}
			},
		},
		methods: {
			swapPlaces() {
				let arrivalTochange;
				arrivalTochange = this.arrivalAddress;
				this.arrivalAddress = this.departureAddress;
				this.departureAddress = arrivalTochange;
			},
			formatDate(date) {
				console.log(date);
				return moment(date).format('YYYY-MM-DD');
				/* let d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();

				if (month.length < 2) month = '0' + month;
				if (day.length < 2) day = '0' + day;
				return [year, month, day].join('-'); */
			},
			searchCarTrip() {
				this.isLoading = true;

				if (this.allDates) {
					this.departureDates = null;
				} 

				this.$validator.validateAll().then(result => {
					if (result) {
						let data = {
							hitchhike_places: this.hitchhikePlaces,
							...(this.departureDates && { departure_range_start: this.formatDate(this.departureDates.start) }),
							...(this.departureDates && { departure_range_end: this.formatDate(this.departureDates.end) }),
							departure_zip_code: this.departureAddress.zip_code,
							departure_country: this.departureAddress.country,
							arrival_zip_code: this.arrivalAddress.zip_code,
							arrival_country: this.arrivalAddress.country,
						};
						console.log(data);
						getAvailableCarTrips(data)
							.then(response => {
								console.log(response);
								if (response.data.length <= 0) {
									this.$buefy.toast.open({
										message: 'Não existem viagens com as carateristicas selecionadas',
										position: 'is-top',
										type: 'is-danger',
									});
								} else {
									for (let i = 0; i < response.data.length; i++) {
										response.data[i].departure_hour = moment(response.data[i].departure_date).format('HH');
										response.data[i].minutes = moment(response.data[i].departure_date).format('mm');
										let day = response.data[i].departure_date.substring(8, 10);
										let month = response.data[i].departure_date.substring(5, 7);
										let hour = response.data[i].departure_date.substring(11, 13);
										let minutes = response.data[i].departure_date.substring(14, 16);
										response.data[i].departure_date = day + '/' + month;
										/* response.data[i].departure_hour = hour + 'h:' + minutes; */
									}
									data.response = response.data;

									this.setHitchhike(4, data);
								}
								this.isLoading = false;
							})
							.catch(error => {
								this.isLoading = false;
								console.log(error);
								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';
	@import '../../../assets/scss/hitchhike';

	.select,
	select {
		width: 100% !important;
	}
</style>
