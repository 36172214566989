<template>
	<b-table :data="data" :row-class="(row, index) => row.name === 'Utilizador Apagado' ? 'is-hidden' : ''">
		<b-table-column>
			<template v-slot="props">
				<div class="mobile">
					<div class="file-cta center-inside">
						<img class="profile-photo" v-if="props.row.photo" :src="props.row.photo" />

						<i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
					</div>
				</div>
			</template>
		</b-table-column>
		<template v-for="column in columns">
			<b-table-column :key="column.id" v-bind="column">
				<template v-if="column.searchable && !column.numeric" #searchable="props">
					<b-input v-model="props.filters[props.column.field]" placeholder="Pesquisa" icon="magnify" size="is-small" />
				</template>
				<template v-slot="props">
					{{ props.row[column.field] }}
				</template>
			</b-table-column>
		</template>

		<b-table-column label="Email">
			<template v-slot="props">
				{{ props.row.user.email }}
			</template>
		</b-table-column>

		<b-table-column v-slot="props">
			<b-button rounded class="deactivate-account" v-if="props.row.user.active === true" @click="deactivate(props.row.user_id)">Desativar Conta</b-button>
			<b-button rounded v-else class="activate-account" @click="activate(props.row.user_id)">Ativar Conta</b-button>
		</b-table-column>

		<b-table-column v-slot="props">
			<b-button rounded class="deactivate-account" @click="deleteAccount(props.row.user_id, props.index)">Eliminar Utilizador</b-button>
		</b-table-column>
	</b-table>
</template>

<script>
	import { getUsers, deactivateAccount, activateAccount, deleteUser } from '@/api/apiBackOffice';

	export default {
		data() {
			return {
				data: [],
				id: '',
				columns: [
					{
						field: 'name',
						label: 'Nome',
						searchable: true,
						width: '400',
					},
					{
						field: 'origin_country',
						label: 'País de origem',
						searchable: false,
						centered: true,
					},
					{
						field: 'destination_country',
						label: 'País de acolhimento',
						searchable: false,
						centered: true,
					},
				],
			};
		},
		methods: {
			deactivate(id) {
				deactivateAccount(id)
					.then(response => {
						console.log(response);
						getUsers()
							.then(response => {
								console.log(response.data);
								this.data = response.data;
							})
							.catch(error => {});
					})
					.catch(error => {
						console.log(error);
					});
			},

			activate(id) {
				activateAccount(id)
					.then(response => {
						console.log(response);
						getUsers()
							.then(response => {
								console.log(response.data);
								this.data = response.data;
							})
							.catch(error => {});
					})
					.catch(error => {
						console.log(error);
					});
			},

			deleteAccount(id, index) {
				let self = this;
				this.$buefy.dialog.confirm({
					title: 'Eliminar Utilizador',
					canCancel: ['button'],
					message: '<p style="color: #1d4696">Tens a certeza que pretendes eliminar este utilizador?</p>',
					cancelText: 'Confirmar',
					confirmText: 'Não',
					type: 'is-primary',
					onCancel() {
						deleteUser(id)
							.then(response => {
								self.data.splice(index, 1);
								self.$buefy.toast.open({
									message: 'Utilizador apagado com sucesso',
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: 'Ocurreu um erro ao apagar o utilizador',
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
				});
			},
		},
		mounted() {
			getUsers()
				.then(response => {
					console.log(response.data);
					this.data = response.data;
				})
				.catch(error => {});
		},
	};
</script>
<style lang="scss" scoped>
	@import '../../assets/scss/backofficeUser';
</style>
