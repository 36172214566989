import { formData, get, post, del, put } from '@/utils/http';

export function createPost(data) {
	console.log(data);

	let form = new FormData();
	form.append('photo', data.photo);
	form.append('description', data.description);

	return formData('/posts', form);
}

export function getFeed(limit, offset) {
	return get(`/posts/feed?limit=${limit}&offset=${offset}`);
}

export function userLikePost(postId) {
	const params = new URLSearchParams();
	params.append('post_id', postId);

	return post('/likes', params);
}

export function userDislikePost(postId, likeId) {
	const params = new URLSearchParams();
	params.append('post_id', postId);

	return del('/likes/' + likeId, params);
}

export function getPostDetails(postId) {
	console.log(postId);
	return get('/posts/' + postId);
}

export function updatePost(postId,data) {
    console.log(data);

	let form = new FormData();
	form.append('photo', data.photo);
	form.append('description', data.description);

	return put('/posts/' + postId, form);
}

export function deletePost(postId) {
    return del ('/posts/' + postId);
}
