<template>
	<section>
		<div class="hitchhike-fields">
			<div class="field email-field">
				<label class="label">Marca</label>
				<div class="select control">
					<select v-validate="'required'" v-model="carBrand" name="carBrand" @change="getCarModel">
						<option :value="brand.id" v-for="brand in carBrandsArray">{{ brand.name }}</option>
					</select>
					<p v-show="errors.has('carBrand')" class="help is-danger">Selecione uma marca</p>
				</div>
			</div>

			<div class="field email-field">
				<label class="label">Modelo</label>
				<div class="select control">
					<select v-validate="'required'" v-model="carModel" name="carModel">
						<option :value="model.id" v-for="model in carModelsArray">{{ model.name }}</option>
					</select>
					<p v-show="errors.has('carModel')" class="help is-danger">Selecione um modelo</p>
				</div>
			</div>

			<div class="field email-field">
				<label class="label">Ano</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('carYear') }" type="number" v-validate="'required'" name="carYear" v-model="carYear" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('carYear') && carYear != '' && carYear > 1900 && carYear <= currentYear" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('carYear') || carYear <= 1900 || carYear > currentYear" class="help is-danger">Insira um ano válido</p>
			</div>

			<div class="field email-field">
				<label class="label">Cor</label>
				<div class="select control">
					<select v-validate="'required'" v-model="carColor" name="carColor">
						<option value="Preto">Preto</option>
						<option value="Branco">Branco</option>
						<option value="Prateado">Prateado</option>
						<option value="Cinzento">Cinzento</option>
						<option value="Vermelho">Vermelho</option>
						<option value="Azul">Azul</option>
						<option value="Castanho">Castanho</option>
						<option value="Verde">Verde</option>
						<option value="Amarelo">Amarelo</option>
						<option value="Outra">Outra</option>
					</select>
					<p v-show="errors.has('carColor')" class="help is-danger">Selecione a cor do automóvel</p>
				</div>
			</div>

			<div class="field email-field">
				<label class="label">Nº de lugares</label>
				<div class="select control">
					<select v-model="carSeats" name="carSeats">
						<option value="2">2</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="7">7</option>
						<option value="9">9</option>
					</select>
					<p v-show="errors.has('carSeats')" class="help is-danger">Selecione o número de lugares existentes no automóvel</p>
				</div>
			</div>
			<div class="field email-field">
				<label class="label">Nº de Lugares disponíveis</label>
				<div class="select control">
					<select v-validate="'required'" v-model="hitchhikePlaces" name="hitchhikePlaces">
						<option :value="number" v-for="number in carSeats - 1">{{ number }}</option>
					</select>
					<p v-show="errors.has('hitchhikePlaces')" class="help is-danger">Selecione o número de lugares disponíveis</p>
				</div>
			</div>

			<div>
				<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isButtonLoading }" @click="saveCar">Anunciar</button>
				<button class="button is-rounded is-danger is-fullwidth mt-2" @click="back">
					Cancelar
				</button>
			</div>
			<div class="register-steps center-inside is-fullwidth">
				<div></div>
				<div class="step"></div>
				<div></div>
			</div>
		</div>
		<gmap-map v-show="false" :center="{ lat: 40.9417347, lng: -2.8104512 }">
			<DirectionsRoutes
				travelMode="DRIVING"
				:origin="{ query: this.getHitchhikeData.formatted_departure_address }"
				:destination="{ query: this.getHitchhikeData.formatted_arrival_address }"
			></DirectionsRoutes>
		</gmap-map>
	</section>
</template>

<script>
	//API
	import { createVehicle, listAllCarBrands, listCarModelsByCarBrand } from '@/api/apiCar';
	import DirectionsRoutes from '@/components/Hitchhike/OfferHitchhike/DirectionsRoutes.js';
	//Mixins
	import { hitchhikeSteps } from '@/mixins/hitchhikes/hitchhikeSteps';

	export default {
		props: {
			userCar: {
				type: Array,
				required: true,
			},
		},
		name: 'HitchhikeCar',
		components: {
			DirectionsRoutes,
		},
		mixins: [hitchhikeSteps],
		data() {
			return {
				isButtonLoading: false,
				modalState: this.isModalOpen,
				carBrandsArray: [],
				carModelsArray: [],
				carBrand: '',
				carModel: '',
				carColor: 'Preto',
				carSeats: 5,
				carYear: '',
				hitchhikePlaces: 1,
				currentYear: null,
			};
		},
		created() {
			console.log(this.userCar);
			let d = new Date();
			this.currentYear = d.getFullYear();
			console.log(this.getHitchhikeData.formatted_departure_address);
			console.log(this.getHitchhikeData.formatted_arrival_address);
		

			listAllCarBrands()
				.then(response => {
					console.log(response);
					this.carBrandsArray = response.data;
					if (this.userCar.length > 0) {
						this.carBrand = this.userCar[0].car_model.car_brand_id;
					} else {
						this.carBrand = this.carBrandsArray[0].id;
					}

					listCarModelsByCarBrand(this.carBrand)
						.then(response => {
							this.carModelsArray = response.data;
							if (this.userCar.length > 0) {
								this.carModel = this.userCar[0].car_model_id;
								this.carColor = this.userCar[0].color;
								this.carSeats = this.userCar[0].places;

								this.carYear = this.userCar[0].year;
							} else {
								this.carModel = this.carModelsArray[0].id;
								this.carYear = this.currentYear;
							}
						})
						.catch(error => {});
				})
				.catch(error => {});
		},
		computed: {},
		methods: {
			getCarModel() {
				listCarModelsByCarBrand(this.carBrand)
					.then(response => {
						console.log(response);
						this.carModelsArray = response.data;
						this.carModel = this.carModelsArray[0].id;
					})
					.catch(error => {});
			},
			back() {
				this.setHitchhike(0, null);
			},
			saveCar() {
				this.$validator.validateAll().then(result => {
					if (result && this.carYear > 1900 && this.carYear <= this.currentYear) {
						this.isButtonLoading = true;
						let car = {
							car_model_id: this.carModel,
							color: this.carColor,

							places: this.carSeats,
							year: this.carYear,
						};
						createVehicle(car)
							.then(response => {
								this.isButtonLoading = true;
								let data = this.getHitchhikeData;
								let day = data.departure_date.substring(8, 10);
								let month = data.departure_date.substring(5, 7);
								let hour = data.departure_date.substring(11, 13);
								let minutes = data.departure_date.substring(14, 16);
								let nextPage = null;
								if (data.routes.routes.length > 1) {
									nextPage = 2;
								} else {
									nextPage = 3;
								}
								data.available_places = this.hitchhikePlaces;
								data.date = day + '/' + month;
								data.time = hour + 'h' + minutes;
								console.log(data);
								this.setHitchhike(nextPage, data);
							})
							.catch(error => {
								this.$buefy.toast.open({
									message: error,
									position: 'is-top',
									type: 'is-danger',
								});
								this.setHitchhike(0, null);
								this.isButtonLoading = true;
							});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';
	@import '../../../assets/scss/hitchhike';

	.hitchhike-fields {
		height: calc(100vh - 77px);
	}

	.field {
		margin-bottom: 0;
	}

	.email-field {
		position: relative;
		margin-bottom: 40px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.select-origin {
		color: #bac3dc !important;
	}

	.select,
	select {
		width: 100% !important;
	}

	.button-size {
		width: 180px;
		margin-left: 10px;
	}
	@media screen and (max-width: 360px) {
		.button-size {
			width: 140px;
			margin-left: 10px;
		}
	}
</style>
