<template>
	<div class="error-container">
		<div class="logo center-inside">
			<img width="140" src="../assets/logos/logo.png" />
		</div>
		<p class="has-text-primary title mt-4">Oops!</p>
		<p v-if="this.$route.name === 'Home'" class="has-text-primary ">Esta {{ type }} já não está disponível!</p>
		<p v-else class="has-text-primary ">Este {{ type }} já não está disponível!</p>
	</div>
</template>

<script>
	export default {
		props: ['type'],
		created() {
			console.log(this.$route.name);
		},
	};
</script>

<style lang="scss" scoped>
	.error-container {
		margin-top: 10%;
	}

	.title {
		margin-top: 80px !important;
	}
</style>
