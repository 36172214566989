<template>
	<section>
		<div class="center-inside">
			<b-field>
				<b-autocomplete
					rounded
					v-model="searchString"
					:data="filteredDataArray"
					placeholder="Pesquisar"
					icon-right="magnify"
					clearable
					@input="getInfo"
					field="name"
					@select="option => (selected = option)"
				>
					<template slot-scope="props">
						<div v-if="props.option.id !== userData.info.id" style="padding: 10px" @click="setCenter(props.option)">
							<div class="user center-inside">
								<img class="profile-photo" v-if="props.option.photo" :src="props.option.photo" />
								<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
							</div>

							<div style="float:left; margin-left:10px">
								<p class="subtitle">{{ props.option.name }}</p>
								<p v-if="props.option.distance > 1" class="distance">
									<b>{{ props.option.distance }}km</b>
								</p>
								<p v-else class="distance">&lt 1 km</p>
							</div>
						</div>
					</template>
					<template #empty>Nenhum utilizador encontrado</template>
				</b-autocomplete>
			</b-field>
		</div>

		<gmap-map v-if="userData.info" ref="map" class="map-size" :center="mapOptions.center" :options="mapOptions.options" :zoom="mapOptions.zoom">
			<!-- Cluster -->
			<gmap-cluster :zoomOnClick="true">
				<gmap-info-window
					:options="mapOptions.infoWindow.options"
					:position="mapOptions.infoWindow.position"
					:opened="mapOptions.infoWindow.isOpen"
					@closeclick="mapOptions.infoWindow.isOpen = false"
				>
					<!-- Providers info window -->
					<MapInfoWindow v-if="selectedUser" :selected-user="selectedUser"> </MapInfoWindow>
				</gmap-info-window>

				<template>
					<gmap-custom-marker
						v-if="userData.info"
						:marker="{
							lat: userData.info.destination_latitude,
							lng: userData.info.destination_longitude,
						}"
					>
						<div class="user-pin center-inside">
							<img v-if="userData.info.photo" class="user-img" :src="userData.info.photo" />
							<div v-else class="user-img center-inside">
								<i class="mdi mdi-camera-outline mdi-24px"></i>
							</div>
						</div>
					</gmap-custom-marker>

					<gmap-custom-marker v-if="markers.length > 0" v-for="(m, index) in markers" :key="index" :marker="m.position" @click.native="toggleInfoWindow(m, index)">
						<div class="user-pin center-inside">
							<img v-if="m.img" class="user-img" :src="m.img" />
							<div v-else class="user-img center-inside">
								<i class="mdi mdi-camera-outline mdi-24px"></i>
							</div>
						</div>
					</gmap-custom-marker>

					<!--              <gmap-marker v-for="(company, index) in companies" v-if="company.coordinates.lat"
                                     :position="company.coordinates" :clickable="true" :draggable="false"
                                     :key="index" @click="toggleInfoWindow(company, index)">
                        </gmap-marker>-->
				</template>
			</gmap-cluster>
		</gmap-map>
		<div class="center-inside center-div">
			<b-field>
				<button class="center-btn"><i @click="autoCenter" class="mdi mdi-crosshairs-gps mdi-24px"></i></button>
			</b-field>
		</div>
	</section>
</template>

<script>
	// Components
	import MapInfoWindow from '@/components/Community/Map/MapInfoWindow';
	import GmapCustomMarker from 'vue2-gmap-custom-marker';

	//MAP Style
	const mapStyle = require('@/components/Community/Map/mapStyle.json');

	//API
	import { getNearbyEmigrants } from '@/api/apiCommunity';
	import { getBySearch } from '@/api/apiCommunity';
	import { followUser } from '@/mixins/follow/followUser';

	export default {
		props: {
			userData: {
				type: Object,
				required: true,
			},
		},
		name: 'Map',
		mixins: [followUser],
		components: {
			MapInfoWindow,
			'gmap-custom-marker': GmapCustomMarker,
		},
		data() {
			return {
				markers: [],
				userMarker: null,
				mapOptions: {
					center: {
						lat: null,
						lng: null,
					},
					options: {
						zoomControl: false,
						mapTypeControl: false,
						scaleControl: false,
						streetViewControl: false,
						rotateControl: false,
						fullscreenControl: false,
						disableDefaultUi: false,

						styles: mapStyle,
					},
					zoom: 6,
					infoWindow: {
						position: null,
						name: null,
						currentMidx: null,
						isOpen: false,
						options: {
							maxWidth: 300,
						},
					},
				},
				selectedUser: null,
				searchString: null,
				isLoading: false,
				data: [],
				selected: null,
				nearbyUsers: null,
			};
		},
		created() {
			getNearbyEmigrants()
				.then(response => {
					console.log(response);
					this.nearbyUsers = response.data;
					console.log(this.nearbyUsers);
					for (let i = 0; i < this.nearbyUsers.length; i++) {
						this.markers.push({
							id: this.nearbyUsers[i].id,
							img: this.nearbyUsers[i].photo,
							name: this.nearbyUsers[i].name,
							follows: this.nearbyUsers[i].follow,
							follow_id: this.nearbyUsers[i].follow_id,
							distance: this.nearbyUsers[i].distance,
							position: {
								lat: this.nearbyUsers[i].destination_latitude,
								lng: this.nearbyUsers[i].destination_longitude,
							},
						});
					}
				})
				.catch(error => {});

			this.mapOptions.center.lat = this.userData.info.destination_latitude;
			this.mapOptions.center.lng = this.userData.info.destination_longitude;
		},

		computed: {
			filteredDataArray() {
				return this.data; /* .filter(option => {
					return (
						option.name
							.toString()
							.toLowerCase()
							.indexOf(this.searchString.toLowerCase()) >= 0
					);
				}); */
			},
		},
		methods: {
			getInfo() {
				if (this.searchString !== '' && this.searchString.length >= 3) {
					let search = {
						text: this.searchString,
					};
					getBySearch(search)
						.then(response => {
							console.log(response);
							/* response.data.sort((a, b) => (a.distance > b.distance ? 1 : -1)); */
							this.data = response.data;
						})
						.catch(err => {});
				}
			},
			setCenter(searchedUser) {
				const index = this.markers.findIndex(e => e.id === searchedUser.id);
				if (index === -1) {
					this.markers.push({
						id: searchedUser.id,
						img: searchedUser.photo,
						name: searchedUser.name,
						follows: searchedUser.follow,
						distance: searchedUser.distance,
						position: {
							lat: searchedUser.destination_latitude,
							lng: searchedUser.destination_longitude,
						},
					});
				}
				this.mapOptions.center.lat = searchedUser.destination_latitude;
				this.mapOptions.center.lng = searchedUser.destination_longitude;
			},

			toggleInfoWindow(m, index) {
				console.log(m.position.lat);
				let lastDigit = Number.isInteger(m.position.lat) ? m.position.lat % 10 : m.position.lat.toString().slice(-1);
				console.log('The last digit of ', m.position.lat, ' is ', lastDigit);

				// Because index is optional
				index = index || 0;
				this.mapOptions.infoWindow.position = m.position;
				this.selectedUser = m;

				// check if its the same marker that was selected if yes toggle
				if (this.mapOptions.infoWindow.currentMidx === index) {
					this.mapOptions.infoWindow.isOpen = !this.mapOptions.infoWindow.isOpen;
				}

				// if different marker set infowindow to open and reset current marker index
				else {
					this.mapOptions.infoWindow.isOpen = true;
					this.mapOptions.infoWindow.currentMidx = index;
				}
			},

			autoCenter() {
				this.$refs.map.$mapPromise.then(map => {
					map.panTo({ lat: this.mapOptions.center.lat, lng: this.mapOptions.center.lng });
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/gmap';

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.user {
		width: 55px;
		height: 55px;
		padding: 0;
		border-radius: 50%;
		background-color: #bac3dc !important;
		float: left;
	}

	.profile-photo {
		border-radius: 50%;
		width: 55px;
		height: 55px;
	}

	.subtitle {
		font-size: 10px;
		font-family: 'Poppins', sans-serif;
		color: #1d4696;
		margin-top: 15px;
		margin-bottom: 5px;
		font-weight: bold;
	}

	.pending-button {
		background-color: #bac3dc;
		color: white;
	}

	.distance {
		margin-bottom: 10px;
		color: #bac3dc;
		font-size: 10px;
	}

	.button {
		font-size: 12px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.following-button {
		background-color: #f6a328;
		color: white;
	}

	.center-btn {
		background-color: white;
		border: none;
		border-radius: 20px;
		height: 40px;
		width: 40px;
		color: $primary;
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.center-div {
		position: absolute;
		bottom: 10%;
		left: 82%;
	}

	@media screen and (min-width: 768px) {
		.center-div {
			left: 92%;
		}
	}
</style>
