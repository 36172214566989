<template>
	<section>
		<div class="goods-services-fields" v-if="goods !== null">
			<div class="columns is-gapless is-multiline">
				<div class="column is-4-desktop is-6-tablet" v-for="(good, index) in goods">
					<div class="card">
						<div class="card-content">
							<div class="media">
								<div class="media-left" @click="goToPage('goods/' + good.id)">
									<div class="user center-inside">
										<img v-if="good.photo" :src="good.photo" />
										<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
									</div>
								</div>
								<div class="media-content" @click="goToPage('goods/' + good.id)">
									<p class="has-text-primary">
										<b>{{ good.title }}</b>
									</p>
									<p class="has-text-dark">{{ good.article_type.name_pt }}</p>
								</div>
								<div class="media-right">
									<div class="media-content">
										<p v-if="good.transaction !== 'exchange' && good.transaction !== 'donate'" class="has-text-primary">
											<b>{{ good.price }} €</b>
										</p>

										<!--                <i class="mdi mdi-delete mdi-24px not-favourite" @click="deleteGood(good.id)"></i>-->
									</div>
								</div>
							</div>
							<div class="media">
								<div class="media-left">
									<div class="media-content">
										<button class="button is-rounded is-primary is-fullwidth" @click="openModal(good)"><b>Editar</b></button>
									</div>
								</div>
								<div class="media-content has-text-centered"></div>
								<div class="media-right">
									<div class="media-content">
										<button class="button is-rounded is-danger is-fullwidth" @click="deleteGood(good.id, index)"><b>Apagar</b></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<EditModal v-if="isModalOpen" :isModalOpen="isModalOpen" :good="selectedGood" @clicked="closeModal"></EditModal>
	</section>
</template>

<script>
	//API
	import { listOwnGoods, deleteGood, deleteService, soldGood } from '@/api/apiGoodsAndServices';
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { loading } from '@/mixins/shared/loading';
	//Components
	import EditModal from '@/components/Goods/MyGoods/EditModal';

	export default {
		mixins: [loading, userInfo, goToPage],
		components: {
			EditModal,
		},
		name: 'ListGoods',
		data() {
			return {
				isButtonLoading: false,
				goods: null,
				selectedGood: null,
				good_id: '',
				good_index: null,
				isModalOpen: false,
			};
		},
		created() {
			this.setPageLoading(true);
			this.getOwnGoods();
		},
		methods: {
			getOwnGoods() {
				listOwnGoods()
					.then(response => {
						console.log(response);
						this.goods = response.data;
						this.setPageLoading(false);
					})
					.catch(error => {
						this.setPageLoading(false);
					});
			},
			openModal(good) {
				this.selectedGood = good;
				this.isModalOpen = true;
			},
			closeModal(value) {
				this.isModalOpen = value;
				this.getOwnGoods();
			},
			deleteGood(id, index) {
				this.good_id = id;
				this.good_index = index;
				let self = this;
				this.$buefy.dialog.confirm({
					title: 'Apagar Anúncio',
					canCancel: ['button'],
					message: '<p style="color: #1d4696">Porque pretende apagar este anúncio?</p>',
					cancelText: 'Foi vendido',
					confirmText: 'Outro motivo',
					type: 'is-primary',
					onCancel() {
						soldGood(self.good_id)
							.then(response => {
								self.goods.splice(self.good_index, 1);
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
					onConfirm() {
						deleteGood(self.good_id)
							.then(response => {
								self.goods.splice(self.good_index, 1);
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '../../../assets/scss/goodsAndServices';

	.goods-services-fields {
		width: 100%;
		height: calc(100vh - 77px);
		position: absolute;
		padding-left: 0;
		padding-right: 0;
		overflow-y: scroll;
	}

	.card {
		width: 93%;
		border-radius: 25px;
		margin: 10px auto;
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.media {
		border: none;
		margin-top: 0;
	}
</style>
