<template>
	<section>
		<div class="local-markets-fields">
			<p v-if="purchaseInfo === null" class="has-text-centered local-market-title">
				<b>Leitura de QR code</b>
			</p>
			<p v-else class="has-text-centered local-market-title">
				<b>Negócio Local</b>
			</p>

			<div class="qr-code-div">
				<qrcode-stream v-if="purchaseInfo === null" style="height: 30%" class="code-reader " @init="onInit" @decode="onDecode"></qrcode-stream>

				<div v-else>
					<div class="media user-content">
						<div class="media-left">
							<div class="user center-inside">
								<img class="user-photo" v-if="purchaseInfo.emigrant.photo" :src="purchaseInfo.emigrant.photo" />
								<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
							</div>
						</div>
						<div class="media-content user-info">
							<p style="color:white">
								<b>{{ purchaseInfo.emigrant.name }}</b>
							</p>
						</div>
						<div class="media-right">
							<div class="media-content"></div>
						</div>
						<div class="media-right user-info">
							<i class="file-icon mdi mdi-account-check-outline mdi-36px"></i>
						</div>
					</div>

					<div class="card">
						<div class="card-content">
							<div class="has-text-primary local-market-discount-text">
								<div class=" media">
									<p class="date">
										{{ this.purchaseInfo.createdAt | moment('D/MM/YYYY') }}
									</p>
									<div class="media-content"></div>

									<div class="media-right">
										<p class="date">{{ this.purchaseInfo.createdAt | moment('HH') }}h{{ this.purchaseInfo.createdAt | moment('mm') }}</p>
									</div>
								</div>

								<hr class="dashed" />
								<table>
									<thead>
										<tr>
											<th>Valor</th>
											<th>Total(€)</th>
											<th>UNI</th>
											<th>Desc.%</th>
											<th>Desc.€</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in purchaseInfo.benefits" :key="index">
											<td>
												<p class="" v-if="index === 0">{{ amount }}€</p>

												<p class="" v-else>{{ subTotal(index) }}€</p>
											</td>
											<td>
												<span v-if="item.purchases_benefits.quantity && item.type == 'percentage_product'">{{ item.purchases_benefits.quantity }}</span>
												<span v-else>-</span>
											</td>
											<td>
												<span v-if="item.purchases_benefits.quantity && item.type == 'fixed_price_product'">{{ item.purchases_benefits.quantity }}</span>
												<span v-else>-</span>
											</td>
											<td>
												<span v-if="item.type == 'percentage_purchase'">{{ item.value }}</span>

												<span v-else-if="item.type == 'percentage_product'">{{ item.value }}</span>
												<span v-else>-</span>
											</td>
											<td>
												<span v-if="item.type == 'fixed_price_product'">{{ item.value }}</span>
												<span v-else-if="item.type == 'fixed_price_purchase'">{{ item.value }}</span>

												<span v-else>-</span>
											</td>
											<td>
												<span>{{ item.subtotal }}€</span>
											</td>
										</tr>
									</tbody>
								</table>
								<hr class="dashed" />

								<div class=" media">
									<p>Valor sem desconto</p>

									<div class="media-content"></div>

									<div class="media-right">
										<p>{{ amount }}€</p>
									</div>
								</div>
								<div class=" media">
									<p>Desconto</p>

									<div class="media-content"></div>

									<div class="media-right">
										<p>{{ discount.toFixed(2) }}€</p>
									</div>
								</div>

								<hr class="dashed" />

								<div class=" media">
									<p class="date">Total</p>

									<div class="media-content"></div>

									<div class="media-right">
										<p class="date">{{ total }}€</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<p v-if="purchaseInfo === null" class="has-text-centered local-market-discount-text">
				<b>Aproxime do QR code do utilizador e.migra para validar o desconto aplicável</b>
			</p>

			<div v-if="purchaseInfo !== null" class="next-div has-text-centered">
				<button @click="validate(purchaseInfo.id, 'accept')" class="button is-rounded is-secondary is-fullwidth">
					Validar
				</button>
				<button @click="validate(purchaseInfo.id, 'decline')" class="button is-rounded is-danger is-fullwidth">
					Cancelar
				</button>
				<br />
				<br />
			</div>
		</div>
	</section>
</template>

<script>
	import { getPurchaseById, purchase, validatePurchase } from '@/api/apiLocalMarket';
	import { localMarketSteps } from '@/mixins/localMarkets/localMarketSteps';
	import { loading } from '@/mixins/shared/loading';

	export default {
		mixins: [loading, localMarketSteps],
		name: 'ValidateDiscount',
		data() {
			return {
				purchaseInfo: null,
				discountTotal: 0,
				amount: 0,
			};
		},

		computed: {
			discount: function() {
				console.log(this.purchaseInfo.benefits.length);
				let lastIndex = this.purchaseInfo.benefits.length - 1;
				return this.amount - this.purchaseInfo.benefits[lastIndex].subtotal;
			},

			total: function() {
				console.log(this.purchaseInfo.benefits.length);
				let lastIndex = this.purchaseInfo.benefits.length - 1;
				return this.purchaseInfo.benefits[lastIndex].subtotal;
			},
		},

		watch: {
			purchaseInfo: function() {
				this.orderArray();

				this.purchaseInfo.benefits[0].subtotal = this.purchaseInfo.amount;
				let discount = 0;
				let currentAmount = this.purchaseInfo.amount;

				for (let index = 0; index < this.purchaseInfo.benefits.length; index++) {
					if (this.purchaseInfo.benefits[index].type === 'percentage_purchase') {
						discount = currentAmount * (0.01 * this.purchaseInfo.benefits[index].value);
						currentAmount = currentAmount - discount;
						this.purchaseInfo.benefits[index].subtotal = currentAmount.toFixed(2);
						this.discountTotal = discount;

						console.log(this.purchaseInfo.benefits[index].subtotal);
					} else if (this.purchaseInfo.benefits[index].type === 'fixed_price_product') {
						discount = this.purchaseInfo.benefits[index].purchases_benefits.quantity * this.purchaseInfo.benefits[index].value;

						currentAmount = currentAmount - discount;
						console.log(currentAmount);

						this.purchaseInfo.benefits[index].subtotal = currentAmount.toFixed(2);
						this.discountTotal = discount;

						console.log(this.purchaseInfo.benefits[index].subtotal);
					} else if (this.purchaseInfo.benefits[index].type === 'fixed_price_purchase') {
						discount = this.purchaseInfo.benefits[index].value;
						currentAmount = currentAmount - discount;
						this.purchaseInfo.benefits[index].subtotal = currentAmount.toFixed(2);
						this.discountTotal = discount;

						console.log(this.purchaseInfo.benefits[index].subtotal);
					} else if (this.purchaseInfo.benefits[index].type === 'percentage_product') {
						discount =
							this.purchaseInfo.benefits[index].purchases_benefits.quantity -
							this.purchaseInfo.benefits[index].purchases_benefits.quantity * (0.01 * this.purchaseInfo.benefits[index].value);
						currentAmount = currentAmount - discount;
						this.purchaseInfo.benefits[index].subtotal = currentAmount.toFixed(2);
						this.discountTotal = discount;
					}
				}
			},
		},

		methods: {
			async onInit(promise) {
				// show loading indicator
				this.setPageLoading(true);
				try {
					const { capabilities } = await promise;
					// successfully initialized
				} catch (error) {
					if (error.name === 'NotAllowedError') {
						// user denied camera access permisson
						this.errorMessage = 'Não permitiste que o Browser usasse a câmara do teu dispositivo, digita o código ! ';
					} else if (error.name === 'NotFoundError') {
						this.errorMessage = 'Não encontramos nenhuma câmara no teu dispositivo, digita o código ! ';
					} else if (error.name === 'NotSupportedError') {
						// page is not served over HTTPS (or localhost)
					} else if (error.name === 'NotReadableError') {
						this.errorMessage = 'Talvez a tua câmara já esteja em uso, digita o código ! ';
					} else if (error.name === 'OverconstrainedError') {
						// did you requested the front camera although there is none?
					} else if (error.name === 'StreamApiNotSupportedError') {
						this.errorMessage = 'O teu browser não suporta o uso de câmara, digita o código ! ';
					}
				} finally {
					this.setPageLoading(false);
				}
			},
			onDecode(decodedString) {
				getPurchaseById(decodedString)
					.then(response => {
						this.purchaseInfo = response.data;
						console.log(this.purchaseInfo);
					})
					.catch(error => {
						this.setMarketInfo(0, null);
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
			validate(id, answer) {
				validatePurchase(id, answer)
					.then(response => {
						this.setMarketInfo(0, null);
						if (answer === 'accept') {
							this.$buefy.toast.open({
								message: 'Compra validada com sucesso',
								position: 'is-top',
								type: 'is-primary',
							});
						} else {
							this.$buefy.toast.open({
								message: 'Compra cancelada com sucesso',
								position: 'is-top',
								type: 'is-primary',
							});
						}
					})
					.catch(error => {
						this.setMarketInfo(0, null);
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
			orderArray() {
				console.log(this.purchaseInfo);
				this.amount = this.purchaseInfo.amount;
				for (let index = 0; index < this.purchaseInfo.benefits.length; index++) {
					this.purchaseInfo.benefits.sort(function(a, b) {
						if (a.purchases_benefits.order > b.purchases_benefits.order) {
							return 1;
						}
						if (a.purchases_benefits.order < b.purchases_benefits.order) {
							return -1;
						}
						// a must be equal to b
						return 0;
					});
				}
			},

			subTotal: function(index) {
				return this.purchaseInfo.benefits[index - 1].subtotal;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';
	@import '../../../assets/scss/localMarket';
	@import '../../../assets/scss/validateDiscount';

	table {
		width: 100%;
		border-collapse: collapse;
	}
	th {
		font-size: 12px;
		color: $primary;
		font-weight: bold;
	}
	tr {
		text-align: center;
		border: none;
	}
	/* td{
    border-right: solid 1px $primary; 
  border-left: solid 1px $primary;
} */
	td:first-child,
	td:last-child {
		border: none;
	}

	td,
	.card p {
		font-size: 12px;
	}

	.user-photo {
		width: 55px;
		height: 55px;
		border-radius: 50%;
		background-color: #bac3dc !important;
		margin-left: auto;
		margin-right: auto;
		object-fit: cover;
	}

	.card-content {
		padding: 1.5rem;
	}

	.user-info {
		height: 55px;
		align-items: center;
		display: flex;
	}

	.user-content {
		padding-left: 6%;
		padding-right: 6%;
		margin-bottom: 30px;
		margin-top: 30px;
	}

	.local-market-title {
		font-size: 1.3rem;
	}

	@media screen and (max-width: 320px) {
		.local-market-title {
			font-size: 1rem;
		}
	}
</style>
