// API
import { tokenAuth } from '@/api/apiAuth';

// Mixins
import { tokenVerification } from './tokenVerification';

let firebaseApp = null;

export const auth = {
	computed: {
		getFirebaseApp() {
			return firebaseApp;
		},
	},
	mixins: [tokenVerification],
	methods: {
		authWithToken() {
			tokenAuth()
				.then(response => {
					if (response.status === 200) {
						console.log(response);
						this.$store.dispatch('auth/authenticateUser', response, { root: true });
					}
					this.setHasVerifiedToken(true);
				})
				.catch(error => {
					console.log(error);
					console.log('Teste');
					this.$store.commit('auth/removeToken');
					this.$router.push('/');
					this.setHasVerifiedToken(true);
				});
		},
		setFirebaseApp(app) {
			firebaseApp = app;
		},
	},
};
