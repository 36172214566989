<template>
	<section>
		<div class="auth-div">
			<div class=" center-inside">
				<img width="140" src="../../../assets/logos/logo.png" />
			</div>
			<div class="has-text-centered">
				<p class="title has-text-primary">
					<b
						>Bem vindo<br />
						à app e.migra!</b
					>
				</p>
				<p class="small-text" style="justify-content: center">
					O teu registo foi concluído!<br />
					Irás receber um email para confirmar o teu registo
				</p>
			</div>
			<div class="auth-buttons">
				<button class="button register is-rounded is-secondary is-fullwidth" @click="goToPage('/login')">
					<i class="required-icon mdi mdi-check mdi-24px "></i>
				</button>
				<div class="register-steps center-inside is-fullwidth">
					<div></div>
					<div></div>
					<div></div>
					<div class="step-3"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { goToPage } from '@/mixins/shared/goToPage';
	import { steps } from '@/mixins/shared/steps';

	export default {
		mixins: [goToPage, steps],
		name: 'Step4',
	};
</script>
<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		position: relative;
		padding-top: 30px;
		
		@media screen and (min-width: 768px) {
			padding-top: 10%;
		}
	}

	.auth-buttons {
		padding-top: 50px;
	}

	.register {
		margin-bottom: 50px;
	}

	.required-icon {
		color: white !important;
	}

	.register-steps > .step-3 {
		background: #1d4696;
	}
</style>
