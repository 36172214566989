<template>
	<section>
		<div class="auth-div">
			<div>
				<!--  <i
          @click="setActiveStep(1, null)"
          style="display:inline"
          class="mdi mdi-chevron-left mdi-48px"
        ></i>
 -->
				<p class="title has-text-primary bef" style="display:inline;">
					<b>Registo</b>
				</p>
			</div>
			<div class="field email-field">
				<label class="label">Nome</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('name') }" type="text" v-validate="'required'" name="name" v-model="register_name" placeholder="António Silva" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('name') && register_name != ''" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('name')" class="help is-danger">
					Insere o teu nome
				</p>
			</div>
			<div class="field email-field">
				<label class="label">Género</label>
				<div class="control">
					<label class="radio">
						<input type="radio" value="male" v-model="register_gender" name="answer" />
						Masculino
					</label>
					<label class="radio">
						<input type="radio" value="female" v-model="register_gender" name="answer" />
						Feminino
					</label>
					<br />
					<br />
					<label class="radio">
						<input type="radio" value="other" v-model="register_gender" name="answer" />
						Prefiro não responder
					</label>
				</div>
				<hr />
			</div>
			<!--      <div class="field email-field">
              <label class="label">Utilizador</label>
              <div class="control has-icons-right">
                <input class="input is-success" type="text" v-validate="'required'" name="username"
                       v-model="register_username">
                <span class="icon is-small is-right">
          <i v-if="!errors.has('username')&&register_username!=''" class="required-icon mdi mdi-check mdi-24px"></i>
          </span>
              </div>
            </div>-->
			<div class="field email-field">
				<label class="label">Email</label>
				<div class="control has-icons-right">
					<input
						class="input is-success"
						:class="{ 'error-text': errors.has('email') }"
						type="text"
						v-validate="'required|email'"
						name="email"
						v-model="register_email"
					/>
					<span class="icon is-small is-right">
						<i v-show="!errors.has('email') && register_email != ''" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('email')" class="help is-danger">
					Formato de email inválido
				</p>
			</div>

			<div class="field email-field">
				<label class="label">Password</label>
				<div class="control">
					<b-input
						type="password"
						name="password"
						ref="password"
						:class="{ 'error-text': errors.has('password') }"
						v-validate="'required|min:8'"
						v-model="register_password"
						password-reveal
					>
					</b-input>
				</div>
				<p v-show="errors.has('password')" class="help is-danger">
					A password é demasiado pequena
				</p>
				<br />
				<p v-if="passwordError" class="help is-danger">
					A password deve conter números e letras
				</p>
			</div>
			<div class="field email-field">
				<label class="label">Confirmar Password</label>
				<div class="control">
					<b-input
						type="password"
						v-validate="{ required: true, is: register_password, min: 8 }"
						:class="{ 'error-text': errors.has('password_confirmation') }"
						name="password_confirmation"
						v-model="register_confirm_password"
						password-reveal
					>
					</b-input>
				</div>
				<p v-show="errors.has('password_confirmation')" class="help is-danger">
					As passwords não coincidem
				</p>
			</div>

			<div class="next-div has-text-centered">
				<button class="button is-rounded is-secondary" :class="{ 'is-loading': isLoading }" @click="backToZip">
					<i style="transform: translate(-26px, -17px);width:40px;color: white;" class="mdi mdi-arrow-left-thick mdi-48px"></i>
				</button>
				<button class="button is-rounded is-primary button-size " :class="{ 'is-loading': isLoading }" @click="register">
					Seguinte
				</button>

				<div class="register-steps center-inside is-fullwidth">
					<div @click="backToZip"></div>
					<div class="step-2"></div>
					<div></div>
					<div></div>
				</div>
				<p class="small-text">
					Já tens conta?
					<span class="link-text is-click" @click="goToPage('/login')">Efetua Login agora!</span>
				</p>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { steps } from '@/mixins/shared/steps';
	import { goToPage } from '@/mixins/shared/goToPage';

	export default {
		mixins: [steps, goToPage],
		name: 'Step2',
		data() {
			return {
				register_name: '',
				register_gender: '',
				register_username: '',
				register_email: '',
				register_password: '',
				register_confirm_password: '',
				isLoading: false,
				previouslySelected: '',
				passwordError: false,
			};
		},
		created() {
			console.log(this.getStepData.data);
			if (this.getStepData.data) {
				if (this.getStepData.data.name) {
					this.register_name = this.getStepData.data.name;
				}
				if (this.getStepData.data.gender) {
					this.register_gender = this.getStepData.data.gender;
				}

				if (this.getStepData.data.email) {
					this.register_email = this.getStepData.data.email;
				}

				if (this.getStepData.data.password) {
					this.register_password = this.getStepData.data.password;
					this.register_confirm_password = this.getStepData.data.password;
				}
			}
		},
		methods: {
			backToZip() {
				if (this.getStepData) {
					this.setActiveStep(1, this.getStepData);
				} else setActiveStep(1, null);
			},

			register() {
				this.isLoading = true;
				if (this.register_password.search(/\d/) == -1) {
					this.passwordError = true;
				} else if (this.register_password.search(/[a-zA-Z]/) == -1) {
					this.passwordError = true;
				} else this.passwordError = false;
				this.$validator.validateAll().then(result => {
					if (result && !this.passwordError) {
						this.passwordError = false;
						let destination;
						let destinationZip;
						let originZip;
						if (this.getStepData.type === 'error') {
							destination = this.getStepData.data.destination_country;
							destinationZip = this.getStepData.data.destination_zip_code;
							originZip = this.getStepData.data.origin_zip_code;
						} else {
							destination = this.getStepData.host_country.ISO;
							destinationZip = this.getStepData.zip_host;
							originZip = this.getStepData.zip_origin;
						}

						let data = {
							register_type: 'basic',
							origin_zip_code: originZip,
							destination_zip_code: destinationZip,
							destination_country: destination,
							name: this.register_name,
							email: this.register_email,
							password: this.register_password,
							...(this.register_gender && { gender: this.register_gender }),
						};

						this.setActiveStep(3, data);
					} else {
						this.isLoading = false;
					}
				});
			},
			unselect(val) {
				if (val === this.register_gender) {
					this.register_gender = false;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		top: 5%;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 30px;
	}

	.register-steps > .step-2 {
		background: #1d4696;
	}

	hr {
		margin-bottom: 0;
	}
	i::before {
		transform: translate(-23px, 8px);
	}

	.bef ::before {
		transform: translateX(-15px);
	}

	i::before {
		transform: translate(20px, 17px);
	}
	.button-size {
		width: 180px;
		margin-left: 10px;
	}

	.title {
		font-size: 1.5rem;
	}

	.label,
	input {
		font-size: 0.9rem;
	}

	.radio {
		font-size: 0.75rem;
	}

	@media screen and (max-width: 360px) {
		.button-size {
			width: 140px;
			margin-left: 10px;
		}
	}
</style>
