<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Completar Registo</b></p>

			<div class="field email-field">
				<label class="label">Foto de Perfil</label>
				<b-field class="file is-primary center-inside">
					<b-upload v-model="file" class="file-label" accept="image/*">
						<div class="file-cta center-inside">
							<img class="profile-photo" v-if="register_photo && !file" :src="register_photo" />
							<img class="profile-photo" v-else-if="file" :src="getFileURL" />
							<i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
						</div>
					</b-upload>
				</b-field>
			</div>
			<div class="field email-field">
				<label class="label">Nome</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('name') }" type="text" v-validate="'required'" name="name" v-model="register_name" placeholder="António Silva" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('name') && register_name != null" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('name')" class="help is-danger">
					Insira o seu nome
				</p>
			</div>

			<div class="field email-field">
				<label class="label">Email</label>
				<div class="control has-icons-right">
					<input
						class="input is-success"
						:class="{ 'error-text': errors.has('email') }"
						type="text"
						v-validate="'required|email'"
						name="email"
						v-model="register_email"
						:disabled="userData.email"
					/>
					<span class="icon is-small is-right">
						<i v-show="!errors.has('email') && register_email != null" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('email')" class="help is-danger">
					Formato de email inválido
				</p>
			</div>

			<div class="field email-field">
				<label class="label">Género</label>
				<div class="control">
					<label class="radio">
						<input type="radio" value="male" v-model="register_gender" name="answer" />
						Masculino
					</label>
					<label class="radio">
						<input type="radio" value="female" v-model="register_gender" name="answer" />
						Feminino
					</label>
					<br />
					<br />
					<label class="radio">
						<input type="radio" value="other" v-model="register_gender" name="answer" />
						Prefiro não responder
					</label>
				</div>
				<hr />
			</div>
			<div class="next-div has-text-centered">
				<button class="button is-rounded is-secondary" @click="setActiveStep(0, userData)">
					<i style="transform: translate(-26px, -17px);width:40px;color: white;" class="mdi mdi-arrow-left-thick mdi-48px"></i>
				</button>
				<button class="button is-rounded is-primary button-size" @click="register">
					Seguinte
				</button>

				<!--  <button class="button is-rounded is-primary is-fullwidth" @click="register">
          Seguinte
        </button> -->
				<div class="register-steps center-inside is-fullwidth">
					<div @click="setActiveStep(0, userData)"></div>
					<div class="step-2"></div>
					<div @click="register"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { steps } from '@/mixins/shared/steps';

	export default {
		mixins: [steps],
		props: {
			userData: {
				type: Object,
				required: true,
			},
		},
		name: 'Step2',
		data() {
			return {
				register_name: null,
				register_gender: null,
				register_email: null,
				register_photo: null,
				file: null,
			};
		},
		created() {
			console.log(this.userData);
			console.log(this.getStepData);

			if (this.userData.info.name != '' || this.userData.info.email != '' || this.userData.info.gender != '' || this.userData.info.photo != null) {
				this.register_name = this.userData.info.name;
				this.register_gender = this.userData.info.gender;
				this.register_email = this.userData.email;
				this.register_photo = this.userData.info.photo;
			}
		},
		computed: {
			getFileURL() {
				if (this.file) {
					return window.URL.createObjectURL(this.file);
				}
			},
		},
		methods: {
			register() {
				console.log(this.file);
				this.$validator.validateAll().then(result => {
					if (result) {
						let data = {
							origin_county: this.getStepData.origin_county,
							origin_parish: this.getStepData.origin_parish,
							origin_street: this.getStepData.origin_street,
							origin_zip_code: this.getStepData.origin_zip_code,
							destination_zip_code: this.getStepData.destination_zip_code,
							destination_country: this.getStepData.destination_country,
							destination_county: this.getStepData.destination_county,
							destination_city: this.getStepData.destination_city,
							destination_street: this.getStepData.destination_street,
							name: this.register_name,
							email: this.register_email,
							birth_date: null,
							phone: null,
							...(this.file != null && { photo: this.file }),
							...(this.register_gender  && { gender: this.register_gender }),
						};
						console.log(data);
						this.setActiveStep(2, data);
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		top: 6%;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 30px;
	}

	.register-steps > .step-2 {
		background: #1d4696;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.file-cta {
		width: 100px;
		height: 100px;
		padding: 0;
		border-radius: 50%;
		background-color: #bac3dc !important;
	}

	.profile-photo {
		border-radius: 50%;
		width: 100px;
		height: 100px;
	}

	hr {
		margin-bottom: 0;
	}

	.mdi-arrow-left-thick::before {
		transform: translate(20px, 17px);
	}

	.button-size {
		width: 180px;
		margin-left: 10px;
	}

	.title {
		font-size: 1.5rem;
	}

	.label,
	input {
		font-size: 0.9rem;
	}

	.small-text,
	.link-text,
	.radio {
		font-size: 0.75rem;
	}

	@media screen and (max-width: 360px) {
		.button-size {
			width: 140px;
			margin-left: 10px;
		}
	}
</style>
