<template>
	<section>
		<div class="hitchhike-fields">
			<p style="font-size: 24px" class="has-text-primary has-text-centered">
				<b>{{ this.getHitchhikeData.departure_country }}-{{ this.getHitchhikeData.arrival_country }}</b>
			</p>
			<div class="card" v-for="(hitchhike, index) in getHitchhikeData.response" v-if="!hitchhike.trash">
				<div class="card-content">
					<div class="media">
						<div @click="goToPage('/emigrant/' + hitchhike.vehicle.emigrant_id)" class="media-left">
							<div class="user center-inside">
								<img class="profile-photo" v-if="hitchhike.vehicle.emigrant.photo" :src="hitchhike.vehicle.emigrant.photo" />
								<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
							</div>
						</div>
						<div class="media-content">
							<p class="has-text-primary">
								<b>{{ hitchhike.vehicle.emigrant.name }}</b>
							</p>
							<p class="has-text-dark">{{ hitchhike.vehicle.car_model.car_brand.name }} | {{ hitchhike.vehicle.year }}</p>
						</div>
						<div class="media-right">
							<div class="media-content">
								<p class="has-text-secondary">
									<b>{{ hitchhike.distance_text }}</b>
								</p>
								<p class="has-text-primary">
									<b v-if="hitchhike.available_places - hitchhike.occupied_places == 1">{{ hitchhike.available_places - hitchhike.occupied_places }} Lugar</b>
									<b v-else>{{ hitchhike.available_places - hitchhike.occupied_places }} Lugares</b>
								</p>
							</div>
						</div>

						<div class="media-right" v-if="hitchhike.hitchhikes.length <= 0">
							<div class="media-content is-click" @click="removeFavourite(hitchhike.wish_car_trips[0].id, index)" v-if="hitchhike.wish_car_trips.length > 0">
								<i class="mdi mdi-heart mdi-24px not-favourite"></i>
							</div>
							<div class="media-content is-click" v-else @click="addToFavourites(hitchhike.id, index)">
								<i class="mdi mdi-heart-outline mdi-24px not-favourite"></i>
							</div>
						</div>
					</div>
					<div v-if="!hitchhike.is_emigrant_origin_to_destination">
						<b class="has-text-primary">De:</b><span class="has-text-primary"> {{ hitchhike.vehicle.emigrant.destination_formatted_address }}</span
						><br />
						<b class="has-text-primary">Para:</b>
						<span class="has-text-primary"> {{ hitchhike.vehicle.emigrant.origin_formatted_address }}</span>
					</div>
					<div v-else>
						<b class="has-text-primary">De:</b> <span class="has-text-primary"> {{ hitchhike.vehicle.emigrant.origin_formatted_address }}</span>
						<br />
						<b class="has-text-primary">Para:</b><span class="has-text-primary"> {{ hitchhike.vehicle.emigrant.destination_formatted_address }}</span>
					</div>

					<div class="media mt-3">
						<div class="media-left">
							<div class="media-content">
								<p class="has-text-primary">
									<b>{{ hitchhike.departure_date }}</b>
								</p>
							</div>
						</div>
						<div class="media-content has-text-centered">
							<p class="has-text-primary">
								<b>{{ hitchhike.departure_hour }}h{{ hitchhike.minutes }}</b>
							</p>
						</div>
						<div class="media-right">
							<div class="media-content" v-if="hitchhike.hitchhike_status === 'none'">
								<button
									class="button is-rounded is-primary is-fullwidth"
									:class="{ 'is-loading': isButtonLoading && hitchhike.id === selectedCartripId }"
									@click="createHitchhike(hitchhike.id)"
								>
									<b>Requisitar</b>
								</button>
							</div>
							<div class="media-content" v-else-if="hitchhike.hitchhike_status === 'accepted'">
								<button class="button is-rounded is-primary is-fullwidth " disabled><b>Aceite</b></button>
								<!-- 	<button
									class="button is-rounded is-primary is-fullwidth"
									:class="{ 'is-loading': isButtonLoading && hitchhike.id === selectedCartripId }"
									@click="createHitchhike(hitchhike.id)"
								>
									<b>Requisitar</b>
								</button> -->
							</div>
							<div class="media-content" v-else>
								<button class="button is-rounded is-primary is-fullwidth " disabled><b>Requisitado</b></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//API
	import { requestHitchhike, deleteWishCartrip, createWishCartrip } from '@/api/apiRides';
	//Mixins
	import { hitchhikeSteps } from '@/mixins/hitchhikes/hitchhikeSteps';
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';

	export default {
		mixins: [hitchhikeSteps, userInfo, goToPage],
		name: 'HitchhikeResults',
		data() {
			return {
				isButtonLoading: false,
				selectedCartripId: null,
			};
		},
		created() {},
		methods: {
			addToFavourites(id, index) {
				let data = {
					car_trip_id: id,
					departure_country: this.getHitchhikeData.departure_country,
					departure_zip_code: this.getHitchhikeData.departure_zip_code,
					arrival_zip_code: this.getHitchhikeData.arrival_zip_code,
					arrival_country: this.getHitchhikeData.arrival_country,
					hitchhike_places: this.getHitchhikeData.hitchhike_places,
				};
				createWishCartrip(data)
					.then(response => {
						let data = this.getHitchhikeData;
						data.response[index].wish_car_trips.push(response.data.body);
						this.setHitchhike(4, data);
					})
					.catch(error => {});
			},
			removeFavourite(id, index) {
				deleteWishCartrip(id)
					.then(response => {
						let data = this.getHitchhikeData;
						data.response[index].wish_car_trips.pop();
						this.setHitchhike(4, data);
					})
					.catch(error => {});
			},

			createHitchhike(id) {
				this.isButtonLoading = true;
				this.selectedCartripId = id;
				let data = {
					hitchhike_places: this.getHitchhikeData.hitchhike_places,
					car_trip_id: id,
					departure_country: this.getHitchhikeData.departure_country,
					departure_zip_code: this.getHitchhikeData.departure_zip_code,
					arrival_zip_code: this.getHitchhikeData.arrival_zip_code,
					arrival_country: this.getHitchhikeData.arrival_country,
				};
				requestHitchhike(data)
					.then(response => {
						console.log(response);
						this.isButtonLoading = false;
						this.$buefy.toast.open({
							message: 'Boleia requisitada com sucesso',
							position: 'is-top',
							type: 'is-primary',
						});
						this.setHitchhike(0, null);
					})
					.catch(error => {
						this.isButtonLoading = false;
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
						this.setHitchhike(0, null);
					});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '../../../assets/scss/hitchhike';

	.hitchhike-fields {
		width: 100%;
		height: calc(100vh - 77px);
		position: absolute;
		padding-left: 0;
		padding-right: 0;
		overflow-y: scroll;
	}

	.card {
		width: 93%;
		border-radius: 25px;
		margin: 10px auto;
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.media {
		border: none;
		margin-top: 0;
	}

	p {
		font-size: 0.9rem;
	}

	b,
	span {
		font-size: 0.8rem;
	}
</style>
