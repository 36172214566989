<template style="overflow: hidden">
  <div v-if="visible" class="sideBar">
    <div class="sidebar-icons" @click="onClickButton(business)">
      <i class="icon mdi mdi-domain mdi-48px"></i>
    </div>
    <div class="sidebar-icons" @click="onClickButton(users)">
      <i class="icon mdi mdi-account mdi-48px"></i>
    </div>
    <div class="sidebar-icons" @click="onClickButton(tickets)">
      <i class="icon mdi mdi-chat-question-outline mdi-48px"></i>
    </div>
    <div class="sidebar-icons" @click="onClickButton(control)">
      <i class="icon mdi mdi-shield-account mdi-48px"></i>
    </div>
    <div class="sidebar-icons" @click="isLoggoutModal = true">
      <i class="icon mdi mdi-logout mdi-48px"></i>
    </div>

    <b-modal :can-cancel="false" v-model="isLoggoutModal" scroll="keep">
      <div class="modal is-active">
        <div class="modal-card">
          <section class="modal-card-body">
            <p class="title has-text-primary"><b>Tem a certeza que pretende sair?</b></p>
            <button @click="execLogout()" class="button is-rounded is-primary is-fullwidth">
              Confirmar
            </button>
            <br />
            <button @click="isLoggoutModal = false" class="button is-rounded is-danger is-fullwidth">
              Agora não
            </button>
          </section>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//Mixins
import { logout } from "@/mixins/auth/logout";

export default {
  mixins: [logout],
  name: "SideBar",

  data() {
    return {
      path: this.$route.path,
      visible: true,
      mobile: false,
      isSelected: false,
      workspaces: [],
      groups: [],
      business: "business",
      users: "users",
      tickets: "tickets",
      control:"control",
      isLoggoutModal: false
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.unwatch = this.$store.watch(
      state => {
        return this.$store.getters["backofficeSidebar/getState"];
      },
      (newState, oldState) => {
        this.visible = newState;
        this.expanded = newState;
      }
    );
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.unwatch = null;
  },

  methods: {
    handleResize() {
      this.width = window.innerWidth;
      if (this.width <= 768) {
        this.visible = false;
        this.mobile = true;
      } else {
        this.visible = true;
        this.mobile = false;
      }
    },
    onClickButton(event) {
      this.$emit("clicked", event);
      this.$store.commit("backofficeSidebar/setState", false);
    },
    async execLogout() {
      await this.logout();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/backofficeSidebar";

.modal-card {
  border-radius: 20px;
}

p {
  font-size: 1.5rem;
}
</style>
