<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Completar Registo</b></p>

			<div class="field email-field">
				<label class="label">Telemóvel</label>
				<div class="control has-icons-right">
					<input
						class="input is-success"
						:class="{ 'error-text': errors.has('email') }"
						type="text"
						name="register_phone"
						v-validate="'numeric'"
						v-model="register_phone"
					/>
				</div>
				<p v-show="errors.has('register_phone')" class="help is-danger">
					Número de telefone inválido
				</p>
			</div>

			<div class="field email-field">
				<label class="label">Data de Nascimento</label>
				<b-datepicker v-model="register_birth_date" name="birthday" expanded :max-date="new Date()" :mobile-native="false" inline placeholder="Select a date">
				</b-datepicker>
			</div>
			<p v-if="!hasAge" class="help is-danger">
				Para utilizar a app e.migra tem que ter mais de 16 anos
			</p>
			<br />
			<div style="margin-top:12px">
				<label class="radio">
					<input type="checkbox" v-validate="'required'" value="true" v-model="radioLocation" name="answer" />
					Aceito partilhar a minha localização
				</label>
				<br />
				<p v-show="errorLocation" class="help is-danger mt-4">
					Para utilizar a app e.migra tem de aceitar a partilha de localização
				</p>
			</div>

			<div class="next-div has-text-centered">
				<button class="button is-rounded is-secondary" @click="goBack">
					<i style="transform: translate(-26px, -17px);width:40px;color: white;" class="mdi mdi-arrow-left-thick mdi-48px"></i>
				</button>
				<button class="button is-rounded is-primary button-size " @click="register">
					Registar
				</button>

				<div class="register-steps center-inside is-fullwidth">
					<div @click="setActiveStep(0, userData)"></div>
					<div @click="setActiveStep(1, userData)"></div>
					<div class="step-3"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { steps } from '@/mixins/shared/steps';
	import { userInfo } from '@/mixins/shared/userInfo';
	//API
	import { completeRegister } from '@/api/apiRegister';

	export default {
		mixins: [steps],
		props: {
			userData: {
				type: Object,
				required: true,
			},
		},
		name: 'Step3',
		data() {
			return {
				register_birth_date: new Date(),
				register_phone: null,
				hasAge: true,
				isLoading: false,
				radioLocation: null,
				errorLocation: false,
			};
		},
		created() {
			console.log(this.getStepData);
		},
		computed: {},
		methods: {
			formatDate(date) {
				let d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();

				if (month.length < 2) month = '0' + month;
				if (day.length < 2) day = '0' + day;

				return [year, month, day].join('-');
			},
			calculate_age(dob) {
				let diff_ms = Date.now() - dob.getTime();
				let age_dt = new Date(diff_ms);
				return Math.abs(age_dt.getUTCFullYear() - 1970);
			},

			register() {
				this.isLoading = true;
				if (this.radioLocation == null || this.radioLocation == false) {
					this.errorLocation = true;
				} else this.errorLocation = false;
				if (this.calculate_age(this.register_birth_date) >= 16 && !this.errorLocation) {
					let data = {
						origin_country: this.getStepData.origin_county,
						origin_parish: this.getStepData.origin_parish,
						origin_street: this.getStepData.origin_street,
						origin_zip_code: this.getStepData.origin_zip_code,
						destination_zip_code: this.getStepData.destination_zip_code,
						destination_country: this.getStepData.destination_country,
						/* destination_county: this.getStepData.destination_county, */
						destination_city: this.getStepData.destination_city,
						destination_street: this.getStepData.destination_street,
						name: this.getStepData.name,
						email: this.getStepData.email,
						...(this.register_phone != null && { phone: this.register_phone }),
						birth_date: this.formatDate(this.register_birth_date),
						...(this.getStepData.photo && { photo: this.getStepData.photo }),
						...(this.getStepData.gender && { gender: this.getStepData.gender }),
					};
					console.log(data);

					completeRegister(data)
						.then(response => {
							console.log(response);
							this.isLoading = false;
							location.reload();
						})
						.catch(error => {
							this.isLoading = false;

							this.$buefy.toast.open({
								message: error.response.data.message.pt,
								position: 'is-top',
								type: 'is-danger',
							});
						});
				} else {
					this.isLoading = false;
					this.hasAge = false;
				}
			},
			goBack() {
				this.setActiveStep(1, this.getStepData);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		top: 6%;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.button {
		margin-top: 45px;
		margin-bottom: 5px;
	}

	.register-steps > .step-3 {
		background: #1d4696;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.file-cta {
		width: 100px;
		height: 100px;
		padding: 0;
		border-radius: 50%;
		background-color: #bac3dc !important;
	}

	.profile-photo {
		border-radius: 50%;
		width: 100px;
		height: 100px;
	}
	.mdi-arrow-left-thick::before {
		transform: translate(20px, 17px);
	}
	.button-size {
		width: 180px;
		margin-left: 10px;
	}

	.title {
		font-size: 1.5rem;
	}

	.label,
	input {
		font-size: 0.9rem;
	}

	.small-text,
	.link-text,
	.radio {
		font-size: 0.75rem;
	}

	input[type='checkbox'] {
		transform: translateY(4px);
	}

	.help {
		position: relative !important;
	}

	@media screen and (max-width: 360px) {
		.button-size {
			width: 140px;
			margin-left: 10px;
		}
	}

	@media screen and (max-width: 321px) {
		.auth-div {
			padding-left: 4%;
			padding-right: 4%;
		}
	}
</style>
