<template>
	<section>
		<div class="goods-services-fields" v-if="favouriteGoods !== null">
			<div class="columns is-gapless is-multiline">
				<div class="column is-4-desktop is-6-tablet" v-for="(good, index) in favouriteGoods">
					<div class="card" v-if="!good.article.trash">
						<div class="card-content">
							<div class="media">
								<div class="media-left">
									<div class="user center-inside">
										<img v-if="good.article.photo" :src="good.article.photo" />
										<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
									</div>
								</div>
								<div class="media-content">
									<p class="has-text-primary">
										<b>{{ good.article.title }}</b>
									</p>
									<p class="has-text-dark">{{ good.article.article_type.name_pt }}</p>
								</div>
								<!--            <div class="media-right">
                              <div class="media-content">
                                <p class="has-text-secondary"><b>{{ good.article.distance }} km</b></p>
                              </div>
                            </div>-->
								<div class="media-right">
									<div class="media-content is-click" @click="removeFavourite(good.id, index)">
										<i class="mdi mdi-heart mdi-24px not-favourite"></i>
									</div>
								</div>
							</div>
							<div class="media">
								<div class="media-left">
									<div class="media-content">
										<p v-if="good.article.transaction === 'sell'" class="has-text-primary">Venda</p>
										<p v-if="good.article.transaction === 'exchange'" class="has-text-primary">Troca</p>
										<p v-if="good.article.transaction === 'donate'" class="has-text-primary">Doação</p>
										<p v-if="good.article.transaction === 'buy'" class="has-text-primary">Compra</p>
										<p v-if="good.article.transaction !== 'exchange' && good.transaction !== 'donate'" class="has-text-primary">
											<b>{{ good.article.price }} €</b>
										</p>
									</div>
								</div>
								<div class="media-content has-text-centered"></div>
								<div class="media-right">
									<div class="media-content">
										<button class="button is-rounded is-primary is-fullwidth" @click="goToPage('/goods/' + good.article.id)"><b>Detalhes</b></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//API
	import { createWishGood, deleteWishGood, listOwnWishGoods } from '@/api/apiGoodsAndServices';
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { loading } from '@/mixins/shared/loading';

	export default {
		mixins: [loading, userInfo, goToPage],
		name: 'Favourites',
		data() {
			return {
				isButtonLoading: false,
				favouriteGoods: null,
			};
		},
		created() {
			this.setPageLoading(true);
			listOwnWishGoods()
				.then(response => {
					console.log(response);
					this.favouriteGoods = response.data;
					this.setPageLoading(false);
				})
				.catch(error => {
					this.setPageLoading(false);
				});
		},
		methods: {
			removeFavourite(id, index) {
				deleteWishGood(id)
					.then(response => {
						console.log(response);
						this.favouriteGoods.splice(index, 1);
					})
					.catch(error => {});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '../../../assets/scss/goodsAndServices';

	.goods-services-fields {
		width: 100%;
		height: calc(100vh - 77px);
		position: absolute;
		padding-left: 0;
		padding-right: 0;
		overflow-y: scroll;
	}

	.card {
		width: 93%;
		border-radius: 25px;
		margin: 10px auto;
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.media {
		border: none;
		margin-top: 0;
	}
</style>
