<template>
	<div class="modal is-active">
		<div
			class=" modal-background
  "
		></div>
		<div class="modal-card">
			<section class="modal-card-body">
				<b-carousel :has-drag="true" v-model="step" :autoplay="false" :repeat="false">
					<b-carousel-item v-for="(image, i) in images" :key="i">
						<section>
							<span class="image">
								<img :src="image.url" />
							</span>
							<div class=" tutorial-button center-inside">
								<button v-if="i === images.length - 1" class="button is-rounded is-secondary " @click="endTutorial()">
									Terminar
								</button>
								<button v-else class="button is-rounded is-primary " @click="next">
									Seguinte
								</button>
							</div>
						</section>
					</b-carousel-item>
				</b-carousel>
			</section>
		</div>
	</div>
</template>

<script>
	//API
	import { deactivateTutorial } from '@/api/apiTutorial';
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';

	export default {
		name: 'Tutorial',
		mixins: [userInfo],
		data() {
			return {
				step: 0,
				images: [
					{ url: require('@/assets/images/tutorial/1.png') },
					{ url: require('@/assets/images/tutorial/2.png') },
					{ url: require('@/assets/images/tutorial/3.png') },
					{ url: require('@/assets/images/tutorial/4.png') },
					{ url: require('@/assets/images/tutorial/5.png') },
				],
			};
		},
		methods: {
			next() {
				this.step = this.step + 1;
			},
			endTutorial() {
				deactivateTutorial()
					.then(response => {
						this.userData.show_tutorial = false;
						this.$store.commit('auth/setShowTutorial', false);
						console.log(this.$store.getters['auth/getShowTutorial']);
					})
					.catch(error => {
						this.userData.show_tutorial = false;
						this.$store.commit('auth/setShowTutorial', false);
						console.log(this.$store.getters['auth/getShowTutorial']);
					});
				this.$forceUpdate();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.modal-card {
		border-radius: 20px;
	}

	.modal-card-body {
		padding: 0;
	}

	.tutorial-button {
		width: 100%;
		position: absolute;
		bottom: 20px;
		z-index: 1;
		margin-bottom: 10px;
	}
	img {
		height: 96vh;
		width: 50vw;
		transform: translateY(-66px);
		object-fit: scale-down;
	}

	@media screen and (max-width: 767px) {
		.modal-card {
			width: 75vw;
			height: 90vh !important;
		}

		img {
			height: 84vh;
			width: 75vw;
		}
	}
	@media screen and (min-width: 768px) and (max-width: 1024px) {
		.modal-card {
			width: 53vw;
			height: 86vh !important;
		}

		img {
			height: 84vh;
			width: 75vw;
		}
	}

	@media screen and (min-width: 1025px) {
		.modal-card {
			width: 25vw;
			height: 86vh !important;
		}

		img {
			height: 84vh;
			width: 75vw;
		}
	}
</style>
