<template>
	<section>
		<loading :isPageLoading="isPageLoading"></loading>
		<div class="auth-div">
			<div class="header">
				<i @click="goBack" class="mdi mdi-chevron-left mdi-36px"></i>

				<i v-if="dataUsed.sort === 'price'" class="mdi mdi-currency-eur mdi-24px" style="margin-right:10px;" @click="changeSortSearch"></i>
				<i v-if="dataUsed.sort === 'duration'" class="mdi mdi-timer-outline mdi-24px" style="margin-right:10px;" @click="changeSortSearch"></i>

				<span class="title">{{ getFlightData[0].OutboundLegId.OriginStation.Code }}-{{ getFlightData[0].OutboundLegId.DestinationStation.Code }}</span>
			</div>
			<div v-for="(flight, index) in getFlightData" :key="index">
				<div class="going-card" @click="seeDetails(getFlightData[index])">
					<div class="top-card">
						<div class="media media-top">
							<div class="media-left">
								<p class="hour">
									{{ flight.OutboundLegId.Departure | moment('HH:mm') }}
								</p>

								<p class="dark-label">
									{{ flight.OutboundLegId.OriginStation.Code }}
								</p>
							</div>

							<div class="media-content">
								<p class="dark-label">
									{{ getFlightDuration(flight.OutboundLegId.Duration) }}
								</p>

								<hr />
								<div class="company-container">
									<p class="initials">
										{{ flight.OutboundLegId.Carriers[0].DisplayCode }}
									</p>
									<img class="logo-company" :src="flight.OutboundLegId.Carriers[0].ImageUrl" alt="" />
								</div>
							</div>
							<div class="media-right">
								<p class="hour">
									{{ flight.OutboundLegId.Arrival | moment('HH:mm') }}
								</p>
								<p class="dark-label">
									{{ flight.OutboundLegId.DestinationStation.Code }}
								</p>
							</div>
						</div>
					</div>
					<br />
					<div class="media">
						<div class="media-left"></div>
						<div class="media-content"></div>
						<div class="media-right">
							<div class="price">
								<p class="price-value">{{ flight.PricingOptions[0].Price }} €</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { flightSteps } from '@/mixins/flight/flightSteps';
	import { getFlight } from '@/api/apiFlights';
	import moment from 'moment';
	import Loading from '@/components/Loading/Loading';
	export default {
		mixins: [flightSteps],
		components: {
			Loading,
		},
		data() {
			return {
				newOrder: null,
				dataUsed: null,
				isPageLoading: true,
			};
		},
		created() {
			window.scrollTo(0, 0);
			this.dataUsed = this.getPassengers;
			this.isPageLoading = false;
		},
		methods: {
			seeDetails(index) {
				console.log(index);
				this.$router.push({ name: 'FlightsDetails', params: { details: index } });
			},

			changeSortSearch() {
				this.isPageLoading = true;
				if (this.dataUsed.sort === 'duration') {
					this.dataUsed.sort = 'price';
				} else this.dataUsed.sort = 'duration';

				getFlight(this.dataUsed)
					.then(response => {
						console.log(response.data);
						if (response.data.length > 0) {
							this.$store.commit('flightSteps/setActiveFlightStep', 2);
							this.$store.commit('flightSteps/setFlightData', response.data);
							this.$store.commit('flightSteps/setPassengers', this.dataUsed);
							this.isPageLoading = false;
						} else {
							this.$buefy.toast.open({
								message: 'Os teus dados estão a ser processados. Por favor tenta novamente.',
								position: 'is-top',
								type: 'is-danger',
							});
							this.isPageLoading = false;
						}
					})
					.catch(error => {
						this.isPageLoading = false;
						this.isLoading = false;
						this.$buefy.toast.open({
							message: error,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
			goBack() {
				this.setFlightStep(0);
			},
			getFlightDuration(time) {
				//create duration object from moment.duration
				var duration = moment.duration(time, 'minutes');

				//calculate hours
				var hh = duration.years() * (365 * 24) + duration.months() * (30 * 24) + duration.days() * 24 + duration.hours();

				//get minutes
				var mm = duration.minutes();

				if (hh < 10 && mm < 10) {
					return '0' + hh + ':' + '0' + mm;
				} else if (hh < 10) {
					return '0' + hh + ':' + mm;
				} else if (mm < 10) {
					return hh + ':' + '0' + mm;
				} else return hh + ':' + mm;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';
	@import '../../assets/scss/flightGoing';

	.logo-company {
		object-fit: scale-down;
		width: 50px;
	}

	.company-container {
		justify-content: center;
		margin-top: 10px;
	}

	.mdi-chevron-left::before {
		transform: translateY(4px) !important;
	}

	.mdi-currency-eur::before,
	.mdi-timer-outline::before {
	
		color: $secondary;
		margin-right: 10px;
	}
	.header {
		margin-top: 6px;
	}
</style>
