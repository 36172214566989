<template>
	<section>
		<div class="goods-services-div" v-if="!showError">
			<div class="services-header">
				<i @click="$router.go(-1)" class="mdi mdi-chevron-left mdi-36px"></i>
				<p class="title has-text-primary"><b>Serviços</b></p>
			</div>

			<div class="goods-services-fields">
				<p style="font-size: 20px" class="has-text-primary">
					<b>{{ serviceInfo.title }}</b>
				</p>
				<hr />
				<p class="has-text-dark">{{ serviceInfo.description }}</p>
				<hr />
				<p style="font-size: 20px" class="has-text-primary">
					<b>{{ serviceInfo.hourly_price }}€/hora</b>
				</p>
				<hr />
				<div class="media" @click="$router.push('/emigrant/' + serviceInfo.emigrant_id)">
					<div class="media-left">
						<div class="user center-inside">
							<img class="profile-photo" v-if="serviceInfo.emigrant.photo" :src="serviceInfo.emigrant.photo" />
							<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
						</div>
					</div>
					<div class="media-content">
						<p class="has-text-primary">
							<b>{{ serviceInfo.emigrant.name }}</b>
						</p>
					</div>
					<div class="media-right">
						<div class="media-content">
							<p v-if="serviceInfo.distance > 1" class="has-text-secondary">
								<b>{{ serviceInfo.distance }}km</b>
							</p>
							<p v-else class="has-text-secondary">&lt 1 km</p>
						</div>
					</div>
				</div>
				<hr />
				<gmap-map
					v-if="serviceInfo.share_location"
					ref="map"
					class="map-size"
					:zoom="8"
					:center="{ lat: serviceInfo.emigrant.destination_latitude, lng: serviceInfo.emigrant.destination_longitude }"
					:options="options"
				>
					<GmapCircle
						ref="circleRef"
						:center="{ lat: serviceInfo.emigrant.destination_latitude, lng: serviceInfo.emigrant.destination_longitude }"
						:radius="this.serviceInfo.range_radius * 1000"
						:visible="true"
						:options="{ fillColor: '#1d4696', fillOpacity: 0.3, strokeColor: '#f6a328' }"
					></GmapCircle>
					<gmap-marker :icon="mapMarker" :position="{ lat: serviceInfo.emigrant.destination_latitude, lng: serviceInfo.emigrant.destination_longitude }"> </gmap-marker>
				</gmap-map>
				<div class="next-div has-text-centered">
					<!--          <a class="button is-rounded is-primary is-fullwidth" href="tel:123-456-7890">123-456-7890</a>-->
					<button class="button is-rounded is-primary is-fullwidth" @click="goToChat">
						Contactar
					</button>
				</div>
			</div>
		</div>
		<div style="height:100vh" class="goods-services-div has-text-centered" v-else>
			<div class="services-header">
				<i @click="$router.go(-1)" class="mdi mdi-chevron-left mdi-48px"></i>
				<p class="title has-text-primary"><b>Serviços</b></p>
			</div>
			<Error type="serviço" />
		</div>
	</section>
</template>

<script>
	import { getServiceByID } from '@/api/apiGoodsAndServices';
	import { sendMessageRequest } from '@/api/apiChat';
	//Mixins
	import { loading } from '@/mixins/shared/loading';
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';
	//MAP Style
	const mapStyle = require('@/components/Community/Map/mapStyle.json');

	import Error from '@/views/Error.vue';
	export default {
		mixins: [loading, userInfo, goToPage],
		name: 'ServicePage',
		components: {
			Error,
		},
		data() {
			return {
				serviceInfo: null,
				mapMarker: require('@/assets/images/mapMarkers/mapMarker.png'),
				options: {
					zoomControl: false,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
					disableDefaultUi: false,
					styles: mapStyle,
				},
				showError: false,
			};
		},
		created() {
			console.log(this.serviceInfo);
			this.setPageLoading(true);
			getServiceByID(this.$route.params.service_id)
				.then(response => {
					console.log(response);
					this.serviceInfo = response.data;
					this.setPageLoading(false);
				
					if (this.serviceInfo ) {
						this.showError = false
					}
					else this.showError = true;
				})
				.catch(error => {
					this.setPageLoading(false);
				});
		},
		methods: {
			goToChat() {
				console.log(this.serviceInfo.emigrant.room);
				console.log(this.userData.id);
				console.log(this.user);
				if (this.serviceInfo.emigrant.room !== null) {
					this.goToPage('/chat/' + this.serviceInfo.emigrant.room._id + '/' + this.userData.id);
				} else {
					sendMessageRequest(this.serviceInfo.emigrant_id)
						.then(response => {
							console.log(response);
							this.goToPage('/chat/' + response.data.room._id + '/' + this.userData.id);
						})
						.catch(error => {});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';
	@import '../../assets/scss/goodsAndServices';

	.select,
	select {
		width: 100% !important;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.file-cta {
		width: 400px;
		height: 200px;
		padding: 0;
		background-color: #bac3dc !important;
	}

	.button {
		margin-top: 30px;
	}

	hr {
		background-color: whitesmoke !important;
	}

	.goods-services-fields {
		height: calc(100vh - 90px);
	}

	.services-header {
		display: flex;
		align-items: center;
		width: 70vw;
	}

	.mdi-chevron-left {
		color: #1d4696;
		transform: translate(10px, 5px);
	}
</style>
