import VueCookies from 'vue-cookies';

// initial state
const state = {
	token: null,
	isFirstAuth: false,
	userType: null,
	userData: {},
	showTutorial: null,
	confirmAddress: null,
	hasVerifiedToken: false,
	socialType: null,
};

// getters
const getters = {
	getToken(state, getters) {
		return state.token;
	},
	getFirstAuth(state, getters) {
		return state.isFirstAuth;
	},
	getUserType(state, getters) {
		return state.userType;
	},
	getUserData(state, getters) {
		return state.userData;
	},
	getHasVerifiedToken(state, getters) {
		return state.hasVerifiedToken;
	},
	getShowTutorial(state, getters) {
		return state.showTutorial;
	},
	getConfirmAddress(state, getters) {
		return state.confirmAddress;
	},
	getSocialType(state, getters) {
		return state.socialType;
	},
};

// actions
const actions = {
	authenticateUser({ commit }, userData) {
		// Set token
		const token = userData.headers.authorization;
		commit('setToken', token);

		// Set firstAuth
		const firstAuth = userData.data.body.register_completed;
		commit('setFirstAuth', !firstAuth);

		//SetShowTutorial
		const showTutorial = userData.data.body.show_tutorial;
		commit('setShowTutorial', showTutorial);

		//Set All User Data
		commit('setUserData', userData.data.body);
		//Set User Type
		if (userData.data.body.user_type.type === 'emigrant') {
			commit('setUserType', 0);

			//SetConfirmAddress
			const confirmAddress = userData.data.body.info.location_confirmed;
			commit('setConfirmAddress', confirmAddress);
		} else if (userData.data.body.user_type.type === 'local_market') {
			commit('setUserType', 1);
		} else if (userData.data.body.user_type.type === 'admin') {
			commit('setUserType', 2);
		}
	},
};

// mutations
const mutations = {
	setToken(state, token) {
		state.token = token;
	},
	setFirstAuth(state, isFirstAuth) {
		state.isFirstAuth = isFirstAuth;
	},
	setUserData(state, userData) {
		state.userData = userData;
	},
	setUsername(state, username) {
		state.username = username;
	},
	setUserType(state, userType) {
		state.userType = userType;
	},
	removeToken(state) {
		state.token = null;
	},
	setHasVerifiedToken(state, hasVerifiedToken) {
		state.hasVerifiedToken = hasVerifiedToken;
	},
	setShowTutorial(state, showTutorial) {
		state.showTutorial = showTutorial;
	},
	setConfirmAddress(state, confirmAddress) {
		state.confirmAddress = confirmAddress;
	},
	setSocialType(state, socialType) {
		state.socialType = socialType;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
