<template>
	<section>
		<div class="modal is-active">
			<div class="modal-background"></div>
			<div class="modal-card">
				<section class="modal-card-body">
					<button @click="closeModal()" class="delete" aria-label="close"></button>
					<p class=" modal-card-title title has-text-primary"><b>Suporte</b></p>
					<br />
					<div class="field email-field">
						<label class="label">Email</label>
						<div class="control has-icons-right">
							<input class="input is-success" :class="{ 'error-text': errors.has('email') }" type="text" v-validate="'email'" name="email" v-model="email" />
							<span class="icon is-small is-right">
								<i v-show="!errors.has('email') && email != ''" class="required-icon mdi mdi-check mdi-24px"></i>
							</span>
						</div>
						<p v-show="errors.has('email')" class="help is-danger">
							Insere o teu endereço de email
						</p>
					</div>
					<div class="field email-field">
						<label class="label">Tipo de solicitação</label>
						<div class="select control">
							<select v-validate="'required'" v-model="request" name="request">
								<option value="Problemas com o login">Problemas com o login</option>
								<option value="Conta Inativa">Conta Inativa</option>
								<option value="Mudança de código postal">Mudança de código postal</option>
								<option value="Outra">Outra</option>
							</select>
						</div>
						<p v-show="errors.has('request')" class="help is-danger">
							Selecione um tipo de solicitação
						</p>
					</div>
					<div class="field email-field">
						<label class="label">Descrição do problema</label>
						<div class="control has-icons-right">
							<textarea
								class="input is-success"
								:class="{ 'error-text': errors.has('requestDescription') }"
								type="text"
								v-validate="'required'"
								name="requestDescription"
								v-model="requestDescription"
							/>
						</div>
						<p v-show="errors.has('requestDescription')" class="help is-danger">
							Descreva o problema
						</p>
					</div>

					<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isButtonLoading }" @click="sendRequest">
						Enviar
					</button>
				</section>
			</div>
		</div>
	</section>
</template>

<script>
	import { createTicket, listTickets } from '@/api/apiSupport';
	export default {
		data() {
			return {
				isButtonLoading: false,
				email: '',
				request: 'Problemas com o login',
				requestDescription: '',
				inputError: false,
			};
		},

		methods: {
			closeModal(event) {
				this.$emit('clicked', false);
			},
			sendRequest() {
				this.$validator.validateAll().then(result => {
					this.isButtonLoading = true;
					if (result && this.email !== '') {
						let ticket = {
							type: this.request,
							description: this.requestDescription,
							email: this.email,
						};
						createTicket(ticket)
							.then(response => {
								console.log(response);
								this.inputError = false;
								this.$buefy.toast.open({
									message: 'Pedido de suporte enviado com sucesso',
									position: 'is-top',
									type: 'is-primary',
								});
								this.closeModal();
							})
							.catch(error => {
								this.isButtonLoading = false;
								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					} else this.isButtonLoading = false;
				});
				console.log(this.$validator);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		bottom: 5%;
	}

	.field {
		margin-bottom: 0;
	}

	.email-field {
		position: relative;
		margin-bottom: 40px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 25px;
	}

	.register-steps > .step-1 {
		background: #1d4696;
	}

	.select-origin {
		color: #bac3dc !important;
	}

	.select,
	select {
		width: 100% !important;
	}

	.modal-card {
		border-radius: 20px;
	}

	.delete {
		float: right;
		margin-right: 13px;
	}

	textarea {
		resize: none;
		max-height: 80px;
	}
</style>
