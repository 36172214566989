import { MapElementFactory } from "vue2-google-maps";


export default MapElementFactory({

    name: "directionsRenderer",

    ctr() {
        return window.google.maps.DirectionsRenderer;
    },

    events: [],

    mappedProps: {},

    props: {
        origin: { type: Object },
        destination: { type: Object },
        travelMode: { type: String }
    },

    beforeCreate(directionsRenderer) {
        let storedHitchhikeData = this.$store.getters["hitchhikeSteps/getHitchhikeData"]
        let finalOrigin = storedHitchhikeData.formatted_departure_address
        let finalDestination = storedHitchhikeData.formatted_arrival_address
        let directionsService = new window.google.maps.DirectionsService();
        let origin = finalOrigin
        let destination = finalDestination
        let travelMode = this.travelMode
        let provideRouteAlternatives = true
        directionsService.route(
            {
                origin,
                destination,
                travelMode,
                provideRouteAlternatives

            },

            (response, status) => {

                if (status !== "OK") return;
                console.log(response)
                let data = this.$store.getters["hitchhikeSteps/getHitchhikeData"]
                data.routes = response
                console.log(data);
                this.$store.commit('hitchhikeSteps/setHitchhikeData', data)

            }
        );

    }
});