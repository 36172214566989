<template>
	<section>
		<div class="logo center-inside">
			<img width="140" src="../../../assets/logos/logo.png" />
		</div>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Recuperar Password</b></p>
			<p class="small-text mb-3">Em breve receberás um e-mail, abre e segue os passos para recuperares a tua password</p>
			<div class="field email-field">
				<label class="label">Email</label>
				<div class="control has-icons-right">
					<input class="input is-success" :class="{ 'error-text': errors.has('email') }" type="text" v-validate="'required|email'" name="email" v-model="recover_email" />
					<span class="icon is-small is-right">
						<i v-show="!errors.has('email') && recover_email != ''" class="required-icon mdi mdi-check mdi-24px"></i>
					</span>
				</div>
				<p v-show="errors.has('email')" class="help is-danger">Formato de email inválido</p>
			</div>
			<div class="auth-buttons has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isLoading }" @click="recoverPassword"><b>Recuperar</b></button>

				<br />
				<br />
				<p class="small-text">Já tens conta? <span class="link-text is-click" @click="goToPage('/login')">Efetua Login agora!</span></p>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { goToPage } from '@/mixins/shared/goToPage';
	//API
	import { requestRecoverPassword } from '@/api/apiRegister';

	export default {
		mixins: [goToPage],
		name: 'Forgot',
		data() {
			return {
				recover_email: '',
				isLoading: false,
			};
		},
		methods: {
			recoverPassword() {
				this.isLoading = true;
				this.$validator.validateAll().then(result => {
					if (result) {
						let data = {
							email: this.recover_email,
						};
						requestRecoverPassword(data)
							.then(response => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: 'Verifica o teu email!',
									position: 'is-top',
									type: 'is-primary',
								});
								this.$router.push('/login');
							})
							.catch(error => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
								this.$router.push('/login');
							});
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		bottom: 10%;
	}

	.email-field {
		margin-bottom: 30px;
	}

	.button {
		margin-top: 40px;
		margin-bottom: 10px;
	}
</style>
