<template>
	<section>
		<div class="auth-div">
			<p class="title has-text-primary has-text-weight-bold"><b>POLÍTICA DE PRIVACIDADE e.migra</b></p>
			<div id="policies-body" class="policies" @scroll="onScroll">
				<div class="policies-body">
					<p>
						A presente Política de Privacidade, elaborada de acordo com o Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016,
						(doravante apenas RGPD) e legislação nacional portuguesa aplicável, pretende dar a conhecer de forma adequada e informada como são recolhidos e tratados os
						seus dados pessoais pelo MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, relativamente ao website e à aplicação e.migra.
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">1. Quem é o responsável pelo tratamento dos seus dados pessoais?</p>
					<br />
					<p>
						Os seus dados pessoais são tratados pelo MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, com o número de identificação de
						pessoa coletiva 516 073 141, com sede na Rua São Miguel, n.o 36, 4570-346, Laúndos – Póvoa de Varzim. <br />
						MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil poderá modificar ou alterar a presente Política de Privacidade, a qualquer
						momento. Tais modificações e alterações serão comunicadas através do nosso website. <br />
						Em caso de dúvidas ou esclarecimento adicional poderá contactar-nos através do email:
						<a href="mailto:dpo@appemigra.com">dpo@appemigra.com</a>
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">2. Que categorias de dados pessoais tratamos?</p>
					<br />
					<p>
						Quando usa o e.migra no website ou aplicação, o MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil recolhe: <br />
						• As informações básicas de identidade que fornecer no seu registo na aplicação, como nome, morada, data de nascimento, endereço de e-mail, username, código
						postal, foto de perfil (opcional), género (opcional), contacto móvel (opcional); <br />
						• O conteúdo da sua comunicação e os detalhes técnicos da própria comunicação; <br />
						• preferências em relação à receção de comunicações por e-mail, como newsletters, anúncios, parceiros; <br />
						• Dados de identificação eletrónica, como endereço IP e protocolo; <br />
						• Dados do dispositivo, como o tipo e modelo, sistema operativo e versão, o seu identificador único, dados de relatório de erro e quais as configurações que
						ativou; <br />
						• Dados de registo, como a data e hora da sua visita, a versão que utilizou (e as suas definições), os URLs de proveniência e de destino, e o como utilizou
						o e.migra, website ou aplicação, que são automaticamente gravados pelos nossos servidores, com base nos dados enviados pelo website ou pela aplicação;
						<br />
						• Dados cookies, que vêm de pequenos ficheiros de dados armazenados no seu computador ou dispositivo móvel, e tecnologias similares. Os dados Cookies
						ajudam- nos a reconhecê-lo, a memorizar as suas preferências e a personalizar a sua experiência. Os parceiros publicitários também utilizam dados cookies;
						<br />
						• Pixels, SKDs e outras tecnologia e armazenamento que funcionam de forma semelhante aos cookies e são utilizados por nós, bem como por terceiros, tais como
						as redes sociais e plataformas de patrocinadores, para recolher ou receber informação; <br />
						• Da nossa e de outras aplicações para fornecer serviços de medição e anúncios alvo; <br />
						• Informações publicamente disponíveis no seu perfil das Redes Sociais e quaisquer outros dados pessoais que nos fornecer; <br />
						• Dados de terceiros ou redes públicas, como dados do censo, endereço de ruas, informações de parceiros para ajudar a personalizar a publicidade ou medir o
						desempenho dos seus anúncios; <br />
						• Se decidir convidar novos membros para se juntarem ao E.migra, pode optar por partilhar connosco o seu endereço residencial ou eletrónico, ou partilhar os
						seus contactos connosco, para que possamos enviar um convite e lembretes de acompanhamento a potenciais novos membros em seu nome; <br />
						• Se utilizar as redes sociais como o Facebook, Instagram ou Twitter para iniciar sessão no e.migra está a consentir que as redes sociais acima mencionadas
						partilhem as suas informações pessoais com o e.migra, incluindo o seu nome e endereço de correio eletrónico;
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">3. Com que finalidade tratamos os seus dados pessoais?</p>
					<br />
					<p>
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, trata os seus dados pessoais que são necessários à utilização da e.migra,
						seja através do website, seja através da aplicação, por si solicitados à MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil.
						<br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, trata os seus dados pessoais para realizar análises estatísticas para que
						possamos melhorar o e.migra, quer o website quer a aplicação e desenvolver novos produtos e serviços. <br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, trata os seus dados pessoais para fornecer e melhorar anúncios, como
						medir o interesse de um anúncio para membros. <br />
						Tratamos os seus dados com vista à definição de perfis e decisões automatizadas, com base nas suas preferências com vista a publicidade dirigida quando
						utiliza o nosso website ou aplicação. <br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, trata os seus dados pessoais para cumprir obrigações legais ou para
						atender a qualquer solicitação razoável dos agentes ou representantes da lei competentes, autoridades judiciais, agências ou órgãos governamentais,
						incluindo autoridades competentes de proteção de dados. Os seus dados pessoais podem ser transferidos por iniciativa própria do O MECS – Movimento de
						Empreendedorismo e Criatividade Social – Associação Juvenil, à polícia ou às autoridades judiciais como prova ou se houver suspeitas justificadas de um ato
						ilícito ou crime cometido por si através do seu registo, uso do website, aplicação, redes sociais ou outra comunicação connosco. <br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, pode tratar os seus dados pessoais para preservar os interesses legítimos
						do MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, dos seus parceiros ou terceiros, se e quando o seu registo no e.migra
						seja no website seja na aplicação para dispositivos móveis puderem ser considerados (a) uma violação de quaisquer termos de uso aplicáveis ou os direitos de
						propriedade intelectual ou qualquer outro direito de terceiros, (b) uma ameaça à segurança ou integridade do site, (c) um perigo para o site ou qualquer um
						dos Sistemas subjacentes do MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, ou dos seus subcontratados devido a vírus,
						cavalos de Tróia, spyware, malware ou qualquer outra forma de código malicioso, ou (d) de qualquer maneira odiosa, obscena, discriminatória, racista,
						caluniosa, maldosa ou de alguma outra forma inadequado ou ilegal. <br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, pode tratar os seus dados pessoais com terceiros utilizando a sua
						informação para fornecer e melhorar anúncios ou outras ofertas comerciais, tanto dentro como fora dos nossos serviços. Os parceiros são contratualmente
						obrigados a utilizá-lo apenas para nos prestarem o seu serviço, e estão contratualmente proibidos de o utilizar para os seus próprios objetivos.
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">4. Porque é que o nosso tratamento dos seus dados pessoais é legítimo?</p>
					<br />
					<p>
						Tratamos os seus dados pessoais através do seu consentimento, livre, informado e explícito, para a utilização do e.migra através do website ou da aplicação
						móvel. <br />
						<br />
						Tratamos os seus dados para o cumprimento de obrigações jurídicas; <br />
						<br />

						Tratamos os seus dados sempre que for necessário para a execução de um contrato no qual o titular dos dados é parte, ou para diligências pré-contratuais a
						pedido do titular dos dados; <br />
						<br />
						Tratamos os seus dados pessoais, sempre que for necessário para efeito dos interesses legítimos do MECS – Movimento de Empreendedorismo e Criatividade
						Social – Associação Juvenil, que são: <br />
						• ser capaz de responder adequadamente às suas solicitações. <br />
						• comunicar os seus dados pessoais aos nossos parceiros e utilizadores para fornecer informações adequadas; <br />
						• permitir-nos defender em procedimentos legais; <br />
						• melhorias contínuas no website e na aplicação, produtos e serviços para garantir que tenha a melhor experiência possível; <br />
						• manter a nossa website e aplicação e.migra protegidos contra uso indevido e atividades ilegais; <br />
						• melhorar e adequar os anúncios ou outras ofertas publicitárias;
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">5. Quais são as nossas medidas de segurança e confidencialidade dos seus dados?</p>
					<br />
					<p>
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil – Movimento de Empreendedorismo e Criatividade Social – Associação
						Juvenil, apenas trata os seus dados pessoais que são necessários para alcançar as finalidades listadas nesta política. Os seus dados pessoais são tratados
						somente pelo tempo que for necessário para os fins listados acima ou até o momento em que retirar o seu consentimento para o tratamento. No entanto, esse
						facto não impede o tratamento anteriormente realizado ou invalida, tratamentos futuros para cumprimento de obrigações legais. <br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, irá anonimizar os seus dados pessoais quando eles não forem mais
						necessários para os fins descritos na finalidade do tratamento, a menos que haja uma obrigação legal ou regulamentar ou uma ordem judicial ou administrativa
						que impeça MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, de identificá-los. <br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, aplica todas as medidas técnicas e organizacionais para assegurar um
						nível de segurança adequado ao risco de cada tratamento. <br />
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, apenas transmitirá os seus dados pessoais a terceiros somente quando
						necessário para a prestação de serviços e tal transmissão estará sempre sujeita a obrigações contratuais dos terceiros e de confidencialidade estritas.
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">6. Por quanto tempo são conservados os seus dados?</p>
					<br />
					<p>
						Guardamos as suas informações nos nossos registos do servidor, nas nossas bases de dados, e nos nossos registos pelo tempo necessário para a prestação dos
						serviços. Assim, logo que a finalidade para qual os dados foram recolhidos termine, os mesmos serão apagados ou eliminados periodicamente. Porém, em
						determinados casos concretos e definidos os dados poderão ser conservados, por qualquer imposição legal, ou prova, de acordo com os períodos legais de
						prescrição.
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">7. Quais são os seus direitos?</p>
					<br />
					<p>
						Tem o direito de solicitar o acesso a todos os seus dados pessoais tratados pelo MECS – Movimento de Empreendedorismo e Criatividade Social – Associação
						Juvenil. <br />
						<br />
						Tem o direito de solicitar que os seus dados pessoais imprecisos sejam corrigidos gratuitamente. <br />
						<br />
						Tem o direito de retirar o seu consentimento em qualquer altura, sem comprometer a licitude do tratamento efetuado com base no consentimento previamente
						prestado para tratar seus dados pessoais. <br />
						<br />
						Tem o direito de solicitar que os seus dados pessoais sejam apagados, no entanto, é necessário ter em mente que uma solicitação de exclusão será avaliada
						pelo MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, em obediência às obrigações legais ou regulamentares ou ordens
						administrativas ou judiciais que possam contradizer tal exclusão. <br />
						<br />
						Tem o direito a solicitar que MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil, limite o tratamento dos seus dados pessoais
						se e quando: (a) contestar a exatidão desses dados, (b) o tratamento for ilegítimo ou (c) os dados não forem mais necessários para o propósito descrito
						acima, mas poderá precisar deles para se defender em processos judiciais. <br />
						<br />
						Tem o direito de se opor ao tratamento dos dados pessoais se puder provar que existem razões sérias e justificadas relacionadas com suas circunstâncias
						particulares que justificam tal oposição. No entanto, se o tratamento pretendido se qualifica como marketing direto, tem o direito de se opor a esse
						tratamento gratuitamente e sem justificação.
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">8. Como exercer os seus direitos?</p>
					<br />
					<p>
						Para exercer um, ou mais, dos direitos acima mencionados, pode enviar um e-mail para <a href="mailto:dpo@appemigra.com">dpo@appemigra.com</a> <br />
						A entidade competente, em Portugal para o exercício dos seus direitos nesta matéria, nomeadamente, reclamações, é a Comissão Nacional de Proteção de Dados
						Pessoais. Para mais informações, visite <a href="https://www.cnpd.pt/index.asp">https://www.cnpd.pt/index.asp</a>
					</p>
					<br />
					<p class="has-text-centered underline has-text-weight-bold">9. Alterações à Política de Privacidade:</p>
					<br />
					<p>
						O MECS – Movimento de Empreendedorismo e Criatividade Social – Associação Juvenil pode alterar, em qualquer momento, a sua política de privacidade. Assim,
						recomendamos a consulta regular da política de privacidade, que consta do website ou aplicação do e.migra, bem como, através de outro suporte, se
						solicitado.
					</p>
				</div>
			</div>
			<div class="next-div has-text-centered">
				<button v-if="getStepData === 'Google'" :disabled="disabledButton" @click="googleLogin" class="button register is-rounded is-primary is-fullwidth">
					<i class="mdi mdi-google mdi-24px"></i> Registar <i v-if="checked" class="required-icon mdi mdi-check mdi-24px ml-2"></i>
				</button>
				<button v-else-if="getStepData === 'Facebook'" :disabled="disabledButton" @click="facebookLogin" class="button register is-rounded is-primary is-fullwidth">
					<i class="mdi mdi-facebook mdi-24px"></i> Registar <i v-if="checked" class="required-icon mdi mdi-check mdi-24px ml-2"></i>
				</button>
				<button v-else-if="getStepData === 'Twitter'" :disabled="disabledButton" @click="twitterLogin" class="button register is-rounded is-primary is-fullwidth">
					<i class="mdi mdi-twitter mdi-24px"></i> Registar <i v-if="checked" class="required-icon mdi mdi-check mdi-24px ml-2"></i>
				</button>
				<div v-else>
					<button class="button is-rounded is-secondary" @click="goBack">
						<i style="width:40px;color: white;" class="mdi mdi-arrow-left-thick mdi-48px"></i>
					</button>
					<button class="button is-rounded is-primary button-size " :disabled="disabledButton" :class="{ 'is-loading': isLoading }" @click="register">
						Registar <i v-if="checked" class="required-icon mdi mdi-check mdi-24px ml-2"></i>
					</button>
					<div class="register-steps center-inside is-fullwidth">
						<div></div>
						<div></div>
						<div class="step-2"></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { steps } from '@/mixins/shared/steps';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { socialAuth } from '@/mixins/auth/socialAuth';
	//API
	import { createAccount } from '@/api/apiRegister';

	import firebase from 'firebase/app';
	import 'firebase/auth';

	export default {
		mixins: [socialAuth, steps, goToPage],
		name: 'PrivacyPolicies',
		components: {},
		data() {
			return {
				isLoading: false,
				disabledButton: true,
				checked: false,
			};
		},
		created() {
			const auth = firebase.auth.getAuth();

			if (this.$store.getters['auth/getSocialType'] == 'google') this.googleLoginCallback(auth);
			if (this.$store.getters['auth/getSocialType'] == 'facebook') this.facebookLoginCallback(auth);
			if (this.$store.getters['auth/getSocialType'] == 'twitter') this.twitterLoginCallback(auth);
		},
		mounted() {
			const div = document.getElementById('policies-body');
			let hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
			if (!hasVerticalScrollbar) {
				this.disabledButton = false;
			}
		},
		methods: {
			register() {
				this.isLoading = true;
				this.$validator.validateAll().then(result => {
					if (result) {
						createAccount(this.getStepData)
							.then(response => {
								console.log(response);
								this.isLoading = false;
								this.setActiveStep(4, null);
							})
							.catch(error => {
								this.isLoading = false;

								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
								let infoBack = {
									data: this.getStepData,
									type: 'error',
								};
								this.setActiveStep(2, infoBack);
								this.isLoading = false;
							});
					} else {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: 'Preencha os campos corretamente',
							position: 'is-top',
							type: 'is-primary',
						});
					}
				});
			},
			goBack() {
				let infoBack = {
					data: this.getStepData,
					type: 'error',
				};
				this.setActiveStep(2, infoBack);
			},
			onScroll: function({ target: { scrollTop, clientHeight, scrollHeight } }) {
				if (scrollTop + clientHeight + 1 >= scrollHeight) {
					this.disabledButton = false;
					this.checked = true;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		top: 8%;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 25px;
	}

	.register-steps > .step-2 {
		background: #1d4696;
	}

	.mdi {
		color: white;
		margin-right: 10px;
	}
	i::before {
		transform: translate(0, 0);
	}

	.mdi-google {
		transform: translate(0, 0);
	}

	.button-size {
		width: 170px;
		margin-left: 10px;
	}

	.title {
		font-size: 1rem;
	}

	.underline {
		text-decoration: underline;
	}

	@media screen and (max-width: 360px) {
		.button-size {
			width: 140px;
			margin-left: 10px;
		}
	}
</style>
