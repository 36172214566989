<template>
	<b-table :data="posts">
		<b-table-column label="Imagem">
			<template v-slot="props">
				<div class="mobile">
					<div class="file-cta center-inside">
						<img class="feed-content" v-if="props.row.media_url && props.row.media_type === 'image'" :src="props.row.media_url" />
						<Media v-else-if="props.row.media_url && props.row.media_type === 'video'" class="feed-content" :kind="'video'" :controls="true" :src="props.row.media_url">
						</Media>
						<i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
					</div>
				</div>
			</template>
		</b-table-column>

		<b-table-column label="Nome">
			<template v-slot="props">
				<p v-if="props.row.user.emigrant">{{ props.row.user.emigrant.name }}</p>
				<p v-else-if="props.row.user.local_market">{{ props.row.user.local_market.name }}</p>
			</template>
		</b-table-column>

		<template v-for="column in columns">
			<b-table-column :key="column.id" v-bind="column">
				<template v-if="column.searchable && !column.numeric" #searchable="props">
					<b-input v-model="props.filters[props.column.field]" placeholder="Pesquisa" icon="magnify" size="is-small" />
				</template>
				<template v-slot="props">
					{{ props.row[column.field] }}
				</template>
			</b-table-column>
		</template>

		<b-table-column label="Email">
			<template v-slot="props">
				{{ props.row.user.email }}
			</template>
		</b-table-column>

		<b-table-column v-slot="props">
			<b-button rounded class="deactivate-account" @click="removePost(props.row.id, props.index)">Eliminar</b-button>
		</b-table-column>
	</b-table>
</template>

<script>
	import { getAllPosts, deletePost } from '@/api/apiBackOffice';
	export default {
		data() {
			return {
				posts: [],
				columns: [
					{
						field: 'description',
						label: 'Descrição',
						searchable: false,
						width: '400',
					},
				],
			};
		},

		methods: {
			removePost(id, index) {
				let self = this;
				this.$buefy.dialog.confirm({
					title: 'Apagar Publicação',
					canCancel: ['button'],
					message: '<p style="color: #1d4696">Tens a certeza que pretendes eliminar esta publicação?</p>',
					cancelText: 'Confirmar',
					confirmText: 'Não',
					type: 'is-primary',
					onCancel() {
						console.log(index);

						deletePost(id)
							.then(response => {
								console.log(self.posts);
								self.posts.splice(index, 1);
								self.$buefy.toast.open({
									message: 'Publicação apagada com sucesso',
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: 'Ocurreu um erro ao apagar a publicação',
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
				});
			},
			getAll() {
				getAllPosts()
					.then(response => {
						console.log(response);
						this.posts = response.data;
						console.log(this.posts);
					})
					.catch(error => {
						console.log(error);
					});
			},
		},

		mounted() {
			this.getAll();
		},
		/* updated() {
	           this.getAll()
	       } */
	};
</script>

<style lang="scss" scoped>
	.activate-account {
		background-color: rgb(47, 179, 47);
		color: white;
		width: 163px;
	}

	.deactivate-account {
		background-color: rgb(201, 42, 42);
		color: white;
		width: 163px;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.feed-content {
		width: 200px;
		height: 200px;
		object-fit: scale-down;
	}

	.file-cta {
		width: 200px;
		height: 200px;
		padding: 0;
	}

	/deep/ td {
		vertical-align: middle !important;
	}

	@media screen and (max-width: 768px) {
		.mobile {
			width: 100%;
			justify-content: center;
			display: flex;
		}
	}
</style>
