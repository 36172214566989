<template>
  <section>
    <div class="tabs is-toggle center-inside is-toggle-rounded">
      <div class="tabs-shadow">
        <ul>
          <li @click="activeTab = 1" :class="{ 'is-active': activeTab === 1 }">
            <a>
              <span><b>Pesquisar</b></span>
            </a>
          </li>
          <li @click="activeTab = 0" :class="{ 'is-active': activeTab === 0 }">
            <a>
              <span><b>Criar Anúncio</b></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <OfferService v-if="activeTab === 0" :servicesList="servicesList" @changeTab="changeTab"></OfferService>
    <SearchService v-else :servicesList="servicesList"></SearchService>
  </section>
</template>
<script>
//Components
import SearchService from "@/components/Services/SearchService";
import OfferService from "@/components/Services/OfferService";

export default {
  name: "ChooseType",
  props: {
    servicesList: {
      type: Array,
      required: true
    }
  },
  components: { SearchService, OfferService },
  data() {
    return {
      activeTab: 1
    };
  },
  methods: {
    changeTab() {
      this.activeTab = 1;
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/goodsAndServices";

.tabs.is-toggle.is-toggle-rounded li:first-child a {
	border-radius: 20px;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
	border-radius: 20px;
}

.tabs-shadow {
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
}

.tabs.is-toggle a {
  border: none;
  color: #1d4696;
}

.tabs {
  padding: 10px;
}

.tabs.is-toggle li.is-active a {
  color: #1d4696;
}
</style>
