<template>
	<section>
		<p class="title has-text-primary"><b>Comunidade</b></p>
		<div class="center-inside">
			<div class="field">
				<div class="control  has-icons-right">
					<input @input="getInfo" class="input is-rounded is-focused search-box " v-model="searchString" type="text" placeholder="Pesquisar" />
					<span class="icon is-small is-right" @click="openModal">
						<i class="mdi mdi-tune-vertical-variant mdi-24px" :class="{ 'active-search': destination_country }"></i>
					</span>
				</div>
			</div>
		</div>
		<br />

		<div class="user-list">
			<p v-if="!destination_country" class="has-text-dark">Utilizadores perto de ti</p>
			<p v-else class="has-text-dark">Utilizadores no país - {{ countryName }}</p>
			<table style="width:100%; margin-bottom: 20px;">
				<tr v-for="(user, index) in filteredDataArray" v-if="user.id !== userData.info.id">
					<th @click="goToPage('/emigrant/' + user.id)">
						<div class="user center-inside">
							<img class="profile-photo" v-if="user.photo" :src="user.photo" />
							<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
						</div>
					</th>
					<th @click="goToPage('/emigrant/' + user.id)">
						<p class="subtitle">{{ user.name }}</p>
						<p v-if="user.distance > 1" class="distance">
							<b>{{ user.distance }}km</b>
						</p>
						<p v-else class="distance">&lt 1 km</p>
					</th>
					<th style="text-align: center">
						<button v-if="user.follow === 'none'" class="button is-rounded is-primary" @click="followUser(user.id, index)">
							<b>seguir</b>
						</button>
						<button @click="unfollowUser(user.follow_id, index)" v-else-if="user.follow === 'pending'" class="button is-rounded pending-button">
							<b>pendente</b>
						</button>

						<div v-else-if="user.follow === 'answering'">
							<button class="button is-rounded is-primary">
								<b @click="acceptFollow(user, 'accept', index)">aceitar</b>
							</button>
							<br />
							<button @click="acceptFollow(user, 'decline', index)" class="button is-danger mt-1 is-rounded">
								<b>recusar</b>
							</button>
						</div>

						<button @click="unfollowUser(user.follow_id, index)" v-else-if="user.follow === 'following'" class="button is-rounded following-button">
							<b>a seguir</b>
						</button>
					</th>
				</tr>
			</table>

			<p style="line-height: 60vh; text-align: center" class="has-text-danger" v-if="noUsers">Não foram encontrados utilizadores</p>
		</div>
		<FilterModal v-show="isModalOpen" :isModalOpen="isModalOpen" @clicked="closeModal" @data="saveCloseModal"></FilterModal>
	</section>
</template>

<script>
	//API
	import { getBySearch } from '@/api/apiCommunity';
	import { answerFollowRequest } from '@/api/apiFollow';
	//Mixins
	import { followUser } from '@/mixins/follow/followUser';
	import { goToPage } from '@/mixins/shared/goToPage';
	//Components
	import FilterModal from '@/components/Community/FilterModal';

	export default {
		props: {
			userData: {
				type: Object,
				required: true,
			},
			nearbyUsers: {
				type: Array,
				required: true,
			},
		},
		components: {
			FilterModal,
		},
		mixins: [followUser, goToPage],
		name: 'UsersList',
		data() {
			return {
				data: [],
				searchString: '',
				destination_country: null,
				destination_city: null,
				origin_city: null,
				selected: null,
				isModalOpen: false,
				countryName: null,
				noUsers: false,
			};
		},
		created() {
			this.data = this.nearbyUsers;
		},
		methods: {
			openModal() {
				this.isModalOpen = true;
			},
			closeModal(value) {
				this.isModalOpen = value;
			},
			saveCloseModal(destination_country, destination_city, origin_city, name) {
				this.destination_country = destination_country;
				this.destination_city = destination_city;
				this.origin_city = origin_city;
				this.countryName = name;
				this.getInfo();
			},
			getInfo() {
				let data = {
					...(this.searchString && { text: this.searchString }),
					...(this.destination_country && { destination_country: this.destination_country }),
					...(this.destination_city && { destination_city: this.destination_city }),
					...(this.origin_city && { origin_city: this.origin_city }),
				};
				console.log(data);

				if (!this.searchString && !this.destination_country && !this.destination_city && !this.origin_city) {
					this.data = this.nearbyUsers;
				} else {
					getBySearch(data)
						.then(response => {
							this.data = response.data;

							if (this.data.length === 0) {
								this.noUsers = true;
							} else this.noUsers = false;
						})
						.catch(err => {});
				}
			},
			acceptFollow(user, answer, index) {
				answerFollowRequest(user.follow_id, answer)
					.then(response => {
						if (answer === 'accept') {
							user.follow = 'following';
						} else user.follow = 'none';
						this.getInfo();
					})
					.catch(error => {});
			},
		},
		computed: {
			filteredDataArray() {
				return this.data; /* .filter(option => {
					return (
						option.name
							.toString()
							.toLowerCase()
							.indexOf(this.searchString.toLowerCase()) >= 0
					);
				});
				return this.data; */
			},
		},
	};
</script>

<style lang="scss" scoped>
	.title {
		margin-left: 25px;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		position: absolute;
		top: 20px;
	}

	.field {
		margin-top: 70px;
		width: 85%;
		margin-left: auto;
		margin-right: auto;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.user {
		width: 55px;
		height: 55px;
		padding: 0;
		border-radius: 50%;
		background-color: #bac3dc !important;
	}

	.profile-photo {
		border-radius: 50%;
		width: 55px;
		height: 55px;
	}

	.subtitle {
		font-family: 'Poppins', sans-serif;
		color: #1d4696;
		margin-top: 15px;
		margin-bottom: 5px;
		font-weight: bold;
		/* display: -webkit-box; */
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.pending-button {
		background-color: #bac3dc;
		color: white;
	}

	.distance {
		margin-bottom: 10px;
		color: #bac3dc;
	}

	.button {
		font-size: 12px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.following-button {
		background-color: #f6a328;
		color: white;
	}

	.search-box {
		height: 50px;
		color: #bac3dc;
		border: none;

		/* box-shadow */
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.is-focused {
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px !important;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px !important;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px !important;
	}

	.search-box::placeholder {
		color: #bac3dc;
		border: none;
	}

	.control.has-icons-left .icon,
	.control.has-icons-right .icon {
		color: #bac3dc;
		height: 2.5em;
		pointer-events: initial;
		position: absolute;
		top: 6px;
		right: 20px;
		width: 2.5em;
		z-index: 4;
	}

	.user-list {
		margin-top: 20px;
		width: 85%;
		margin-left: auto;
		margin-right: auto;
	}

	.user-div {
		margin-top: 20px;
	}

	th {
		vertical-align: middle;
	}

	.title {
		font-size: 1.5rem;
		margin-top: 4px;
	}

	.subtitle,
	.distance {
		font-size: 0.8rem;
		margin-left: 6px;
	}

	.active-search::before {
		color: #f6a328 !important;
	}

	.decline-button {
		color: white;
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
</style>
