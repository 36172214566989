<template>
  <section>
    <div class="hitchhike-fields">
      <div @click="selectRoute(route)" class="card is-click" v-for="route in getHitchhikeData.routes.routes">
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <div class="user center-inside">
                <i class="file-icon mdi mdi-car-outline mdi-36px"></i>
              </div>
            </div>
            <div class="media-content">
              <p class="has-text-primary">
                <b>{{ route.summary }}</b>
              </p>
            </div>
            <div class="media-right">
              <div class="media-content">
                <p class="has-text-secondary">
                  <b>{{ route.legs[0].duration.text }}</b>
                </p>
                <p class="has-text-dark">{{ route.legs[0].distance.text }}</p>
              </div>
            </div>
          </div>
          <div class="media">
            <!--            <div class="media-left">
                          <div class="media-content">
                            <p class="has-text-primary">
                              <b>{{ hitchhike.departure_date }}</b></p>
                          </div>
                        </div>-->
            <div class="media-content ">
              <p class="has-text-primary" style="font-size: 12px"><b>Este trajeto inclui portagens.</b></p>
              <p class="has-text-dark" style="font-size: 12px"><b>Antes de partir, verifica as restrições nas fronteiras. O teu destino está num fuso horário diferente.</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//Mixins
import { hitchhikeSteps } from "@/mixins/hitchhikes/hitchhikeSteps";
import { userInfo } from "@/mixins/shared/userInfo";

export default {
  mixins: [hitchhikeSteps, userInfo],
  name: "ChooseRoute",
  data() {
    return {};
  },
  created() {
    // console.log(this.getHitchhikeData)
  },
  methods: {
    selectRoute(selectedRoute) {
      let stringData = this.getHitchhikeData;
      stringData = JSON.stringify(stringData);
      let data = JSON.parse(stringData);
      data.routes.routes = [];
      data.routes.routes.push(selectedRoute);
      console.log(data);
      this.setHitchhike(3, data);
    },
    back() {
      this.setHitchhike(0, null);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/hitchhike";

.hitchhike-fields {
  width: 100%;
  height: calc(100vh - 77px);
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  overflow-y: scroll;
}

.card {
  width: 93%;
  border-radius: 25px;
  margin: 10px auto;
  -webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  -moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
  box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
}

.card .media:not(:last-child) {
  margin-bottom: 0;
}

.card-content:hover .media .media-left .user {
  background-color: #f6a328 !important;
}

.media {
  border: none;
  margin-top: 0;
}
</style>
