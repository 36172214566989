<template>
	<section>
		<div class="modal is-active">
			<div class="modal-background"></div>
			<div class="modal-card">
				<section class="modal-card-body">
					<button @click="closeModal()" class="delete" aria-label="close"></button>
					<p class=" modal-card-title title has-text-primary"><b>Editar Bem </b></p>
					<br />
					<div class="field email-field">
						<b-field class="file is-primary center-inside">
							<b-upload v-model="goodImg" class="file-label" accept="image/*">
								<div class="file-cta center-inside">
									<!--  <img
                    class="profile-photo"
                    v-if="userImg && !editFile"
                    :src="userImg"
                  />-->
									<img v-if="goodImgURL && !goodImg" :src="goodImgURL" />
									<img v-else-if="goodImg" :src="getFileURL" />
									<i v-else-if="!goodImg" class="file-icon mdi mdi-camera-outline mdi-48px"></i>
								</div>
							</b-upload>
						</b-field>
					</div>

					<div class="field email-field">
						<label class="label">Título</label>
						<div class="control has-icons-right">
							<input class="input is-success" :class="{ 'error-text': errors.has('title') }" type="text" v-validate="'required'" name="title" v-model="title" />
							<span class="icon is-small is-right">
								<i v-show="!errors.has('title') && title != ''" class="required-icon mdi mdi-check mdi-24px"></i>
							</span>
						</div>
						<p v-show="errors.has('title')" class="help is-danger">
							Introduza o título
						</p>
					</div>
					<div class="field email-field">
						<label class="label">Descrição</label>
						<div class="control has-icons-right">
							<textarea class="input is-success" type="text" maxlength="400" name="description" v-model="description" />
						</div>
						<small class="counter"> {{ description.length }} / 400 </small>
					</div>
					<div class="field email-field">
						<label class="label">Categoria</label>
						<div class="select control">
							<select v-validate="'required'" v-model="selectedGood" name="goods">
								<option v-for="good in goodsList" :value="good.id">{{ good.name_pt }}</option>
							</select>
							<p v-show="errors.has('goods')" class="help is-danger">
								Selecione uma categoria
							</p>
						</div>
					</div>
					<div  v-if="goodType !== 'donate' && goodType !== 'exchange'" class="field email-field">
						<label class="label">Preço (€)</label>
						<div class="control has-icons-right">
							<input class="input is-success" :class="{ 'error-text': errors.has('price') }" type="number" v-validate="'required'" name="price" v-model="price" />
							<span class="icon is-small is-right">
								<i v-show="!errors.has('price') && price != null" class="required-icon mdi mdi-check mdi-24px"></i>
							</span>
						</div>
						<p v-show="errors.has('price')" class="help is-danger">
							Introduza o preço
						</p>
						<br />
					</div>
					<div class="field email-field">
						<label class="label">Tipo</label>
						<div class="select control">
							<select v-validate="'required'" v-model="goodType" name="goods">
								<option value="sell">Vender</option>
								<option value="buy">Comprar</option>
								<option value="donate">Doar</option>
								<option value="exchange">Trocar</option>
							</select>
							<p v-show="errors.has('goods')" class="help is-danger">
								Selecione um tipo de bem
							</p>
						</div>
					</div>
					<br />
					<label class="radio">
						<input type="checkbox" v-validate="'required'" value="true" v-model="radioContact" name="answer" />
						Partilha de Contacto
					</label>
					<br />
					<br />
					<label class="radio">
						<input type="checkbox" value="true" v-model="radioAddress" name="answer" />
						Partilha de Morada
					</label>
					<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isButtonLoading }" @click="saveGood">Guardar</button>
				</section>
			</div>
		</div>
	</section>
</template>
<script>
	//API
	import { getGoodsType, updateGood } from '@/api/apiGoodsAndServices';

	export default {
		props: {
			isModalOpen: {
				type: Boolean,
				required: true,
			},
			good: {
				type: Object,
				required: true,
			},
		},
		name: 'EditModal',
		data() {
			return {
				isButtonLoading: false,
				modalState: this.isModalOpen,
				title: '',
				description: '',
				editFile: {},
				selectedGood: null,
				isLoading: false,
				goodImg: null,
				goodImgURL: null,
				price: null,
				radioContact: false,
				radioAddress: false,
				goodType: null,
				goodsList: null,
			};
		},
		created() {
			getGoodsType()
				.then(response => {
					console.log(response);
					response.data.sort((a, b) => (a.order_menu > b.order_menu ? 1 : -1));
					this.goodsList = response.data;
					this.title = this.good.title;
					this.description = this.good.description;
					this.selectedGood = this.good.article_type_id;
					this.price = this.good.price;
					this.radioContact = this.good.share_contact;
					this.radioAddress = this.good.share_location;
					this.goodImgURL = this.good.photo;
					this.goodType = this.good.transaction;
				})
				.catch(error => {});
		},
		computed: {
			getFileURL() {
				if (this.goodImg) {
					return window.URL.createObjectURL(this.goodImg);
				}
			},
		},
		methods: {
			closeModal(event) {
				this.$emit('clicked', false);
			},
			saveGood() {
				this.isButtonLoading = true;
				this.$validator.validateAll().then(result => {
					if (result) {
						let data = {
							id: this.selectedGood,
							title: this.title,
							description: this.description,
							price: this.price,
							share_contact: this.radioContact,
							share_location: this.radioAddress,
							transaction_type: this.goodType,
							...(this.goodImg != null && { photo: this.goodImg }),
						};
						updateGood(data, this.good.id)
							.then(response => {
								console.log(response);
								this.$buefy.toast.open({
									message: 'Anúncio atualizado com sucesso',
									position: 'is-top',
									type: 'is-primary',
								});
								this.isButtonLoading = false;
								this.closeModal();
							})
							.catch(error => {
								this.isButtonLoading = false;
								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
							});
					} else {
						this.isButtonLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';
	@import '../../../assets/scss/goodsAndServices';

	.auth-div {
		bottom: 5%;
	}

	.field {
		margin-bottom: 0;
	}

	.email-field {
		position: relative;
		margin-bottom: 40px;
	}

	.button {
		margin-top: 25px;
		margin-bottom: 5px;
	}

	.next-div {
		margin-top: 25px;
	}

	.register-steps > .step-1 {
		background: #1d4696;
	}

	.select-origin {
		color: #bac3dc !important;
	}

	.select,
	select {
		width: 100% !important;
	}

	.modal-card {
		border-radius: 20px;
		max-height: calc(100vh - 130px);
	}

	.delete {
		float: right;
	}

	.select,
	select {
		width: 100% !important;
	}

	.email-field {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.file-cta {
		width: 400px;
		height: 200px;
		padding: 0;
		background-color: #bac3dc !important;
	}

	.profile-photo {
		width: 120px;
		height: 120px;
	}
</style>
