<template>
	<section>
		<div class="notification-div">
			<p class="title has-text-primary"><b>Notificações</b></p>
			<div v-if="getAllNotifications.length <= 0">
				<br />
				<br />
				<br />
				<br />
				<div class=" center-inside">
					<img width="100" src="../assets/logos/logo.png" />
				</div>
				<br />
				<div class="center-inside">
					<p class=" has-text-primary">
						<b>Ainda não tem nenhuma notificação!</b>
					</p>
				</div>
			</div>
			<table v-else class="" style="width:100%">
				<tr v-for="(notification, index) in getAllNotifications" :key="index" :class="{ 'table-color': index % 2 }">
					<th>
						<div class="user center-inside">
							<img class="profile-photo" v-if="notification.image" :src="notification.image" />
							<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
						</div>
					</th>
					<th @click="redirect(notification.type, notification.url)">
						<p class="subtitle">{{ notification.text_pt }}</p>
						<div v-if="notification.type === 'follow'">
							<button class="button is-rounded is-primary" :class="{ active: notification.active }">
								<b @click="acceptFollow(JSON.parse(notification.meta_data).follow_id, 'accept', index)">aceitar</b>
							</button>
							<button
								@click="acceptFollow(JSON.parse(notification.meta_data).follow_id, 'decline', index)"
								:class="{ active: notification.active }"
								class="button is-rounded is-dark"
							>
								<b>recusar</b>
							</button>
						</div>

						<div v-if="notification.type === 'hitchike_request' && notification.meta_data !== null">
							<button class="button is-rounded is-primary" :class="{ active: notification.active }">
								<b @click="acceptHitchhike(notification.url.slice(notification.url.lastIndexOf('/') + 1), 'accept', index)">aceitar</b>
							</button>
							<button
								@click="acceptHitchhike(notification.url.slice(notification.url.lastIndexOf('/') + 1), 'decline', index)"
								class="button is-rounded is-dark"
								:class="{ active: notification.active }"
							>
								<b>recusar</b>
							</button>
						</div>
					</th>
					<th>
						<span class="notification-time">{{ $moment(notification.createdAt).fromNow() }}</span>
						<span v-if="!notification.read" class="unread"></span>
					</th>
				</tr>
			</table>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { followUser } from '@/mixins/follow/followUser';
	import { notifications } from '@/mixins/notifications/notifications';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { answerHitchhikeRequest } from '@/api/apiRides';
	import { answerFollowRequest, follow, deleteFollow } from '@/api/apiFollow';
	import { feedSteps } from '@/mixins/feed/feedSteps';

	export default {
		mixins: [followUser, notifications, goToPage, feedSteps],
		name: 'Notifications',
		data() {
			return {
				// showButtons: false
				sortedNotifications: null,
				isHidden: false,
			};
		},
		created() {
			this.$socket.emit('init', { token: this.$store.getters['auth/getToken'] });

			setTimeout(() => {
				let ids = [];
				for (let i = 0; i < this.getAllNotifications.length; i++) {
					ids.push(this.getAllNotifications[i].id);
				}
				this.$socket.emit('init', { token: this.$store.getters['auth/getToken'] });
				this.$socket.emit('chat_ask_unread', this.$store.getters['auth/getToken']);
				this.$socket.emit('notification_read_many', ids);
			}, 1000);
		},
		beforeDestroy() {
			let ids = [];
			for (let i = 0; i < this.getAllNotifications.length; i++) {
				ids.push(this.getAllNotifications[i].id);
			}

			let readNotification = this.getAllNotifications.map(notification => {
				console.log(notification.read);

				if (notification.read === false) {
					notification.read = true;
				}
			});

		
			this.$socket.emit('init', { token: this.$store.getters['auth/getToken'] });
			this.$socket.emit('chat_ask_unread', this.$store.getters['auth/getToken']);
			this.$socket.emit('notification_read_many', ids);
		},
		sockets: {
			notification(data) {
				const isArray = Object.prototype.toString.call(data) == '[object Array]';
				if (isArray) {
					/*  for (let i = 0; i < data.length; i++) {
	         data[i].createdAt = new Date(data[i].createdAt).getHours() + 'h' + new Date(data[i].createdAt).getMinutes()
	       } */
					/* this.setAllNotifications(data); */
				}
			},
		},
		methods: {
			acceptHitchhike(hitchhike_id, answer, index) {
				let item = this.getAllNotifications[index];
				item.active = !item.active;
				this.$set(this.getAllNotifications, index, item);

				answerHitchhikeRequest(hitchhike_id, answer)
					.then(response => {
						console.log(response);
						this.getAllNotifications.splice(index, 1);
					})
					.catch(error => {
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
			acceptFollow(follow_id, answer, index) {
				console.log(follow_id);
				let item = this.getAllNotifications[index];
				item.active = !item.active;
				this.$set(this.getAllNotifications, index, item);

				answerFollowRequest(follow_id, answer)
					.then(response => {
						this.getAllNotifications.splice(index, 1);
						this.$socket.emit('notification_read_many', ids);
					})
					.catch(error => {});
			},
			redirect(type, id) {
				console.log(type, id);
				console.log(id.slice(7));
				if (type === 'like') {
					this.setFeedInfo(2, id.slice(7));
					this.goToPage('/');
				} else if (type === 'flight_price') {
					console.log(id.slice(8));
					this.$router.push({ name: 'MyFlights', params: { id: id.slice(8) } });
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '../assets/scss/notifications';

	.active {
		visibility: hidden;
	}

	.title {
		font-size: 1.5rem;
		margin-top: 4px;
	}
</style>
