<template>
	<section>
		<div class="feed-list" @scroll="scroll">
			<waterfall :align="align" :line-gap="170">
				<!-- each component is wrapped by a waterfall slot -->
				<waterfall-slot v-for="(item, index) in feed" :width="100" :height="watterfallSlotHeight(item.type, index)" :order="index" :key="index">
					<div class="card" @click="seePostDetails(item.type, item.id, item.redirect_url)">
						<div
							class="card-content"
							:style="
								item.type === 'local_market_benefits_updated' ||
								item.type === 'article_added' ||
								item.type === 'service_added' ||
								item.type === 'car_trip_added' ||
								item.type === 'local_market_added'
									? 'background-color: #bac3dc; height: 60%'
									: `background-image:url(${item.media_url}); height: 75%`
							"
						>
							<Media v-if="item.media_type === 'video'" class="feed-video" :kind="'video'" :controls="false" :loop="true" :src="item.media_url"> </Media>
							<p
								class="benefit-update"
								v-show="
									item.type === 'local_market_benefits_updated' ||
										item.type === 'article_added' ||
										item.type === 'service_added' ||
										item.type === 'car_trip_added' ||
										item.type === 'local_market_added'
								"
								v-if="item.description.length > 30"
							>
								<i v-show="item.type === 'service_added'" class="mdi  mdi-layers-triple-outline mdi-18px"></i>
								<i v-show="item.type === 'car_trip_added'" class="mdi mdi-airplane mdi-18px"></i>
								<i v-show="item.type === 'article_added'" class="mdi  mdi-shopping-outline mdi-18px"></i>
								<i v-show="item.type === 'local_market_benefits_updated' || item.type === 'local_market_added'" class="mdi  mdi-home-outline mdi-18px"></i>
								{{ item.description }}
							</p>
							<p
								v-show="
									item.type === 'local_market_benefits_updated' ||
										item.type === 'article_added' ||
										item.type === 'service_added' ||
										item.type === 'car_trip_added' ||
										item.type === 'local_market_added'
								"
								class="benefit-update"
								v-else
							>
								<i v-show="item.type === 'service_added'" class="mdi  mdi-layers-triple-outline mdi-18px"></i>
								<i v-show="item.type === 'car_trip_added'" class="mdi mdi-airplane mdi-18px"></i>
								<i v-show="item.type === 'article_added'" class="mdi  mdi-shopping-outline mdi-18px"></i>
								<i v-show="item.type === 'local_market_benefits_updated'" class="mdi  mdi-home-outline mdi-18px"></i>
								<i v-show="item.type === 'local_market_added'" class="mdi  mdi-home-outline mdi-18px"></i>
								{{ item.description }}
							</p>
						</div>
						<div class="media">
							<div class="media-left">
								<div v-if="item.user.emigrant !== null" class="user center-inside">
									<img class="profile-photo" v-if="item.user.emigrant.photo !== null" :src="item.user.emigrant.photo" />

									<i v-else class="file-icon mdi mdi-camera-outline mdi-18px"></i>
								</div>

								<div v-else class="user center-inside">
									<img class="profile-photo" v-if="item.user.local_market.photo !== null" :src="item.user.local_market.photo" />
									<i v-else class="file-icon mdi mdi-camera-outline mdi-18px"></i>
								</div>
							</div>

							<div class="media-content">
								<div v-if="item.user.emigrant !== null">
									<span class="name" v-if="item.user.emigrant.name.length < 9">{{ item.user.emigrant.name }}</span>
									<span class="name" v-else>{{ item.user.emigrant.name.substring(0, 9) + '...' }}</span>
									<p class="createdAt">
										{{ $moment(item.updatedAt).fromNow() }}
									</p>
								</div>
								<div v-else>
									<span class="name" v-if="item.user.local_market.name.length < 9">{{ item.user.local_market.name }}</span>
									<span class="name" v-else>{{ item.user.local_market.name.substring(0, 9) + '...' }}</span>
									<p class="createdAt">
										{{ $moment(item.updatedAt).fromNow() }}
									</p>
								</div>
							</div>
							<div class="media-right">
								<div class="user-follow center-inside">
									<i
										v-if="item.likes.length > 0"
										class="file-icon-like mdi mdi-heart mdi-18px"
										@click="dislikePost(item.likes[0].post_id, item.likes[0].id, index)"
									></i>
									<i v-else @click="likePost(item.id, index)" class="file-icon-dislike mdi mdi-heart-outline mdi-18px"></i>
								</div>
							</div>
						</div>
					</div>
				</waterfall-slot>
			</waterfall>
			<div style="height:20px"></div>
		</div>
	</section>
</template>

<script>
	import { methods } from 'qrcode.vue';
	import Waterfall from 'vue-waterfall/lib/waterfall';
	import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot';
	import { getFeed, userLikePost, userDislikePost } from '@/api/apiFeed';
	import { feedSteps } from '@/mixins/feed/feedSteps';

	export default {
		mixins: [feedSteps],
		name: 'FeedTiles',
		components: {
			Waterfall,
			WaterfallSlot,
		},
		data() {
			return {
				heights: [],
				align: 'center',
				feed: [],
				like: [],
				seeDetails: true,
				bottom: false,
				offset: 0,
				width: 0,
				limit: 0,
			};
		},
		methods: {
			scroll(e) {
				const { target } = e;
				if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
					//this code will run when the user scrolls to the bottom of this div so
					//you could do an api call here to implement lazy loading
					this.bottom = true;
					if (this.bottom) {
						if (this.width <= 768) {
							this.offset = this.offset + 10;
						} else this.offset = this.offset + 30;
						this.updateFeed(this.limit, this.offset);
					}
				}
			},
			generateArray() {
				for (let index = 0; index <= this.feed.length; index++) {
					let randomNumber = Math.random() * (180 - 140) + 140;
					this.heights.push(randomNumber);
				}
			},

			likePost(postId, index) {
				this.seeDetails = false;
				userLikePost(postId)
					.then(response => {
						this.feed[index].likes.push(response.data.body);
					})
					.catch(error => {});
			},

			dislikePost(postId, likeId, index) {
				this.seeDetails = false;
				userDislikePost(postId, likeId)
					.then(response => {
						this.feed[index].likes.pop();
					})
					.catch(error => {});
			},

			seePostDetails(type, postId, redirect) {
				console.log(type);
				if (this.seeDetails === true) {
					if (type === 'local_market_benefits_updated') {
						this.$router.push(redirect);
					} else if (type === 'article_added') {
						this.$router.push(redirect);
					} else if (type === 'service_added') {
						this.$router.push(redirect);
					} else if (type === 'post') {
						this.setFeedInfo(2, postId);
					} else if (type === 'local_market_added') {
						this.$router.push(redirect);
					}
				} else this.seeDetails = !this.seeDetails;
			},

			updateFeed(limit, offset) {
				getFeed(limit, offset)
					.then(response => {
						this.feed.push(...response.data);
						this.generateArray();
					})
					.catch(error => {});
			},

			watterfallSlotHeight(type, index) {
				if (type === 'post') {
					return this.heights[index];
				} else return 120;
			},

			handleResize() {
				this.width = window.innerWidth;
			},
		},

		created() {
			window.addEventListener('resize', this.handleResize);
			this.handleResize();
			if (this.width <= 768) {
				this.limit = 10;
			} else this.limit = 30;
			this.updateFeed(this.limit, this.offset);
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize);
		},
	};
</script>

<style scoped lang="scss">
	@import '../../assets/scss/feed';
</style>
