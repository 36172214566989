<template>
	<section>
		<div class="auth-div">
			<div v-for="(post, index) in userPosts" :key="index">
				<div class="card" @click="edit(post.id)" v-if="post.media_type === 'image' || post.media_type === 'video'">
					<div v-if="post.media_type === 'image'" class="card-image" :style="{ 'background-image': `url(${post.media_url})` }" />
					<Media v-else-if="post.media_type === 'video'" class="feed-video" :kind="'video'" :controls="false" :loop="true" :src="post.media_url"> </Media>
					<p class="description">{{ post.description }}</p>
					<p v-if="post.likes.length !== 1" class="likes has-text-primary ">{{ post.likes.length }} gostos</p>
					<p v-else class="likes has-text-primary ">{{ post.likes.length }} gosto</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { getPosts } from '@/api/apiProfile';
	import moment from 'moment';
	export default {
		data() {
			return {
				userPosts: [],
			};
		},

		methods: {
			edit(postId) {
				console.log(postId);
				this.$router.push({ name: 'EditPost', params: { id: postId } });
			},
		},
		mounted() {
			getPosts()
				.then(response => {
					console.log(response.data);
					let sortedArray = response.data.sort((a, b) => moment(a.createdAt).format('YYYYMMDD') - moment(b.createdAt).format('YYYYMMDD'));
					this.userPosts = sortedArray.reverse();
				})
				.catch(error => {
					console.log(error);
				});
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';

	.auth-div {
		margin-top: 150px;
	}

	.card {
		border-radius: 26px;
		box-shadow: 1px 0px 33px -8px #a39ba3;
		width: 100%;
		background-color: #f1f1f1;
		text-align: left !important;
		font-size: 12px;
		margin-bottom: 16px;
		color: black;
	}

	.card-image {
		justify-content: center;
		display: flex;
		height: 175px;
		border-radius: 25px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.description {
		padding: 8px;
	}

	.likes {
		text-align: right;
		padding: 12px;
	}

	@media screen and (min-width: 768px) {
		.card {
			width: 300px;
			margin: 0 auto;
			margin-bottom: 16px;
		}
	}
</style>
