import { get, put, formData, formDataPut, post, del } from '@/utils/http';

export function getUsers() {
	return get('/emigrants/');
}

export function getTickets() {
	return get('/supports/');
}

export function updateTicketStatus(id, status) {
	return put('/supports/' + id, {
		status: status,
	});
}

export function deactivateAccount(id) {
	return put('/users/' + id + '/deactivate');
}

export function activateAccount(id) {
	return put('/users/' + id + '/activate');
}

export function getLocalMarkets() {
	return get('/localmarkets');
}

export function getLocalMarketsAreas() {
	return get('/localmarketareas');
}

export function createLocalMarket(data) {
	console.log(data);

	let form = new FormData();
	Object.keys(data.days).forEach(day => {
		data.days[day].forEach((d, index) => {
			form.append('days[' + day + '][' + index + '][close]', d.close);
			form.append('days[' + day + '][' + index + '][isOpen]', d.isOpen);
			form.append('days[' + day + '][' + index + '][id]', d.id);
			form.append('days[' + day + '][' + index + '][open]', d.open);
		});
	});

	for (let i = 0; i < data.benefits.length; i++) {
		let benefit = data.benefits[i];
		form.append('benefits[' + i + '][type]', benefit.type);
		form.append('benefits[' + i + '][value]', benefit.value);
		form.append('benefits[' + i + '][description]', benefit.description);
		form.append('benefits[' + i + '][color]', benefit.color);
		form.append('benefits[' + i + '][featured]', benefit.featured);
		form.append('benefits[' + i + '][multiple]', benefit.multiple);
		if (benefit.validity) {
			form.append('benefits[' + i + '][validity]', benefit.validity);
		}
	}

	form.append('name', data.name);
	form.append('email', data.email);
	form.append('zip_code', data.zip_code);
	form.append('country', data.country);
	form.append('local_market_area_id', data.local_market_area_id);
	if (data.description) {
		form.append('description', data.description);
	}
	if (data.phone) {
		form.append('phone', data.phone);
	}
	if (data.photo) {
		form.append('photo', data.photo);
	}
	if (data.site) {
		form.append('url', data.site);
	}

	return formData('/localmarkets', form);
}

export function updateLocalMarket(data, id) {
	console.log(data.days);

	let form = new FormData();
	Object.keys(data.days).forEach(day => {
		data.days[day].forEach((d, index) => {
			form.append('days[' + day + '][' + index + '][close]', d.close);
			form.append('days[' + day + '][' + index + '][isOpen]', d.isOpen);
			form.append('days[' + day + '][' + index + '][id]', d.id);
			form.append('days[' + day + '][' + index + '][open]', d.open);
		});
	});

	for (let i = 0; i < data.benefits.length; i++) {
		let benefit = data.benefits[i];
		if (benefit.action != 'new') {
			form.append('benefits[' + i + '][id]', benefit.id);
		}
		form.append('benefits[' + i + '][type]', benefit.type);
		form.append('benefits[' + i + '][value]', benefit.value);
		form.append('benefits[' + i + '][description]', benefit.description);
		form.append('benefits[' + i + '][color]', benefit.color);
		form.append('benefits[' + i + '][featured]', benefit.featured);
		form.append('benefits[' + i + '][action]', benefit.action);
		form.append('benefits[' + i + '][multiple]', benefit.multiple);
		console.log(benefit.featured);
		if (benefit.validity) {
			form.append('benefits[' + i + '][validity]', benefit.validity);
		}
	}

	form.append('name', data.name);
	form.append('email', data.email);
	form.append('zip_code', data.zip_code);
	form.append('country', data.country);
	form.append('local_market_area_id', data.local_market_area_id);
	if (data.description) {
		form.append('description', data.description);
	}
	if (data.phone) {
		form.append('phone', data.phone);
	}
	if (data.photo) {
		form.append('photo', data.photo);
	}
	if (data.site) {
		form.append('url', data.site);
	}

	return formDataPut('/localmarkets/' + id, form);
}

export function createCategory(data) {
	const params = new URLSearchParams();

	params.append('name_pt', data.name_pt);
	params.append('name_en', data.name_en);
	params.append('icon', 'mdi-pencil-outline');

	return post('/localmarketareas', params);
}

export function getAllPosts() {
	return get('/posts');
}
export function deletePost(id) {
	return del('/posts/' + id);
}

export function getAllGoods() {
	return get('/articles?status=open');
}

export function removeGood(id) {
	return del('/articles/' + id);
}

export function getAllServices() {
	return get('/services');
}

export function removeService(id) {
	return del('/services/' + id);
}

export function getAllCarTrips() {
	return get('/cartrips');
}

export function removeCarTrip(id) {
	return del('/cartrips/' + id);
}

export function deleteUser(id) {
	return del('/users/' + id);
}

