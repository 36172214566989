import 'firebase/auth';
import { registerPlugin } from '@capacitor/core';
import firebase from 'firebase/app';
import { Observable, throwError } from 'rxjs';
export const CapacitorFirebaseAuth = registerPlugin('CapacitorFirebaseAuth', {
	web: () => import('capacitor-firebase-auth/dist/esm/web').then(m => new m.CapacitorFirebaseAuthWeb()),
});
const plugin = CapacitorFirebaseAuth;

let _facades = require('capacitor-firebase-auth/dist/esm/facades');
let facades = Object.assign({}, _facades);

facades.cfaSignInGoogle = function() {
	return new Observable(observer => {
		// get the provider id
		const providerId = firebase.auth.GoogleAuthProvider.PROVIDER_ID;
		// native sign in
		plugin
			.signIn({ providerId })
			.then(result => {
				// create the credentials
				const credential = firebase.auth.GoogleAuthProvider.credential(result.idToken);
				// web sign in
				firebase
					.app()
					.auth()
					.signInWithCredential(credential)
					.then(userCredential => {
						if (!userCredential.user) {
							throw new Error('Firebase User was not received.');
						}

						observer.next(userCredential);

						observer.complete();
					})
					.catch(reject => {
						observer.error(reject);
					});
			})
			.catch(reject => {
				observer.error(reject);
			});
	});
};

facades.cfaSignInTwitter = () => {
	return new Observable(observer => {
		// get the provider id
		const providerId = firebase.auth.TwitterAuthProvider.PROVIDER_ID;
		// native sign in
		plugin
			.signIn({ providerId })
			.then(result => {
				// create the credentials
				const credential = firebase.auth.TwitterAuthProvider.credential(result.idToken, result.secret);
				// web sign in
				firebase
					.app()
					.auth()
					.signInWithCredential(credential)
					.then(userCredential => {
						if (!userCredential.user) {
							throw new Error('Firebase User was not received.');
						}
						observer.next(userCredential);
						observer.complete();
					})
					.catch(reject => observer.error(reject));
			})
			.catch(reject => observer.error(reject));
	});
};

facades.cfaSignInFacebook = () => {
	return new Observable(observer => {
		// get the provider id
		const providerId = firebase.auth.FacebookAuthProvider.PROVIDER_ID;
		// native sign in
		plugin
			.signIn({ providerId })
			.then(result => {
				// create the credentials
				const credential = firebase.auth.FacebookAuthProvider.credential(result.idToken);
				// web sign in
				firebase
					.app()
					.auth()
					.signInWithCredential(credential)
					.then(userCredential => {
						if (!userCredential.user) {
							throw new Error('Firebase User was not received.');
						}
						observer.next(userCredential);
						observer.complete();
					})
					.catch(reject => observer.error(reject));
			})
			.catch(reject => observer.error(reject));
	});
};

export default facades;
