import { PushNotifications } from '@capacitor/push-notifications';
import { addToken } from '@/api/apiNotificationToken';
import VueInstance from '@/main';

export const notifications = {
	computed: {
		getAllNotifications() {
			return this.$store.getters['notifications/getNotifications'];
		},
		getChatNotifications() {
			return this.$store.getters['notifications/getChatNotifications'];
		},
	},
	methods: {
		setAllNotifications(notifications) {
			this.$store.commit('notifications/setNotifications', notifications);
		},
		setChatNotifications(unreadCount) {
			this.$store.commit('notifications/setChatNotifications', unreadCount);
		},
	},
};

export function startPushedNotifications() {
	PushNotifications.requestPermissions().then(result => {
		if (result.receive === 'granted') {
			PushNotifications.register();
		}
	});

	PushNotifications.addListener('registration', async token => {
		await addToken(token.value)
			// .then(res => {
			// 	alert('token added');
			// })
			// .catch(error => {
			// 	alert('error in api');
			// 	console.log('ERRORINAPI ', JSON.stringify(error.response));
			// });
	});

	// PushNotifications.addListener('registrationError', error => {
	// 	alert('Error on registration: ' + JSON.stringify(error));
	// });

	// PushNotifications.addListener('pushNotificationReceived', notification => {
	// 	alert('Push received: ' + JSON.stringify(notification));
	// });

	PushNotifications.addListener('pushNotificationActionPerformed', notification => {
		if (notification.actionId == 'tap') {
			let url = notification.notification.data.url;
			VueInstance.$router.push(url);
		}
		// console.log(JSON.stringify(notification));
		// alert('Push action performed: ' + JSON.stringify(notification));
	});
}
