<template>
	<section>
		<div class="tabs is-toggle center-inside is-toggle-rounded">
			<div class="tabs-shadow">
				<ul>
					<li @click="activeTab = 0" :class="{ 'is-active': activeTab === 0 }">
						<a>
							<span><b>Publicações</b></span>
						</a>
					</li>
					<li @click="activeTab = 1" :class="{ 'is-active': activeTab === 1 }">
						<a class="middle-tab">
							<span><b>Bens</b></span>
						</a>
					</li>
					<li @click="activeTab = 2" :class="{ 'is-active': activeTab === 2 }">
						<a>
							<span><b>Serviços</b></span>
						</a>
					</li>
					<li @click="activeTab = 3" :class="{ 'is-active': activeTab === 3 }">
						<a>
							<span><b>Boleias</b></span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<ViewPosts v-if="activeTab === 0" />
		<ViewGoods v-if="activeTab === 1" />
		<ViewServices v-if="activeTab === 2" />
		<ViewCarTrips v-if="activeTab === 3" />
	</section>
</template>

<script>
	import ViewPosts from '@/components/Backoffice/ViewAllPosts.vue';
	import ViewGoods from '@/components/Backoffice/ViewAllGoods.vue';
	import ViewServices from '@/components/Backoffice/ViewAllServices.vue';
    import ViewCarTrips from '@/components/Backoffice/ViewAllCarTrips.vue'
	export default {
		data() {
			return {
				activeTab: 0,
			};
		},
		components: {
			ViewPosts,
			ViewGoods,
			ViewServices,
            ViewCarTrips
		},
	};
</script>

<style scoped lang="scss">
	@import '../../assets/scss/hitchhike';

	.tabs.is-toggle.is-toggle-rounded li:first-child a {
		border-radius: 20px;
	}

	.tabs.is-toggle.is-toggle-rounded li:last-child a {
		border-radius: 20px;
	}

	.tabs-shadow {
		/* border-radius */
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		/* box-shadow */
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.tabs.is-toggle a {
		border: none;
		color: #1d4696;
	}

	.tabs {
		padding: 10px;
	}

	.tabs.is-toggle li.is-active a {
		color: #1d4696;
	}

	.middle-tab {
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
	}

	.content ul {
		list-style: disc outside;
		margin-left: 0;
		margin-top: 0;
	}
</style>
