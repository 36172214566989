<template>
	<section>
		<div class="logo center-inside">
			<img width="140" src="../../../assets/logos/logo.png" />
		</div>
		<div class="auth-div">
			<p class="title has-text-primary"><b>Atualizar Password</b></p>
			<div class="field email-field">
				<label class="label">Nova Password</label>
				<div class="control">
					<b-input
						type="password"
						name="password"
						ref="password"
						:class="{ 'error-text': errors.has('password') }"
						v-validate="'required|min:8'"
						v-model="recover_password"
						password-reveal
					>
					</b-input>
				</div>
				<p v-show="errors.has('password')" class="help is-danger">A password é demasiado pequena</p>
        <br>
				<p v-if="passwordError" class="help is-danger">
					A password deve conter números e letras
				</p>
			</div>
			<div class="field">
				<label class="label">Confirmar Password</label>
				<div class="control">
					<b-input
						type="password"
						v-validate="'required|confirmed:password|min:8'"
						name="password_confirmation"
						data-vv-as="password"
						:class="{ 'error-text': errors.has('password_confirmation') }"
						v-model="confirm_recover_password"
						password-reveal
					>
					</b-input>
				</div>
				<p v-show="errors.has('password_confirmation')" class="help is-danger">As passwords não coincidem</p>
			</div>
			<div class="auth-buttons has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isLoading }" @click="changePassword"><b>Alterar</b></button>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { goToPage } from '@/mixins/shared/goToPage';
	//API
	import { recoverPassword } from '@/api/apiRegister';

	export default {
		mixins: [goToPage],
		name: 'Forgot',
		data() {
			return {
				recover_password: '',
				confirm_recover_password: '',
				isLoading: false,
				passwordError: false,
			};
		},
		methods: {
			changePassword() {
				this.isLoading = true;
				if (this.recover_password.search(/\d/) == -1) {
					this.passwordError = true;
				} else if (this.recover_password.search(/[a-zA-Z]/) == -1) {
					this.passwordError = true;
				} else this.passwordError = false;
        
				this.$validator.validateAll().then(result => {
					if (result && !this.passwordError) {
						let data = {
							password: this.recover_password,
						};
						recoverPassword(this.$route.query.code, data)
							.then(response => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: 'Password alterada!',
									position: 'is-top',
									type: 'is-primary',
								});
								this.$router.push('/login');
							})
							.catch(error => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: error.response.data.message.pt,
									position: 'is-top',
									type: 'is-danger',
								});
								this.$router.push('/');
							});
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';

	.auth-div {
		bottom: 10%;
	}

	.email-field {
		margin-bottom: 30px;
	}

	.button {
		margin-top: 40px;
		margin-bottom: 10px;
	}
</style>
