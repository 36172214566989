<template>
	<section>
		<div class="auth-div">
			<div class=" center-inside">
				<img width="140" src="../../assets/logos/logo.png" />
			</div>
			<p class="title has-text-primary mt-4"><b>Confirmação de Morada </b></p>
			<div>
				<p class="label-text">
					Só falta mais 1 passo...Para utilizares livremente a tua conta e.migra confirma que resides em:
				</p>
				<hr />
				<p class="label-text">
					<b>{{ userData.info.destination_formatted_address }}</b>
				</p>
				<hr />
			</div>
			<button class="button is-rounded is-primary is-fullwidth" :class="{ 'is-loading': isLoading }" :disabled="latLng === null" @click="confirmLocation">
				Confirmar
			</button>
			<br />
			<button class="button is-rounded is-danger is-fullwidth" :disabled="latLng === null" @click="goToPage('/')">
				Agora não
			</button>
		</div>
	</section>
</template>

<script>
	//API
	import { confirmGeoLocation } from '@/api/apiLocation';
	//Mixins
	import { userInfo } from '@/mixins/shared/userInfo';
	import { goToPage } from '@/mixins/shared/goToPage';

	export default {
		mixins: [userInfo, goToPage],
		name: 'ConfirmLocation',
		data() {
			return {
				latLng: null,
				isLoading: false,
				accuracy: null,
				options: {
					maximumAge: 60000,
					timeout: 5000,
					enableHighAccuracy: true,
				},
			};
		},
		mounted() {
			navigator.geolocation.getCurrentPosition(
				position => {
					console.log(position);
					this.latLng = position.coords.latitude + ', ' + position.coords.longitude;
					this.accuracy = position.coords.accuracy;
					console.log(position.coords.latitude);
					console.log(position.coords.longitude);
				},
				error => {
					console.log(error.message);
				},
				this.options
			);
		},

		methods: {
			confirmLocation() {
				this.isLoading = true;
				confirmGeoLocation(this.latLng, this.accuracy)
					.then(response => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: 'Localização confirmada com sucesso',
							position: 'is-top',
							type: 'is-primary',
						});
						this.$router.push('/');
						commit('setConfirmAddress', true);
					})
					.catch(error => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: error.response.data.message.pt,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/auth';

	.auth-div {
		position: relative !important;
		padding-top: 30px;
		padding-bottom: 20px;
		@media screen and (min-width: 768px) {
			padding-top: 10%;
		}
	}

	.logo {
		top: 15vh;
	}

	.label-text {
		color: #1d4696;
		justify-content: center;
		font-size: 0.9rem;
	}

	.title {
		font-size: 1.5rem;
	}
</style>
