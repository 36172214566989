<template>
	<div>
		<div class="filter">
			<label class="radio">Todos</label>
			<input type="radio" v-model="filterOption" value="all" @click="updateData()" />

			<label class="radio">Ativos </label>
			<input type="radio" v-model="filterOption" :value="true" @click="filterData(true)" />

			<label class="radio">Desativos </label>
			<input type="radio" v-model="filterOption" :value="false" @click="filterData(false)" />
		</div>
		<div class="add-container">
			<b-button button class="add" @click="createLocalMarket()"> <i class="icon mdi mdi-plus mdi-18px"></i></b-button>
		</div>
		<b-table :data="filteredData" :row-class="(row, index) => row.name === 'Negócio local apagado' ? 'is-hidden' : ''">
			<b-table-column >
				<template v-slot="props">
					<div class="mobile" >
						<div class="file-cta center-inside">
							<img class="profile-photo" v-if="props.row.photo" :src="props.row.photo" />

							<i v-else class="file-icon mdi mdi-camera-outline mdi-48px"></i>
						</div>
					</div>
				</template>
			</b-table-column>
			<template v-for="column in columns">
				<b-table-column :key="column.id" v-bind="column">
					<template v-if="column.searchable && !column.numeric" #searchable="props">
						<b-input v-model="props.filters[props.column.field]" placeholder="Pesquisa" icon="magnify" size="is-small" />
					</template>
					<template v-slot="props">
						{{ props.row[column.field] }}
					</template>
				</b-table-column>
			</template>

			<b-table-column v-slot="props">
				<b-button
					rounded
					class="edit-account"
					@click="
						editLocalMarket(
							props.row.id,
							props.row.name,
							props.row.user.email,
							props.row.phone,
							props.row.description,
							props.row.country,
							props.row.zip_code,
							props.row.url,
							props.row.local_market_area_id,
							props.row.schedules,
							props.row.benefits,
							props.row.photo
						)
					"
					>Editar Negócio</b-button
				>
			</b-table-column>
			<b-table-column v-slot="props">
				<b-button rounded class="deactivate-account" v-if="props.row.user.active === true" @click="deactivate(props.row.user.id)">Desativar Conta</b-button>
				<b-button rounded v-else class="deactivate-account-disabled" disabled>Desativar Conta</b-button>
			</b-table-column>
			<b-table-column v-slot="props">
				<b-button rounded class="deactivate-account" @click="deleteAccount(props.row.user_id, props.index)">Eliminar Conta</b-button>
			</b-table-column>
		</b-table>
		<MarketModal v-if="isModalOpen" :isModalOpen="isModalOpen" @clicked="closeModal"></MarketModal>

		<EditModal
			v-if="isEditModalOpen"
			:isModalOpen="isEditModalOpen"
			@clicked="closeEditModal"
			:id="localMarketId"
			:localName="localMarketName"
			:email="localMarketEmail"
			:phone="localMarketPhone"
			:description="localMarketDescription"
			:country="localMarketCountry"
			:zipCode="localMarketZipCode"
			:url="localMarketUrl"
			:sector="localMarketSector"
			:hours="localMarketHours"
			:benefits="localMarketBenefits"
			:photo="localMarketPhoto"
			@updateData="updateData"
		></EditModal>
	</div>
</template>

<script>
	import MarketModal from '../Backoffice/LocalMarketModal';
	import EditModal from '../Backoffice/LocalMarketEditModal';

	import { getLocalMarkets, activateAccount, deactivateAccount, deleteUser } from '@/api/apiBackOffice';

	export default {
		components: {
			MarketModal,
			EditModal,
		},
		data() {
			return {
				data: [],
				filteredData: [],
				id: '',
				localMarketId: null,
				localMarketName: null,
				localMarketPhone: null,
				localMarketDescription: null,
				localMarketCountry: null,
				localMarketZipCode: null,
				localMarketUrl: null,
				localMarketSector: null,
				localMarketEmail: null,
				localMarketHours: {},
				localMarketBenefits: null,
				localMarketPhoto: null,
				columns: [
					{
						field: 'name',
						label: 'Nome do negócio',
						width: '300',
						searchable: true,
					},
					{
						field: 'phone',
						label: 'Contacto',
						searchable: false,
					},
					{
						field: 'formatted_address',
						label: 'Morada',
						width: '300',
						searchable: false,
					},
				],
				isModalOpen: false,
				isEditModalOpen: false,
				filterOption: 'all',
			};
		},
		methods: {
			createLocalMarket() {
				this.$router.push('/createlocalmarket');
			},

			editLocalMarket(id, name, email, phone, description, country, zipCode, url, area, schedules, benefits, photo) {
				this.$router.push({
					name: 'EditLocalMarket',
					params: {
						id: id,
						name: name,
						email: email,
						phone: phone,
						description: description,
						country: country,
						zip: zipCode,
						url: url,
						area: area,
						schedules: schedules,
						benefits: benefits,
						photo: photo,
					},
				});
			},

			openModal() {
				this.isModalOpen = true;
			},
			closeModal(value) {
				this.isModalOpen = value;
			},

			openEditModal(id, name, email, phone, description, country, zipCode, url, sector, hours, benefits, photo) {
				this.localMarketId = id;
				this.localMarketName = name;
				this.localMarketEmail = email;
				this.localMarketPhone = phone;
				this.localMarketDescription = description;
				this.localMarketCountry = country;
				this.localMarketZipCode = zipCode;
				this.localMarketUrl = url;
				this.localMarketSector = sector;
				this.localMarketHours = hours;
				this.localMarketBenefits = benefits;
				this.localMarketPhoto = photo;
				this.isEditModalOpen = true;
			},
			closeEditModal(value) {
				this.isEditModalOpen = value;
			},

			deactivate(id) {
				deactivateAccount(id)
					.then(response => {
						console.log(response);
					})
					.catch(error => {
						console.log(error);
					});
			},

			activate(id) {
				activateAccount(id)
					.then(response => {
						console.log(response);
						getUsers()
							.then(response => {
								console.log(response.data);
								this.data = response.data;
							})
							.catch(error => {});
					})
					.catch(error => {
						console.log(error);
					});
			},
			updateData() {
				getLocalMarkets()
					.then(response => {
						console.log(response.data);
						this.data = response.data;
						this.filteredData = this.data;
					})
					.catch(error => {});
			},

			filterData(filter) {
				this.filteredData = this.data.filter(data => data.user.active === filter);
				console.log(this.filteredData);
			},

			deleteAccount(id, index) {
				let self = this;
				this.$buefy.dialog.confirm({
					title: 'Eliminar Negócio Local',
					canCancel: ['button'],
					message: '<p style="color: #1d4696">Tens a certeza que pretendes eliminar este negócio local?</p>',
					cancelText: 'Confirmar',
					confirmText: 'Não',
					type: 'is-primary',
					onCancel() {
						deleteUser(id)
							.then(response => {
								self.filteredData.splice(index, 1);
								self.$buefy.toast.open({
									message: 'Negócio Local apagado com sucesso',
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: 'Ocurreu um erro ao apagar o negócio local',
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
				});
			},
		},
		mounted() {
			this.updateData();
		},
	};
</script>
<style lang="scss" scoped>
	@import '../../assets/scss/backofficeLocalMarket';

	.radio {
		margin-right: 15px;
		color: $primary;
	}

	input[type='radio'] {
		margin-right: 20px;
	}

	input[type='radio']:after {
		width: 25px;
		height: 25px;
		border-radius: 15px;
		top: -7px;
		left: -6px;
		position: relative;
		background-color: white;
		content: '';
		display: inline-block;
		visibility: visible;
		border: 1px solid $dark;
	}

	input[type='radio']:checked:before {
		width: 25px;
		height: 25px;
		border-radius: 15px;
		top: -7px;
		left: -6px;
		position: relative;
		background-color: white;
		content: '';
		display: inline-block;
		visibility: visible;
		border: 1px solid $dark;
	}

	input[type='radio']:checked:after {
		width: 15px;
		height: 15px;
		border-radius: 20px;
		top: -32px;
		left: -1px;
		position: relative;
		background-color: $primary;
		content: '';
		display: inline-block;
		visibility: visible;
		border: 4px solid white;
	}

	.filter {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}
</style>
