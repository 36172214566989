<template>
	<section>
		<div class="hitchhike-fields">
			<div class="field email-field">
				<label class="label">De</label>
				<div class="select control">
					<select v-validate="'required'" v-model="departureAddress" name="departureAddress">
						<option v-if="arrivalAddress !== originInfo" :value="originInfo">{{ originInfo.formatted_address }}</option>
						<option v-if="arrivalAddress !== destinationInfo" :value="destinationInfo">{{ destinationInfo.formatted_address }}</option>
					</select>
					<p v-show="errors.has('departureAddress')" class="help is-danger">Selecione um ponto de partida</p>
				</div>
			</div>
			<div>
				<i class="mdi mdi-swap-vertical mdi-24px" @click="swapPlaces" style="margin-right:10px"></i>
			</div>
			<div class="field email-field">
				<label class="label">Para</label>
				<div class="select control">
					<select v-validate="'required'" v-model="arrivalAddress" name="arrivalAddress">
						<option v-if="departureAddress !== originInfo" :value="originInfo">{{ originInfo.formatted_address }}</option>
						<option v-if="departureAddress !== destinationInfo" :value="destinationInfo">{{ destinationInfo.formatted_address }}</option>
					</select>
					<p v-show="errors.has('arrivalAddress')" class="help is-danger">Selecione um ponto de chegada</p>
				</div>
			</div>
			<br />
			<div class="field email-field">
				<vc-date-picker :min-date="new Date()" v-model="departureDate" />
			</div>

			<div class="field email-field">
				<label class="label">Hora</label>
				<div>
					<b-timepicker v-model="hitchhikeTime" name="hitchhikeTime" hour-format="24" v-validate="'required'"> </b-timepicker>
					<p v-show="errors.has('hitchhikeTime')" class="help is-danger">Selecione a hora da viagem</p>
				</div>
			</div>
			<br />
			<div class="next-div has-text-centered">
				<button class="button is-rounded is-primary is-fullwidth" @click="createTrip" :class="{ 'is-loading': isLoading }">
					Seguinte
				</button>
			</div>

			<div class="register-steps center-inside is-fullwidth">
				<div class="step"></div>
				<div></div>
				<div></div>
			</div>
			<br />
		</div>
	</section>
</template>

<script>
	//Mixins
	import { hitchhikeSteps } from '@/mixins/hitchhikes/hitchhikeSteps';
	/*  import {userInfo} from '@/mixins/shared/userInfo' */
	import moment from 'moment';

	export default {
		name: 'OfferHitchhike',
		mixins: [hitchhikeSteps],
		props: {
			userData: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				carUserSeats: [],
				hitchhikePlaces: 1,
				departureAddress: null,
				arrivalAddress: null,
				departureDate: new Date(),
				isLoading: false,
				originInfo: null,
				destinationInfo: null,
				hitchhikeTime: null,
				todayDate: new Date(),
			};
		},
		created() {
			console.log(this.userData.info);
			this.originInfo = {
				country: this.userData.info.origin_country,
				zip_code: this.userData.info.origin_zip_code,
				formatted_address: this.userData.info.origin_formatted_address,
			};
			this.destinationInfo = {
				country: this.userData.info.destination_country,
				zip_code: this.userData.info.destination_zip_code,
				formatted_address: this.userData.info.destination_formatted_address,
			};
		},

		methods: {
			swapPlaces() {
				let arrivalTochange;
				arrivalTochange = this.arrivalAddress;
				this.arrivalAddress = this.departureAddress;
				this.departureAddress = arrivalTochange;
			},
			formatDate(date) {
				let d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();

				if (month.length < 2) month = '0' + month;
				if (day.length < 2) day = '0' + day;
				return [year, month, day].join('-');
			},

			createTrip() {
				console.log(this.departureAddress);
				let is_emigrant_origin_to_destination =
					this.departureAddress.zip_code + this.departureAddress.country == this.userData.info.origin_zip_code + this.userData.info.origin_country;

				let departure_latitude, departure_longitude, arrival_latitude, arrival_longitude;

				if (is_emigrant_origin_to_destination) {
					departure_latitude = this.userData.info.origin_latitude;
					departure_longitude = this.userData.info.origin_longitude;
					arrival_latitude = this.userData.info.destination_latitude;
					arrival_longitude = this.userData.info.destination_longitude;
				} else {
					departure_latitude = this.userData.info.destination_latitude;
					departure_longitude = this.userData.info.destination_longitude;
					arrival_latitude = this.userData.info.origin_latitude;
					arrival_longitude = this.userData.info.origin_longitude;
				}

				this.isLoading = true;
				this.$validator.validateAll().then(result => {
					if (result && this.hitchhikeTime !== null && this.departureDate !== null) {
						let time = this.hitchhikeTime.toISOString();
						/*       let timeFormat = moment.parseZone(this.hitchhikeTime.toISOString()).local().format() */
						/*   console.log(timeFormat); */
						/*  console.log(moment.parseZone(time).local().format()); */
						let testTime = moment
							.parseZone(time)
							.local()
							.format();
						console.log(moment(testTime).format('HH:mm:ss'));
						let timeFormat = moment(testTime).format('HH:mm:ss');
						let data = {
							departure_zip_code: this.departureAddress.zip_code,
							departure_country: this.departureAddress.country,
							arrival_zip_code: this.arrivalAddress.zip_code,
							arrival_country: this.arrivalAddress.country,
							departure_date: this.formatDate(this.departureDate) + 'T' + timeFormat,
							formatted_departure_address: this.departureAddress.formatted_address,
							formatted_arrival_address: this.arrivalAddress.formatted_address,
							departure_latitude: departure_latitude,
							departure_longitude: departure_longitude,
							arrival_latitude: arrival_latitude,
							arrival_longitude: arrival_longitude,
						};
						this.setHitchhike(1, data);
					} else {
						this.isLoading = false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '../../../assets/scss/auth';
	@import '../../../assets/scss/hitchhike';

	.select,
	select {
		width: 100% !important;
	}
</style>
