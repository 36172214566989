import store from '../store/index';
import axios from 'axios';
import VueInstance from '@/main';
import { logout } from '@/mixins/auth/logout';

axios.interceptors.response.use(
	function(response) {
		if (response.headers && response.headers.authorization) {
			store.commit('auth/setToken', response.headers.authorization);
		}
		return response;
	},
	function(error) {
		if ((error.response.status == 401 || error.response.status == 403) && store.getters['auth/getToken']) {
			logout.methods.logout();
		}
		return Promise.reject(error);
	}
);

const getHeaders = () => {
	if (store.getters['auth/getToken']) {
		return {
			Authorization: `${store.getters['auth/getToken']}`,
			Language: 'pt',
		};
	} else {
		return {
			Language: 'pt',
		};
	}
};

export function post(uri, data) {
	return axios.post(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function formData(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.post(endpoint(uri), data, config);
}

export function formDataPut(uri, data) {
	let config = {
		headers: getHeaders(),
	};
	config.headers['Content-Type'] = 'multipart/form-data';
	return axios.put(endpoint(uri), data, config);
}

export function put(uri, data) {
	return axios.put(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function del(uri, data) {
	return axios.delete(endpoint(uri), {
		data,
		headers: getHeaders(),
	});
}

export function get(uri) {
	return axios.get(endpoint(uri), {
		headers: getHeaders(),
	});
}

export function getAirport(uri) {
	return axios.get(uri, {
		headers: { 'Access-Control-Allow-Origin': '*' },
	});
}

export function verifyZipCode(uri) {
	return axios.get(uri, {});
}

/*export function route(uri) {
    return axios.get(gmapEndpoint(uri), {headers: getHeaders()})
}*/

export function endpoint(uri) {
	return process.env.VUE_APP_API_URL + uri;
}

/*
export function gmapEndpoint(uri) {
    return process.env.VUE_APP_GMAPS_URL + uri
}*/
