<template>
	<section>
		<div class="goods-services-fields">
			<br />
			<p style="font-size: 24px" class="has-text-primary has-text-centered">
				<b>{{ getServiceData[0].service_type.name_pt }}</b>
			</p>
			<br />
			<div class="columns is-gapless is-multiline">
				<div class="column is-4-desktop is-6-tablet" v-for="(service, index) in getServiceData">
					<div class="card">
						<div class="card-content">
							<div class="media">
								<div class="media-left">
									<div class="user center-inside">
										<img class="profile-photo" v-if="service.emigrant.photo" :src="service.emigrant.photo" />
										<i v-else class="file-icon mdi mdi-camera-outline mdi-36px"></i>
									</div>
								</div>
								<div class="media-content distance">
									<p class="has-text-primary">
										<b>{{ service.title }}</b>
									</p>
									<p class="has-text-dark">
										{{ service.service_type.name_pt }}
									</p>
								</div>
								<div class="media-right" style="margin-left:5px">
									<div class="media-content ">
										<p v-if="service.distance > 1" class="has-text-secondary">
											<b>{{ service.distance }}km</b>
										</p>
										<p v-else class="has-text-secondary">&lt 1 km</p>
									</div>
								</div>
								<div class="media-right">
									<div class="media-content is-click" @click="removeFavourite(service.wish_services[0].id, index)" v-if="service.wish_services.length > 0">
										<i class="mdi mdi-heart mdi-24px not-favourite"></i>
									</div>
									<div class="media-content is-click" v-else @click="addToFavourites(service.id, index)">
										<i class="mdi mdi-heart-outline mdi-24px not-favourite"></i>
									</div>
								</div>
							</div>
							<div class="media">
								<div class="media-left">
									<div class="media-content">
										<p class="has-text-primary">
											<b>{{ service.hourly_price }} €/hora</b>
										</p>
									</div>
								</div>
								<div class="media-content has-text-centered"></div>
								<div class="media-right">
									<div class="media-content">
										<button class="button is-rounded is-primary is-fullwidth" @click="goToPage('/services/' + service.id)">
											<b>Detalhes</b>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	//Mixins
	import { servicesSteps } from '@/mixins/services/servicesSteps';
	import { goToPage } from '@/mixins/shared/goToPage';
	import { createWishService, deleteWishService } from '@/api/apiGoodsAndServices';

	export default {
		mixins: [servicesSteps, goToPage],
		name: 'ServicesResults',
		data() {
			return {};
		},
		created() {
			console.log(this.getServiceData);
		},
		methods: {
			addToFavourites(id, index) {
				console.log(id);
				console.log(index);
				createWishService(id)
					.then(response => {
						console.log(response);
						let data = this.getServiceData;
						data[index].wish_services.push(response.data.body);
						this.setServices(1, data);
					})
					.catch(error => {});
			},
			removeFavourite(id, index) {
				deleteWishService(id)
					.then(response => {
						console.log(response);
						let data = this.getServiceData;
						data[index].wish_services.pop();
						this.setServices(1, data);
					})
					.catch(error => {});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '../../assets/scss/goodsAndServices';

	.goods-services-fields {
		width: 100%;
		height: calc(100vh - 77px);
		position: absolute;
		padding-left: 0;
		padding-right: 0;
		overflow-y: scroll;
	}

	.card {
		width: 93%;
		border-radius: 25px;
		margin: 10px auto;
		-webkit-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		-moz-box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
		box-shadow: rgba(29, 70, 150, 0.2) 0 0 15px;
	}

	.media {
		border: none;
		margin-top: 0;
	}

	.distance {
		overflow-x: hidden !important;
	}
</style>
