<template>
	<b-table :data="goods">
		<b-table-column label="Imagem">
			<template v-slot="props">
				<div class="mobile">
					<div class="file-cta center-inside">
						<img class="feed-content" :src="props.row.photo" />
					</div>
				</div>
			</template>
		</b-table-column>
		<template v-for="column in columns">
			<b-table-column :key="column.id" v-bind="column">
				<template v-if="column.searchable && !column.numeric" #searchable="props">
					<b-input v-model="props.filters[props.column.field]" placeholder="Pesquisa" icon="magnify" size="is-small" />
				</template>
				<template v-slot="props">
					{{ props.row[column.field] }}
				</template>
			</b-table-column>
		</template>

		<b-table-column label="Utilizador">
			<template v-slot="props">
				{{ props.row.emigrant.name }}
			</template>
		</b-table-column>

		<b-table-column v-slot="props">
			<b-button rounded class="deactivate-account" @click="removeGood(props.row.id, props.index)">Eliminar</b-button>
		</b-table-column>
	</b-table>
</template>

<script>
	import { getAllGoods, removeGood } from '@/api/apiBackOffice';
	export default {
		data() {
			return {
				goods: [],
				columns: [
					{
						field: 'title',
						label: 'Título',
						searchable: false,
						width: '400',
					},
					{
						field: 'description',
						label: 'Descrição',
						searchable: false,
						width: '400',
					},
					/*   {
						field: 'emigrant',
						label: 'Transação',
						searchable: false,
						width: '400',
					}, */
				],
			};
		},
		created() {
			getAllGoods()
				.then(response => {
					console.log(response);
					this.goods = response.data;
				})
				.catch(error => {
					console.log(error);
				});
		},

		methods: {
			removeGood(id, index) {
				let self = this;
				this.$buefy.dialog.confirm({
					title: 'Apagar Artigo',
					canCancel: ['button'],
					message: '<p style="color: #1d4696">Tens a certeza que pretendes eliminar este artigo?</p>',
					cancelText: 'Confirmar',
					confirmText: 'Não',
					type: 'is-primary',
					onCancel() {
						removeGood(id)
							.then(response => {
								self.goods.splice(index, 1);
								self.$buefy.toast.open({
									message: 'Artigo apagado com sucesso',
									position: 'is-top',
									type: 'is-primary',
								});
							})
							.catch(error => {
								self.$buefy.toast.open({
									message: 'Ocurreu um erro ao apagar o artigo',
									position: 'is-top',
									type: 'is-danger',
								});
							});
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.activate-account {
		background-color: rgb(47, 179, 47);
		color: white;
		width: 163px;
	}

	.deactivate-account {
		background-color: rgb(201, 42, 42);
		color: white;
		width: 163px;
	}

	.file-icon {
		margin-right: 0;
		color: white;
	}

	.feed-content {
		width: 200px;
		height: 200px;
		object-fit: scale-down;
	}

	.file-cta {
		width: 200px;
		height: 200px;
		padding: 0;
	}

	/deep/ td {
		vertical-align: middle !important;
	}

	@media screen and (max-width: 768px) {
		.mobile {
			width: 100%;
			justify-content: center;
			display: flex;
		}
	}
</style>
